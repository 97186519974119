/**
 * ReviewモデルとなるClass
 * - APIの戻り値をプロパティにセットする
 * - フィールドは必ず存在するものとする（Optionalにはならない）
 * - 既定メンバでないプロパティは生成しない
 * - Non-null
 */
export class ReviewOrderClientInfo {
  /** @todo SsSelectiveではid:stringだった。stringである意味があるか？ */
  id: number = 0;
  /** オーダーID */
  order_id: number = 0;
  /** オーダーURL */
  client_id: number = 0;
  /** クライアント名 */
  client_name: string = '';
  /** 施工店詳細ページURL */
  gam_client_page_url: string = '';
  /** 施工事例URL */
  gam_review_page_url: string = '';

  /**
   * @example fb.groupFromModel(new ReviewOrderClientInfo());
   */
  constructor(data?) {
    if (data) {
      this.id = data.id;
      this.order_id = data.order_id;
      this.client_id = data.client_id;
      this.client_name = data.client_name;
      this.gam_client_page_url = data.gam_client_page_url;
      this.gam_review_page_url = data.gam_review_page_url;
    }
  }

  /**
   * nullを初期値に変換して、コンストラクタに渡す
   * @example const data = ReviewOrderClientInfo.initData(json.data)
   */
  static initData(data: ReviewOrderClientInfo) {
    let procData = {} as any;
    Object.entries(data).forEach(([key, val]) => {
      switch (key) {
        // Nullを返すメンバは全て string | null
        default:
          procData[`${key}`] = val ?? '';
          return;
      }
    });

    return new ReviewOrderClientInfo(procData as ReviewOrderClientInfo);
  }
}
