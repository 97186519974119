import { OfferedOrderHistoryOriginInterface } from '@app-units/models/offered-order-history/offered-order-history-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class OfferedOrderHistory implements OriginWrapper {
  private readonly origin: OfferedOrderHistoryOriginInterface;

  id: string;
  order_transfer_cd: string;
  end_name: string;
  user_for_delivery: number;
  user_for_delivery_name: string;
  offered_date: string;
  order_transfer_date: string;
  order_transfer_status: number;
  order_transfer_status_name: string;
  order_price: number;
  order_status_id: number;
  order_status_name: string;
  estimate_status_name: string;
  charge_type_id: number;

  get posting_data(): OfferedOrderHistoryOriginInterface {
    return new OfferedOrderHistoryOrigin(
      parseInt(this.id),
      this.order_transfer_cd,
      this.end_name,
      this.user_for_delivery,
      this.user_for_delivery_name,
      this.offered_date,
      this.order_transfer_date,
      this.order_transfer_status,
      this.order_transfer_status_name,
      this.order_price,
      this.order_status_id,
      this.order_status_name,
      this.estimate_status_name,
      this.charge_type_id
    );
  }

  constructor(offeredOrderHistory?: OfferedOrderHistoryOriginInterface) {
    this.origin = this.createOrderInstance(offeredOrderHistory);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createOrderInstance(
    offeredOrderHistory: OfferedOrderHistoryOriginInterface
  ): OfferedOrderHistoryOrigin {
    return offeredOrderHistory
      ? new OfferedOrderHistoryOrigin(
          offeredOrderHistory.id,
          offeredOrderHistory.order_transfer_cd,
          offeredOrderHistory.end_name,
          offeredOrderHistory.user_for_delivery,
          offeredOrderHistory.user_for_delivery_name,
          offeredOrderHistory.offered_date,
          offeredOrderHistory.order_transfer_date,
          offeredOrderHistory.order_transfer_status,
          offeredOrderHistory.order_transfer_status_name,
          offeredOrderHistory.order_price,
          offeredOrderHistory.order_status_id,
          offeredOrderHistory.order_status_name,
          offeredOrderHistory.estimate_status_name,
          offeredOrderHistory.charge_type_id
        )
      : new OfferedOrderHistoryOrigin();
  }

  private initializeProperties(offeredOrderHistory: OfferedOrderHistoryOrigin) {
    this.id = !_.isNil(offeredOrderHistory.id)
      ? String(offeredOrderHistory.id)
      : null;
    this.order_transfer_cd = offeredOrderHistory.order_transfer_cd;
    this.end_name = offeredOrderHistory.end_name;
    this.user_for_delivery = offeredOrderHistory.user_for_delivery;
    this.user_for_delivery_name = offeredOrderHistory.user_for_delivery_name;
    this.offered_date = offeredOrderHistory.offered_date;
    this.order_transfer_date = offeredOrderHistory.order_transfer_date;
    this.order_transfer_status = offeredOrderHistory.order_transfer_status;
    this.order_transfer_status_name =
      offeredOrderHistory.order_transfer_status_name;
    this.order_price = offeredOrderHistory.order_price;
    this.order_status_id = offeredOrderHistory.order_status_id;
    this.order_status_name = offeredOrderHistory.order_status_name;
    this.estimate_status_name = offeredOrderHistory.estimate_status_name;
    this.charge_type_id = offeredOrderHistory.charge_type_id;
  }
}

class OfferedOrderHistoryOrigin implements OfferedOrderHistoryOriginInterface {
  id: number;
  order_transfer_cd: string;
  end_name: string;
  user_for_delivery: number;
  user_for_delivery_name: string;
  offered_date: string;
  order_transfer_date: string;
  order_transfer_status: number;
  order_transfer_status_name: string;
  order_price: number;
  order_status_id: number;
  order_status_name: string;
  estimate_status_name: string;
  charge_type_id: number;

  constructor(
    id?: number,
    order_transfer_cd?: string,
    end_name?: string,
    user_for_delivery?: number,
    user_for_delivery_name?: string,
    offered_date?: string,
    order_transfer_date?: string,
    order_transfer_status?: number,
    order_transfer_status_name?: string,
    order_price?: number,
    order_status_id?: number,
    order_status_name?: string,
    estimate_status_name?: string,
    charge_type_id?: number
  ) {
    this.id = id ? id : null;
    this.order_transfer_cd = order_transfer_cd ? order_transfer_cd : null;
    this.end_name = end_name ? end_name : null;
    this.user_for_delivery = _.isNumber(user_for_delivery)
      ? user_for_delivery
      : null;
    this.user_for_delivery_name = user_for_delivery_name
      ? user_for_delivery_name
      : null;
    this.offered_date = offered_date ? offered_date : null;
    this.order_transfer_date = order_transfer_date ? order_transfer_date : null;
    this.order_transfer_status = _.isNumber(order_transfer_status)
      ? order_transfer_status
      : null;
    this.order_transfer_status_name = order_transfer_status_name
      ? order_transfer_status_name
      : null;
    this.order_price = _.isNumber(order_price) ? order_price : null;
    this.order_status_id = order_status_id;
    this.order_status_name = order_status_name;
    this.estimate_status_name = estimate_status_name;
    this.charge_type_id = charge_type_id;
  }
}
