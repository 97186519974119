import { QuestionnaireMemoInfoOriginInterface } from '@app-units/models/questionnaire-memo-info/questionnaire-memo-info-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class QuestionnaireMemoInfo implements OriginWrapper {
  private readonly origin: QuestionnaireMemoInfoOriginInterface;

  id: string;
  questionnaire_memo: string;

  get posting_data(): QuestionnaireMemoInfoOriginInterface {
    return new QuestionnaireMemoInfoOrigin(
      Number(this.id),
      this.questionnaire_memo
    );
  }

  constructor(questionnaireMemoInfo?: QuestionnaireMemoInfoOriginInterface) {
    this.origin = this.createQuestionnaireMemoInfonstance(
      questionnaireMemoInfo
    );
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createQuestionnaireMemoInfonstance(
    questionnaireMemoInfo: QuestionnaireMemoInfoOriginInterface
  ): QuestionnaireMemoInfoOrigin {
    return questionnaireMemoInfo
      ? new QuestionnaireMemoInfoOrigin(
          questionnaireMemoInfo.id,
          questionnaireMemoInfo.questionnaire_memo
        )
      : new QuestionnaireMemoInfoOrigin();
  }

  private initializeProperties(
    questionnaireMemoInfo: QuestionnaireMemoInfoOrigin
  ) {
    this.id = _.isNumber(questionnaireMemoInfo.id)
      ? String(questionnaireMemoInfo.id)
      : null;
    this.questionnaire_memo = questionnaireMemoInfo.questionnaire_memo;
  }
}

class QuestionnaireMemoInfoOrigin
  implements QuestionnaireMemoInfoOriginInterface
{
  id: number;
  questionnaire_memo: string;

  constructor(id?: number, questionnaire_memo?: string) {
    this.id = _.isNumber(id) ? id : null;
    this.questionnaire_memo = questionnaire_memo ? questionnaire_memo : null;
  }
}
