import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

export interface OnBeforeUnload {
  shouldConfirmOnBeforeUnload: () => boolean;
}

@Injectable()
export class BeforeUnloadGuard implements CanDeactivate<OnBeforeUnload> {
  canDeactivate(component: OnBeforeUnload) {
    if (component.shouldConfirmOnBeforeUnload()) {
      return confirm(
        '編集中のものがあります。\n遷移してもよろしいでしょうか？'
      );
    }
    return true;
  }
}
