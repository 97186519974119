import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';

@Injectable()
export class SsRouterService {
  urlHistory: string[] = [];

  constructor(private router: Router) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.urlHistory.push(e.urlAfterRedirects);
      });
  }

  get previousUrl() {
    // 一つまえのurlを取得。
    return _.nth(this.urlHistory, -2);
  }
}
