import {
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { SsDialogContainerComponent } from '@blocks/dialog/ss-dialog-container/ss-dialog-container.component';
import {
  SsDialogResult,
  SsModalDialogComponent,
} from '@blocks/dialog/ss-modal-dialog/ss-modal-dialog.component';
import { Observable } from 'rxjs';

/**
 *
 * SsDialogService
 * @description dialogを生成・削除するService
 *
 */
@Injectable()
export class SsDialogService {
  /** dialogを入れるcomponent */
  private dialogContainerComponent: SsDialogContainerComponent;
  /** dialogContainerComponentの追加先（append）のDOM */
  private container: HTMLElement;

  constructor(
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef
  ) {
    // console.log("SsDialogService")
  }

  /**
   * 引数で渡されたDialogComponentを表示
   */
  addDialog<T>(
    DialogComponent: Type<SsModalDialogComponent<T>>,
    fillData?: any
  ): Observable<SsDialogResult> {
    // dialogを入れるcontainerComponentが作られてなければ生成する
    if (!this.dialogContainerComponent) {
      this.createContainer();
    }
    // DialogComponentを生成して表示
    return this.dialogContainerComponent.addDialog(DialogComponent, fillData);
  }

  /**
   * 引数で渡されたdialogComponentを削除
   */
  removeDialog(dialogComponent: SsModalDialogComponent<any>) {
    if (!this.container) {
      return;
    }
    this.dialogContainerComponent.removeDialog(dialogComponent);
  }

  /**
   * 表示されてるdialogを全て削除
   */
  removeAll() {
    if (this.dialogContainerComponent) {
      this.dialogContainerComponent.clear();
    }
  }

  /**
   * dialogを入れるcomponent（dialogContainerComponent）を生成
   */
  private createContainer() {
    // SsDialogContainerComponentを生成するfactoryを用意
    const factory = this.resolver.resolveComponentFactory(
      SsDialogContainerComponent
    );

    // Angular内で使用できるように生成
    const containerComponentRef = factory.create(this.injector);

    // 生成したSsDialogContainerComponentのDOMを取得
    const containerComponentRootNode = (
      containerComponentRef.hostView as EmbeddedViewRef<any>
    ).rootNodes[0] as HTMLElement;

    // Angularが動いてるrootViewを取得
    // const rootViewContainer = this.applicationRef['_rootComponents'][0];
    const rootViewContainer = this.applicationRef.components[0];

    // rootViewのDOMを取得してcontainerとする
    this.container = (rootViewContainer.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // 生成したSsDialogContainerComponentのviewがダーティチェックされるようにアタッチ
    this.applicationRef.attachView(containerComponentRef.hostView);
    // SsDialogContainerComponentのviewが削除されたらデタッチ（ダーティチェックを切り離す）
    containerComponentRef.onDestroy(() => {
      this.applicationRef.detachView(containerComponentRef.hostView);
    });

    // containerに生成したSsDialogContainerComponentのDOMをappend
    this.container.appendChild(containerComponentRootNode);

    // ContainerComponentのインスタンスを格納しておく
    this.dialogContainerComponent = containerComponentRef.instance;
  }
}
