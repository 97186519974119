import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MyAspHttpService {
  constructor(private http: HttpClient) {}

  introduce(orderId: string) {
    return this.http.post(`/myasp/introduction/${orderId}`, null);
  }

  contract(orderId: string) {
    return this.http.post(`/myasp/contract/${orderId}`, null);
  }
}
