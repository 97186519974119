import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';
import { EntryInfoOriginInterface } from './entry-info-origin';

export class EntryInfo implements OriginWrapper {
  id: string;
  entry_comment: string;

  private readonly origin: EntryInfoOriginInterface;

  get posting_data(): EntryInfoOriginInterface {
    return new EntryInfoOrigin(Number(this.id), this.entry_comment);
  }

  constructor(entryInfo?: EntryInfoOriginInterface) {
    this.origin = this.createInstance(entryInfo);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createInstance(entryInfo: EntryInfoOriginInterface): EntryInfoOrigin {
    return entryInfo
      ? new EntryInfoOrigin(entryInfo.id, entryInfo.entry_comment)
      : new EntryInfoOrigin();
  }

  private initializeProperties(entryInfo: EntryInfoOrigin) {
    this.id = _.isNumber(entryInfo.id) ? String(entryInfo.id) : null;
    this.entry_comment = entryInfo.entry_comment;
  }
}

class EntryInfoOrigin implements EntryInfoOriginInterface {
  id: number;
  entry_comment: string;

  constructor(id?: number, entry_comment?: string) {
    this.id = _.isNumber(id) ? id : null;
    this.entry_comment = entry_comment ? entry_comment : null;
  }
}
