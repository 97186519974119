<header>
    <nav class="navbar navbar-default navbar-static-top navbar-fixed-top">
        <div class="container">
            <div class="navbar-header">
                <span class="navbar-brand">{{ projectTitle }}</span>
            </div>

            <auth-user-profile *ngIf="!isHiddenHeaderMenu"></auth-user-profile>

            <ul class="nav navbar-nav navbar-right" *ngIf="!isHiddenHeaderMenu">
                <li *ngFor="let navigate of navigation" #navigateMenu>
                    <a
                        *ngIf="!navigate.children"
                        [routerLink]="[navigate.path]"
                        [routerLinkActive]="['active', 'abc']"
                        [routerLinkActiveOptions]="{
                            exact: navigate.path === ''
                        }"
                    >
                        <i
                            *ngIf="navigate?.data?.icon"
                            class="icomoon-{{ navigate?.data?.icon }}"
                        ></i>
                        <span>
                            {{
                                !!navigate.data.sideNavTitle
                                    ? navigate.data.sideNavTitle
                                    : navigate.data.title
                            }}
                        </span>
                    </a>
                </li>
                <li *ngIf="linkToRequest">
                    <a href="{{ linkToRequest }}" target="_blank"
                        >課金除外申請</a
                    >
                </li>
            </ul>
        </div>

        <page-title></page-title>
    </nav>
</header>
