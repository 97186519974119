import { ReportMonthlyData } from '@app-units/models/report-monthly-data/report-monthly-data';
import { ReportMonthlyDataOriginInterface } from '@app-units/models/report-monthly-data/report-monthly-data-origin';
import { ReportOriginInterface } from '@app-units/models/report/report-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class Report implements OriginWrapper {
  id: string;
  intro_count: string;
  cancel_count: string;
  contract_count: string;
  contract_rate: string;
  auto_rank: string;
  monthly_introduces: ReportMonthlyData;
  monthly_max_order_transfers: ReportMonthlyData;
  monthly_prices: ReportMonthlyData;
  monthly_by_prices: { [key: number]: string };

  private readonly origin: ReportOriginInterface;

  get posting_data(): ReportOriginInterface {
    return new ReportOrigin(
      Number(this.id),
      this.intro_count,
      this.cancel_count,
      this.contract_count,
      this.contract_rate,
      this.auto_rank,
      this.monthly_introduces,
      this.monthly_max_order_transfers,
      this.monthly_prices,
      this.monthly_by_prices
    );
  }

  constructor(report?: ReportOriginInterface) {
    this.origin = this.createSmsInfonstance(report);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createSmsInfonstance(report: ReportOriginInterface): ReportOrigin {
    return report
      ? new ReportOrigin(
          report.id,
          report.intro_count,
          report.cancel_count,
          report.contract_count,
          report.contract_rate,
          report.auto_rank,
          report.monthly_introduces,
          report.monthly_max_order_transfers,
          report.monthly_prices,
          report.monthly_by_prices
        )
      : new ReportOrigin();
  }

  private initializeProperties(report: ReportOrigin) {
    this.id = _.isNumber(report.id) ? String(report.id) : null;
    this.intro_count = report.intro_count;
    this.cancel_count = report.cancel_count;
    this.contract_count = report.contract_count;
    this.contract_rate = report.contract_rate;
    this.auto_rank = report.auto_rank;
    this.monthly_introduces = report.monthly_introduces
      ? new ReportMonthlyData(report.monthly_introduces)
      : new ReportMonthlyData();
    this.monthly_max_order_transfers = report.monthly_max_order_transfers
      ? new ReportMonthlyData(report.monthly_max_order_transfers)
      : new ReportMonthlyData();
    this.monthly_prices = report.monthly_prices
      ? new ReportMonthlyData(report.monthly_prices)
      : new ReportMonthlyData();
    this.monthly_by_prices = report.monthly_by_prices
      ? report.monthly_by_prices
      : null;
  }
}

class ReportOrigin implements ReportOriginInterface {
  id: number;
  intro_count: string;
  cancel_count: string;
  contract_count: string;
  contract_rate: string;
  auto_rank: string;
  monthly_introduces: ReportMonthlyDataOriginInterface;
  monthly_max_order_transfers: ReportMonthlyDataOriginInterface;
  monthly_prices: ReportMonthlyDataOriginInterface;
  monthly_by_prices: { [key: number]: string };

  constructor(
    id?: number,
    intro_count?: string,
    cancel_count?: string,
    contract_count?: string,
    contract_rate?: string,
    auto_rank?: string,
    monthly_introduces?: ReportMonthlyDataOriginInterface,
    monthly_max_order_transfers?: ReportMonthlyDataOriginInterface,
    monthly_prices?: ReportMonthlyDataOriginInterface,
    monthly_by_prices?: { [key: number]: string }
  ) {
    this.id = _.isNumber(id) ? id : null;
    this.intro_count = intro_count ? intro_count : null;
    this.cancel_count = cancel_count ? cancel_count : null;
    this.contract_count = contract_count ? contract_count : null;
    this.contract_rate = contract_rate ? contract_rate : null;
    this.auto_rank = auto_rank ? auto_rank : null;
    this.monthly_introduces = monthly_introduces ? monthly_introduces : null;
    this.monthly_max_order_transfers = monthly_max_order_transfers
      ? monthly_max_order_transfers
      : null;
    this.monthly_prices = monthly_prices ? monthly_prices : null;
    this.monthly_by_prices = monthly_by_prices ? monthly_by_prices : null;
  }
}
