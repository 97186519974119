import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, of } from 'rxjs';
import {
  tap,
  catchError,
  retryWhen,
  delay,
  mergeMap,
  concat,
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@ss-core/services/auth/auth.service';
import { SsConstant } from '@ss-core/constants/ss.constant';
import { ssToasterErrorConfig } from '@blocks/toastr/ss-toastr.config';

/**
 * HttpClientのrequest/responseをインターセプトする
 * - 成功/失敗時にtoast表示
 *
 * https://angular.io/api/common/http/HttpInterceptor
 */
@Injectable()
export class ToastInterceptor implements HttpInterceptor {
  private base_url: string;

  constructor(private toastrService: ToastrService, private router: Router) {
    this.base_url = SsConstant.API_BASE_URL;
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // URLを置換
    const req = request.clone({
      url: this.parseUrl(request.url),
    });

    // 成功/失敗
    return next.handle(req).pipe(
      retryWithDelay(),
      tap({
        next: (res: HttpResponse<any>) => {
          this.handleResponseSuccess(res);
        },
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleResponseCatch(error);
      })
    );
  }

  /**
   * 成功時の処理
   */
  private handleResponseSuccess(response): Observable<any> {
    if (!response.body) return response;

    // messageが返されたら表示する
    const { message, result } = response.body;
    if (message) {
      if (result) {
        this.toastrService.success(message);
      } else {
        this.toastrService.error(message, '', ssToasterErrorConfig);
      }
    }

    return response;
  }

  /**
   * 失敗時の処理
   */
  private handleResponseCatch(response): Observable<any> {
    console.error('handleResponseCatch', this, response);

    // messageが返されたら表示する
    const message = response.error?.message || response.message;
    if (message && response.status !== 401) {
      // (message, title, ToastConfig)
      // https://www.npmjs.com/package/ngx-toastr
      this.toastrService.error(message, '', ssToasterErrorConfig);
    }

    // statusCodeでの分岐処理
    switch (response.status) {
      case 401:
        // errorを返さないとGuardが機能しない
        return throwError(response);
      case 404:
        // 404ページに飛ばす（/404 -> path: '**'）
        this.router.navigate(['/404']);
      case 500:
      case 502:
      case 0:
      default:
        // 例外で落とす（Sentryダイアログ起動）
        throw new Error(response);
    }

    // catchErrorはObservableを返すか、throwで終わる必要がある
    return throwError(response);
  }

  /**
   * URLを変換（baseUrlを追加）
   * From: /auth/login
   * To: /api/auth/login
   */
  parseUrl(url: string) {
    // 外部のURLを叩いてる場合はそのまま返す
    if (/^https?:\/\//.test(url)) {
      return url;
    }
    return this.base_url + url;
  }
}

/**
 * errorが起きたら3秒後にリトライ
 * - ３回まで
 * - 404, 401は除外
 *
 * https://rxjs.dev/api/operators/retryWhen
 * - v9かv10で削除されるので、retry()使えとのこと
 * - いま6なので…
 */
function retryWithDelay() {
  const retryDelay = 3000;
  const retryCount = 3;
  const excludeError = [401, 404];

  return retryWhen((errors) =>
    errors.pipe(
      mergeMap((err, i) => {
        // カウント超過か特定のstatusCodeなら除外（エラーを投げる)
        // - iはconcurrent = カウント。0からスタート
        const hasExcludeCode = excludeError.some(
          (code) => code == (err.status || '')
        );
        const doRetry = hasExcludeCode ? false : i < retryCount;

        return doRetry ? of(err) : throwError(err);
      }),
      tap((err) => console.log(`err:${err.url}, retrying...`)),
      delay(retryDelay),
      concat(throwError('retried too much...'))
    )
  );
}
