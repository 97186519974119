import { filter } from 'rxjs/operators';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IRoutePaths, ROUTES_CONSTANT } from '@app-core/constants';
import { NotificationCenterService } from '@app-core/services/notification-center/notification-center.service';
import { AuthUser } from '@app-units/models/auth-user/auth-user';
import { AuthService } from '@ss-core/services';

/**
 * @classdesc ログイン者情報を表示
 * @export
 * @class AuthUserProfileComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'auth-user-profile',
  templateUrl: './auth-user-profile.component.html',
  styleUrls: ['./auth-user-profile.component.scss'],
})
export class AuthUserProfileComponent implements OnInit {
  /** ログイン者情報 */
  authUser: AuthUser;

  constructor(
    @Inject(ROUTES_CONSTANT) private ROUTES_CONSTANT: IRoutePaths,
    private router: Router,
    private notificationCenterService: NotificationCenterService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    // ログイン者情報を取得
    this.authUser = this.authService.authUser();
  }

  /**
   * ログアウト処理
   */
  onLogout() {
    this.authService
      .logout()
      .pipe(filter((res) => res.result))
      .subscribe(() => {
        this.notificationCenterService.logoutSucceed$.next();
        location.href = '/';
      });
  }

  /**
   * プロフィール
   */
  onProfileClick() {
    this.router.navigate([
      this.ROUTES_CONSTANT.USERS.path,
      this.ROUTES_CONSTANT.COMMON.VIEW.path,
      this.authUser.id,
    ]);
  }
}
