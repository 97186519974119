<div class="auth-user-profile">
    <div class="user-info" dropdown>
        <div class="btn-group">
            <button class="btn btn-default">
                <i class="flat-icon-anonymous_3"></i>{{ authUser?.fullName }}
            </button>
            <button
                type="button"
                class="btn btn-default dropdown-toggle"
                dropdownToggle
            >
                <span class="caret"></span>
            </button>
        </div>
        <ul *dropdownMenu class="dropdown-menu">
            <li><a (click)="onLogout()">ログアウト</a></li>
        </ul>
    </div>
</div>
<!--<pre>{{authUser | json}}</pre>-->
