import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { Report } from '@app-units/models/report/report';
import { SsUrlSearchParamsService } from '@blocks/utils/ss-url-search-params/ss-url-search-params.service';
import { Observable } from 'rxjs';

export class ReportsSearchParams {
  /** 年 */
  year?: number = null;
}

@Injectable()
export class ReportsHttpService {
  constructor(
    private http: HttpClient,
    private ssUrlSearchParams: SsUrlSearchParamsService
  ) {}

  fetchItems(
    id: string,
    searchParams: ReportsSearchParams
  ): Observable<app.response<Report>> {
    const params = this.ssUrlSearchParams.parseParams(searchParams);
    return this.http
      .get<app.response<Report>>(this.getUrl(id), { params: params })
      .pipe(map(JsonConverter.toReport));
  }

  private getUrl(id: string) {
    return `/clients/${id}/report`;
  }
}
