import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class OfferedOrdersHttpService {
  constructor(private http: HttpClient) {}

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * - https://v5.angular.io/tutorial/toh-pt6#final-code-review
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: 適切なエラーの投げ場所を見つけたら、差し替える
      console.error(error); // log to console instead
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  close(orderId: string): Observable<app.response<any>> {
    return this.http
      .put<app.response<any>>(this.getUrl(orderId), {})
      .pipe(
        catchError(
          this.handleError<app.response<any>>('OfferedOrdersHttpService.close')
        )
      );
  }

  private getUrl(id: string): string {
    return `/offered_orders/${id}/close`;
  }
}
