import { ToastrConfig } from 'ngx-toastr';

// ToastrConfig
// https://github.com/scttcper/ngx-toastr#individual-options

// ToastrのConfig設定
export let ssToasterConfig: Partial<ToastrConfig> = {
  progressBar: true,
  enableHtml: true,
  positionClass: 'toast-top-center',
  closeButton: true,
  timeOut: 3000,
};

// Toastr（error）のConfig設定
export let ssToasterErrorConfig: Partial<ToastrConfig> = Object.assign(
  {},
  ssToasterConfig,
  {
    // 自動で消えなくする
    timeOut: 0,
    // hover後も自動で消えなくする
    extendedTimeOut: 0,
  }
);
