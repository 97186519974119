import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SsScrollTopButtonComponent } from './ss-scroll-top-button/ss-scroll-top-button.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SsScrollTopButtonComponent],
  exports: [SsScrollTopButtonComponent],
})
export class SsScrollTopButtonModule {}
