import { ClientPortalOrdersOriginInterface } from '@app-units/models/client-portal-order/client-portal-order-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class ClientPortalOrder implements OriginWrapper {
  private readonly origin: ClientPortalOrdersOriginInterface;

  id: string;
  order_transfer_cd: string;
  estimate_status: number;
  estimate_status_name: string;
  end_name: string;
  end_name_kana: string;
  end_tel: string;
  end_email: string;
  prefecture_name: string;
  city: string;
  article_type_name: string;
  article_age: string;
  floor_name: string;
  floor_area_name: string;
  repair_request_timing: string;
  repair_request_name: string;
  order_transfered_date: string;
  charge_flag: number;

  get posting_data(): ClientPortalOrdersOriginInterface {
    return new ClientPortalOrderOrigin(
      parseInt(this.id),
      this.order_transfer_cd,
      this.estimate_status,
      this.estimate_status_name,
      this.end_name,
      this.end_name_kana,
      this.end_tel,
      this.end_email,
      this.prefecture_name,
      this.city,
      this.article_type_name,
      this.article_age,
      this.floor_name,
      this.floor_area_name,
      this.repair_request_timing,
      this.repair_request_name,
      this.order_transfered_date,
      this.charge_flag
    );
  }

  constructor(clientPortalOrder?: ClientPortalOrdersOriginInterface) {
    this.origin = this.createInstance(clientPortalOrder);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createInstance(
    clientPortalOrder: ClientPortalOrderOrigin
  ): ClientPortalOrderOrigin {
    return clientPortalOrder
      ? new ClientPortalOrderOrigin(
          clientPortalOrder.id,
          clientPortalOrder.order_transfer_cd,
          clientPortalOrder.estimate_status,
          clientPortalOrder.estimate_status_name,
          clientPortalOrder.end_name,
          clientPortalOrder.end_name_kana,
          clientPortalOrder.end_tel,
          clientPortalOrder.end_email,
          clientPortalOrder.prefecture_name,
          clientPortalOrder.city,
          clientPortalOrder.article_type_name,
          clientPortalOrder.article_age,
          clientPortalOrder.floor_name,
          clientPortalOrder.floor_area_name,
          clientPortalOrder.repair_request_timing,
          clientPortalOrder.repair_request_name,
          clientPortalOrder.order_transfered_date,
          clientPortalOrder.charge_flag
        )
      : new ClientPortalOrderOrigin();
  }

  private initializeProperties(clientPortalOrder: ClientPortalOrderOrigin) {
    this.id =
      clientPortalOrder.id || clientPortalOrder.id === 0
        ? String(clientPortalOrder.id)
        : null;
    this.order_transfer_cd = clientPortalOrder.order_transfer_cd;
    this.estimate_status = clientPortalOrder.estimate_status;
    this.estimate_status_name = clientPortalOrder.estimate_status_name;
    this.end_name = clientPortalOrder.end_name;
    this.end_name_kana = clientPortalOrder.end_name_kana;
    this.end_tel = clientPortalOrder.end_tel;
    this.end_email = clientPortalOrder.end_email;
    this.prefecture_name = clientPortalOrder.prefecture_name;
    this.city = clientPortalOrder.city;
    this.article_type_name = clientPortalOrder.article_type_name;
    this.article_age = clientPortalOrder.article_age;
    this.floor_name = clientPortalOrder.floor_name;
    this.floor_area_name = clientPortalOrder.floor_area_name;
    this.repair_request_timing = clientPortalOrder.repair_request_timing;
    this.repair_request_timing = clientPortalOrder.repair_request_timing;
    this.repair_request_name = clientPortalOrder.repair_request_name;
    this.order_transfered_date = clientPortalOrder.order_transfered_date;
    this.charge_flag = clientPortalOrder.charge_flag;
  }
}

class ClientPortalOrderOrigin implements ClientPortalOrdersOriginInterface {
  id: number;
  order_transfer_cd: string;
  estimate_status: number;
  estimate_status_name: string;
  end_name: string;
  end_name_kana: string;
  end_tel: string;
  end_email: string;
  prefecture_name: string;
  city: string;
  article_type_name: string;
  article_age: string;
  floor_name: string;
  floor_area_name: string;
  repair_request_timing: string;
  repair_request_name: string;
  order_transfered_date: string;
  charge_flag: number;

  constructor(
    id?: number,
    order_transfer_cd?: string,
    estimate_status?: number,
    estimate_status_name?: string,
    end_name?: string,
    end_name_kana?: string,
    end_tel?: string,
    end_email?: string,
    prefecture_name?: string,
    city?: string,
    article_type_name?: string,
    article_age?: string,
    floor_name?: string,
    floor_area_name?: string,
    repair_request_timing?: string,
    repair_request_name?: string,
    order_transfered_date?: string,
    charge_flag?: number
  ) {
    this.id = id || id === 0 ? id : null;
    this.order_transfer_cd = order_transfer_cd ? order_transfer_cd : null;
    this.estimate_status = _.isNumber(estimate_status) ? estimate_status : null;
    this.estimate_status_name = estimate_status_name
      ? estimate_status_name
      : null;
    this.end_name = end_name ? end_name : null;
    this.end_name_kana = end_name_kana ? end_name_kana : null;
    this.end_tel = end_tel ? end_tel : null;
    this.end_email = end_email ? end_email : null;
    this.prefecture_name = prefecture_name ? prefecture_name : null;
    this.city = city ? city : null;
    this.article_type_name = article_type_name ? article_type_name : null;
    this.article_age = article_age ? article_age : null;
    this.floor_name = floor_name ? floor_name : null;
    this.floor_area_name = floor_area_name ? floor_area_name : null;
    this.repair_request_timing = repair_request_timing
      ? repair_request_timing
      : null;
    this.repair_request_name = repair_request_name ? repair_request_name : null;
    this.order_transfered_date = order_transfered_date
      ? order_transfered_date
      : null;
    this.charge_flag = charge_flag ? charge_flag : null;
  }
}
