import { AgainDeliveryOriginInterface } from '@app-units/models/again-delivery/again-delivery-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

/**
 * @classdesc ユーザー情報
 * @export
 * @class AgainDelivery
 */
export class AgainDelivery extends SsSelective implements OriginWrapper {
  private readonly origin: AgainDeliveryOriginInterface;

  id: string;
  user_for_again: number;
  user_for_again_name: string;
  again_introduce_date: string;
  again_introduce_request: string;
  again_introduce_request_name: string;
  again_completed_introduce_request: string;
  again_completed_introduce_request_name: string;
  again_call_again: number;
  again_call_again_name: string;

  get posting_data(): AgainDeliveryOriginInterface {
    return new AgainDeliveryOrigin(
      parseInt(this.id),
      this.user_for_again,
      this.user_for_again_name,
      this.again_introduce_date,
      parseInt(this.again_introduce_request),
      this.again_introduce_request_name,
      parseInt(this.again_completed_introduce_request),
      this.again_completed_introduce_request_name,
      this.again_call_again,
      this.again_call_again_name
    );
  }

  constructor(againDelivery?: AgainDeliveryOriginInterface) {
    super();
    this.origin = this.createEndInfoInstance(againDelivery);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createEndInfoInstance(
    againDelivery: AgainDeliveryOriginInterface
  ): AgainDeliveryOrigin {
    return againDelivery
      ? new AgainDeliveryOrigin(
          againDelivery.id,
          againDelivery.user_for_again,
          againDelivery.user_for_again_name,
          againDelivery.again_introduce_date,
          againDelivery.again_introduce_request,
          againDelivery.again_introduce_request_name,
          againDelivery.again_completed_introduce_request,
          againDelivery.again_completed_introduce_request_name,
          againDelivery.again_call_again,
          againDelivery.again_call_again_name
        )
      : new AgainDeliveryOrigin();
  }

  private initializeProperties(againDelivery: AgainDeliveryOrigin) {
    this.id =
      againDelivery.id || againDelivery.id === 0
        ? String(againDelivery.id)
        : null;
    this.user_for_again = againDelivery.user_for_again
      ? againDelivery.user_for_again
      : null;
    this.user_for_again_name = againDelivery.user_for_again_name
      ? againDelivery.user_for_again_name
      : null;
    this.again_introduce_date = againDelivery.again_introduce_date
      ? againDelivery.again_introduce_date
      : null;
    this.again_introduce_request =
      againDelivery.again_introduce_request ||
      againDelivery.again_introduce_request === 0
        ? String(againDelivery.again_introduce_request)
        : null;
    this.again_introduce_request_name =
      againDelivery.again_introduce_request_name
        ? againDelivery.again_introduce_request_name
        : null;
    this.again_completed_introduce_request =
      againDelivery.again_completed_introduce_request ||
      againDelivery.again_completed_introduce_request === 0
        ? String(againDelivery.again_completed_introduce_request)
        : null;
    this.again_completed_introduce_request_name =
      againDelivery.again_completed_introduce_request_name
        ? againDelivery.again_completed_introduce_request_name
        : null;
    this.again_call_again = _.isNumber(againDelivery.again_call_again)
      ? againDelivery.again_call_again
      : null;
    this.again_call_again_name = againDelivery.again_call_again_name
      ? againDelivery.again_call_again_name
      : null;
  }
}

class AgainDeliveryOrigin implements AgainDeliveryOriginInterface {
  id: number;
  user_for_again: number;
  user_for_again_name: string;
  again_introduce_date: string;
  again_introduce_request: number;
  again_introduce_request_name: string;
  again_completed_introduce_request: number;
  again_completed_introduce_request_name: string;
  again_call_again: number;
  again_call_again_name: string;

  constructor(
    id?: number,
    user_for_again?: number,
    user_for_again_name?: string,
    again_introduce_date?: string,
    again_introduce_request?: number,
    again_introduce_request_name?: string,
    again_completed_introduce_request?: number,
    again_completed_introduce_request_name?: string,
    again_call_again?: number,
    again_call_again_name?: string
  ) {
    this.id = id || id === 0 ? id : null;
    this.user_for_again = user_for_again ? user_for_again : null;
    this.user_for_again_name = user_for_again_name ? user_for_again_name : null;
    this.again_introduce_date = again_introduce_date
      ? again_introduce_date
      : null;
    this.again_introduce_request =
      again_introduce_request || again_introduce_request === 0
        ? again_introduce_request
        : null;
    this.again_introduce_request_name = again_introduce_request_name
      ? again_introduce_request_name
      : null;
    this.again_completed_introduce_request =
      again_completed_introduce_request ||
      again_completed_introduce_request === 0
        ? again_completed_introduce_request
        : null;
    this.again_completed_introduce_request_name =
      again_completed_introduce_request_name
        ? again_completed_introduce_request_name
        : null;
    this.again_call_again = _.isNumber(again_call_again)
      ? again_call_again
      : null;
    this.again_call_again_name = again_call_again_name
      ? again_call_again_name
      : null;
  }
}
