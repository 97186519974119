<div class="page-title">
    <div class="container">
        <ul>
            <ng-template #title>
                <div>Then Block: Hello World!</div>
            </ng-template>

            <li
                *ngFor="let route of routes; let i = index"
                [ngClass]="{ 'parent-page': i === 0, 'child-page': i !== 0 }"
                class="page-title-list"
            >
                <ng-container *ngIf="i === 0">
                    <ng-container *ngIf="routes.length >= 2">
                        <a
                            class="page-title-text"
                            (click)="onNavigate(route)"
                            >{{ route.title }}</a
                        >
                    </ng-container>
                    <ng-container *ngIf="routes.length < 2">
                        <span class="page-title-text">{{ route.title }}</span>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="i !== 0">
                    <i class="icomoon-arrow-right"></i>
                    <span class="page-title-text">{{ route.title }}</span>
                </ng-container>
            </li>
        </ul>
    </div>
</div>
