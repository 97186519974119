import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteConstant } from '@app-core/constants';
import { PageTitleService } from '@app-core/services';
import { SsRouteData } from '@ss-core/models';

/**
 * @classdesc ページタイトル
 * @export
 * @class PageTitleComponent
 */
@Component({
  selector: 'page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent {
  routes: SsRouteData[];

  constructor(
    private pageTitleService: PageTitleService,
    private router: Router
  ) {
    pageTitleService.title.subscribe((routes) => {
      this.routes = routes;
    });
  }

  onNavigate(route: SsRouteData) {
    let path: string;
    switch (route.title) {
      case RouteConstant.REVIEWS.data.title:
        path = RouteConstant.REVIEWS.path;
        break;
      case RouteConstant.USERS.data.title:
        path = RouteConstant.USERS.path;
        break;
      case RouteConstant.CLIENTS.data.title:
        path = RouteConstant.CLIENTS.path;
        break;
      case RouteConstant.ORDER.data.title:
        path = RouteConstant.ORDER.path;
        break;
      case RouteConstant.CLIENT_PORTAL_OFFERED_ORDERS.data.title:
        path = RouteConstant.CLIENT_PORTAL_OFFERED_ORDERS.path;
        break;
      case RouteConstant.CLIENT_PORTAL_ORDERS.data.title:
        path = RouteConstant.CLIENT_PORTAL_ORDERS.path;
        break;
      case RouteConstant.ORDER_EXPORT.data.title:
        path = RouteConstant.ORDER_EXPORT.path;
        break;
      default:
        break;
    }
    this.router.navigate([path]);
  }
}
