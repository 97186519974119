import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { ClientBasicInfo } from '@app-units/models/client-basic-info/client-basic-info';
import { ClientBasicInfoOriginInterface } from '@app-units/models/client-basic-info/client-basic-info-origin';
import { CompatiblePrefecture } from '@app-units/models/compatible-prefecture/compatible-prefecture';
import { CompatiblePrefectureOriginInterface } from '@app-units/models/compatible-prefecture/compatible-prefecture-origin';
import { CompatibleCities } from '@app-units/models/compatible-cities/compatible-cities';
import { CompatibleCitiesOriginInterface } from '@app-units/models/compatible-cities/compatible-cities-origin';
import { Observable } from 'rxjs';

@Injectable()
export class ClientsBasicInfoHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(id: string): Observable<app.response<ClientBasicInfo>> {
    return this.http
      .get(this.getUrl(id))
      .pipe(map(JsonConverter.toClientBasicInfo));
  }

  saveItem(
    id: string,
    postingData: ClientBasicInfoOriginInterface
  ): Observable<app.response<ClientBasicInfo>> {
    return this.http
      .put(this.getUrl(id), postingData)
      .pipe(map(JsonConverter.toClientBasicInfo));
  }

  saveImage(
    id: string,
    postingData: any
  ): Observable<app.response<ClientBasicInfo>> {
    return this.http
      .put(this.getUrl(id, true), postingData)
      .pipe(map(JsonConverter.toClientBasicInfo));
  }

  deleteImage(id: string): Observable<app.response<ClientBasicInfo>> {
    return this.http
      .delete(this.getUrl(id, true))
      .pipe(map(JsonConverter.toClientBasicInfo));
  }

  createUpLoadUrl(): string {
    return '/api/upload';
  }

  fetchCompatiblePrefectures(
    id: string
  ): Observable<app.response<CompatiblePrefecture[]>> {
    return this.http
      .get(this.getUrl(id, false, true))
      .pipe(map(JsonConverter.toCompatiblePrefectre));
  }

  saveCompatiblePrefectures(
    id: string,
    postingData: any
  ): Observable<app.response<CompatiblePrefecture[]>> {
    return this.http
      .put(this.getUrl(id, false, true), postingData)
      .pipe(map(JsonConverter.toCompatiblePrefectre));
  }

  fetchCompatibleCities(
    id: string
  ): Observable<app.response<CompatibleCities[]>> {
    return this.http
      .get(this.getUrl(id, false, false, true))
      .pipe(map(JsonConverter.toCompatibleCities));
  }

  saveCompatibleCities(
    id: string,
    postingData: any
  ): Observable<app.response<CompatibleCities[]>> {
    return this.http
      .put(this.getUrl(id, false, false, true), postingData)
      .pipe(map(JsonConverter.toCompatibleCities));
  }

  private getUrl(
    id: string,
    imageApiUrl?: boolean,
    compatiblePrefectureApiUrl?: boolean,
    compatibleCityApiUrl?: boolean
  ): string {
    if (imageApiUrl) {
      return `/clients/${id}/logo_upload_file`;
    }
    if (compatiblePrefectureApiUrl) {
      return `/clients/${id}/basic_info/compatible_prefectures`;
    }
    if (compatibleCityApiUrl) {
      return `/clients/${id}/basic_info/compatible_cities`;
    }
    return `/clients/${id}/basic_info`;
  }
}
