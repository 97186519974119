import { filter } from 'rxjs/operators';
import {
  Component,
  ComponentFactoryResolver,
  Type,
  ViewChild,
  ViewContainerRef,
  OnInit,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SsDialogComponent } from '@blocks/dialog/ss-dialog/ss-dialog.component';
import { SsModalDialogComponent } from '@blocks/dialog/ss-modal-dialog/ss-modal-dialog.component';
import { Observable } from 'rxjs';

/**
 *
 * SsDialogContainerComponent
 * @description dialogを入れるcomponent
 *
 */
@Component({
  selector: 'ss-dialog-container',
  template: ` <ng-container #element></ng-container>`,
  styleUrls: ['./ss-dialog-container.component.scss'],
})
export class SsDialogContainerComponent implements OnInit {
  /** dialogの追加先Ref */
  @ViewChild('element', { static: true, read: ViewContainerRef })
  private element: ViewContainerRef;
  /** backdropのDOM */
  // @ViewChild('backdrop') public backdrop: ElementRef;

  /** 表示中のdialogComponentを格納 */
  dialogs: Array<SsModalDialogComponent<any>> = [];

  constructor(private resolver: ComponentFactoryResolver, router: Router) {
    // ページ切替え開始で全てのdialogを削除
    router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event) => this.clear());
  }

  ngOnInit() {
    /**
     * OnInitがないと、this.elementがundefinedになる
     * 特に何か処理する必要はないっぽい
     */
    //console.log('container', this.element);
  }

  /**
   * 引数で渡されたDialogComponentを生成してdialogとして表示
   */
  addDialog<T>(DialogComponent: any, data: any): Observable<any> {
    // SsDialogComponentを生成するfactoryを用意
    const factory = this.resolver.resolveComponentFactory(SsDialogComponent);

    // SsDialogComponentを生成してelmentに追加
    const dialogComponentRef = this.element.createComponent(factory);

    // 生成したSsDialogComponentのinstanceを取得
    const dialogComponent: SsDialogComponent =
      dialogComponentRef.instance as SsDialogComponent;

    // SsDialogComponentに引数で渡されたDialogComponentを追加
    const modalDialogComponent: SsModalDialogComponent<T> =
      dialogComponent.addComponent(DialogComponent);

    // dialogComponentを格納
    this.dialogs.push(modalDialogComponent);

    // 遅延でSsDialogComponentのDOMに表示用class追加
    setTimeout(() => {
      dialogComponent.container.nativeElement.classList.add('show');
      dialogComponent.container.nativeElement.classList.add('in');
      this.bodyAddClass();
      // this.backdrop.nativeElement.classList.add('in');
    });

    // dialogComponentのObservableを返却
    return modalDialogComponent.fill(data);
  }

  /**
   * 引数で渡されたdialogComponentを削除
   */
  removeDialog(dialogComponent: SsModalDialogComponent<any>) {
    // SsDialogComponent（dialogComponentの親）のDOMを取得
    const dialogElement = dialogComponent.dialog.container.nativeElement;

    // SsDialogComponentのDOMを非表示にする
    dialogElement.classList.remove('show');
    dialogElement.classList.remove('in');
    // this.backdrop.nativeElement.classList.remove('in');
    this.bodyRemoveClass();

    // 遅延でdialogComponentを削除
    setTimeout(() => this.removeElement(dialogComponent), 300);
  }

  /**
   * 表示中のdialogを全て削除
   */
  clear() {
    this.element.clear();
    this.dialogs = [];
    this.bodyRemoveClass();
  }

  /**
   * 引数で渡されたdialogComponentのDOMを削除
   */
  private removeElement(dialogComponent: SsModalDialogComponent<any>) {
    // 保持してるdialogsから該当するcomponentのindexを取得
    const index = this.dialogs.indexOf(dialogComponent);
    if (index > -1) {
      // 該当するcomponentがあればDOMから削除
      this.element.remove(index);
      // 保持してるdialogsからも削除
      this.dialogs.splice(index, 1);
    }
  }

  /**
   * DOMのbodyにdialog表示用のclassを追加
   */
  private bodyAddClass() {
    $('body').addClass('modal-open');
  }

  /**
   * DOMのbodyからdialog表示用のclassを削除
   */
  private bodyRemoveClass() {
    $('body').removeClass('modal-open');
  }
}
