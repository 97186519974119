import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SsEditButtonComponent } from './ss-edit-button/ss-edit-button.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SsEditButtonComponent],
  exports: [SsEditButtonComponent],
})
export class SsEditButtonModule {}
