import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SsSelectiveAllComponent } from '@blocks/selective/ss-selective-all/ss-selective-all.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SsSelectiveAllComponent],
  exports: [SsSelectiveAllComponent],
})
export class SsSelectiveModule {}
