import { ActionEmailContent } from '@app-units/models/action-email-content/action-email-content';
import { ActionEmailContentOriginInterface } from '@app-units/models/action-email-content/action-email-content-origin';
import {
  ActionMessageMeta,
  ActionMessageOriginInterface,
} from '@app-units/models/action-message/action-message-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

/**
 * オーダーアクションの messages 情報
 */
export class ActionMessage extends SsSelective implements OriginWrapper {
  id: string;
  order_id: string;
  action_content: string;
  action_type: string;
  actor_type: string;
  actor_type_name: string;
  client_id: string;
  client_id_name: string;
  gmail_api_id: number;
  created_date: string;
  created_user_id: string;
  created_user_id_name: string;
  modified_date: string;
  modified_user_id: string;
  modified_user_id_name: string;
  deleted: number;
  deleted_date: string;
  ss_meta: ActionMessageMeta;
  action_email_content: ActionEmailContent;
  client_action_email_content: ActionEmailContent;

  private readonly origin: ActionMessageOriginInterface;

  get posting_data(): ActionMessageOriginInterface {
    return new ActionMessageOrigin(
      parseInt(this.id),
      parseInt(this.order_id),
      this.action_content,
      this.action_type,
      parseInt(this.actor_type),
      this.actor_type_name,
      parseInt(this.client_id),
      this.client_id_name,
      this.gmail_api_id,
      this.created_date,
      parseInt(this.created_user_id),
      this.created_user_id_name,
      this.modified_date,
      parseInt(this.modified_user_id),
      this.modified_user_id_name,
      this.deleted,
      this.deleted_date,
      this.ss_meta,
      this.action_email_content.posting_data,
      this.client_action_email_content.posting_data
    );
  }

  constructor(message?: ActionMessageOriginInterface) {
    super();
    this.origin = this.createActionMessageInstance(message);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createActionMessageInstance(
    message: ActionMessageOriginInterface
  ): ActionMessageOrigin {
    return message
      ? new ActionMessageOrigin(
          message.id,
          message.order_id,
          message.action_content,
          message.action_type,
          message.actor_type,
          message.actor_type_name,
          message.client_id,
          message.client_id_name,
          message.gmail_api_id,
          message.created_date,
          message.created_user_id,
          message.created_user_id_name,
          message.modified_date,
          message.modified_user_id,
          message.modified_user_id_name,
          message.deleted,
          message.deleted_date,
          message.ss_meta,
          message.action_email_content,
          message.client_action_email_content
        )
      : new ActionMessageOrigin();
  }

  private initializeProperties(message: ActionMessageOrigin) {
    this.id = _.isNumber(message.id) ? String(message.id) : null;
    this.order_id = _.isNumber(message.order_id)
      ? String(message.order_id)
      : null;
    this.action_content = message.action_content
      ? message.action_content
      : null;
    this.action_type = message.action_type ? String(message.action_type) : null;
    this.actor_type = _.isNumber(message.actor_type)
      ? String(message.actor_type)
      : null;
    this.actor_type_name = message.actor_type_name
      ? message.actor_type_name
      : null;
    this.client_id = _.isNumber(message.client_id)
      ? String(message.client_id)
      : null;
    this.client_id_name = message.client_id_name
      ? String(message.client_id_name)
      : null;
    this.gmail_api_id = message.gmail_api_id ? message.gmail_api_id : null;
    this.created_date = message.created_date ? message.created_date : null;
    this.created_user_id = _.isNumber(message.created_user_id)
      ? String(message.created_user_id)
      : null;
    this.created_user_id_name = message.created_user_id_name
      ? message.created_user_id_name
      : null;
    this.modified_date = message.modified_date ? message.modified_date : null;
    this.modified_user_id = _.isNumber(message.modified_user_id)
      ? String(message.modified_user_id)
      : null;
    this.modified_user_id_name = message.modified_user_id_name
      ? message.modified_user_id_name
      : null;
    this.deleted = _.isNumber(message.deleted) ? message.deleted : null;
    this.deleted_date = message.deleted_date ? message.deleted_date : null;
    this.ss_meta = message.ss_meta ? message.ss_meta : null;
    this.action_email_content = new ActionEmailContent(
      message.action_email_content
    );
    this.client_action_email_content = new ActionEmailContent(
      message.client_action_email_content
    );
  }
}

class ActionMessageOrigin implements ActionMessageOriginInterface {
  id: number;
  order_id: number;
  action_content: string;
  action_type: string;
  actor_type: number;
  actor_type_name: string;
  client_id: number;
  client_id_name: string;
  gmail_api_id: number;
  created_date: string;
  created_user_id: number;
  created_user_id_name: string;
  modified_date: string;
  modified_user_id: number;
  modified_user_id_name: string;
  deleted: number;
  deleted_date: string;
  ss_meta: ActionMessageMeta;
  action_email_content: ActionEmailContentOriginInterface;
  client_action_email_content: ActionEmailContentOriginInterface;

  constructor(
    id?: number,
    order_id?: number,
    action_content?: string,
    action_type?: string,
    actor_type?: number,
    actor_type_name?: string,
    client_id?: number,
    client_id_name?: string,
    gmail_api_id?: number,
    created_date?: string,
    created_user_id?: number,
    created_user_id_name?: string,
    modified_date?: string,
    modified_user_id?: number,
    modified_user_id_name?: string,
    deleted?: number,
    deleted_date?: string,
    ss_meta?: ActionMessageMeta,
    action_email_content?: ActionEmailContentOriginInterface,
    client_action_email_content?: ActionEmailContentOriginInterface
  ) {
    this.id = _.isNumber(id) ? id : null;
    this.order_id = _.isNumber(order_id) ? order_id : null;
    this.action_content = action_content ? action_content : null;
    this.action_type = action_type ? action_type : null;
    this.actor_type = _.isNumber(actor_type) ? actor_type : null;
    this.actor_type_name = actor_type_name ? actor_type_name : null;
    this.client_id = _.isNumber(client_id) ? client_id : null;
    this.client_id_name = client_id_name ? client_id_name : null;
    this.gmail_api_id = gmail_api_id ? gmail_api_id : null;
    this.created_date = created_date ? created_date : null;
    this.created_user_id = _.isNumber(created_user_id) ? created_user_id : null;
    this.created_user_id_name = created_user_id_name
      ? created_user_id_name
      : null;
    this.modified_date = modified_date ? modified_date : null;
    this.modified_user_id = _.isNumber(modified_user_id)
      ? modified_user_id
      : null;
    this.modified_user_id_name = modified_user_id_name
      ? modified_user_id_name
      : null;
    this.deleted = _.isNumber(deleted) ? deleted : null;
    this.deleted_date = deleted_date ? deleted_date : null;
    this.ss_meta = ss_meta ? ss_meta : null;
    this.action_email_content = action_email_content
      ? _.cloneDeep(action_email_content)
      : null;
    this.client_action_email_content = client_action_email_content
      ? _.cloneDeep(client_action_email_content)
      : null;
  }
}
