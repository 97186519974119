import { ClientStaffOriginInterface } from './client-staff-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class ClientStaff implements OriginWrapper {
  id: number | null;
  partner_id: number | null;
  name: string | null;
  profile_upload_file_id: string | null;
  introduction: string | null;
  created: string | null;
  modified: string | null;
  profile_url: string | null;

  private readonly origin: ClientStaffOriginInterface;

  get posting_data(): ClientStaffOriginInterface {
    return {
      id: this.id,
      partner_id: this.partner_id,
      name: this.name,
      profile_upload_file_id: this.profile_upload_file_id,
      introduction: this.introduction,
      created: this.created,
      modified: this.modified,
    };
  }

  constructor(clientStaff?: ClientStaffOriginInterface) {
    this.origin = this.createClientStaffInstance(clientStaff);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createClientStaffInstance(
    clientStaff?: ClientStaffOriginInterface
  ): ClientStaffOriginInterface {
    return (
      clientStaff || {
        id: null,
        partner_id: null,
        name: null,
        profile_upload_file_id: null,
        introduction: null,
        created: null,
        modified: null,
      }
    );
  }

  private initializeProperties(clientStaff: ClientStaffOriginInterface) {
    this.id = clientStaff.id;
    this.partner_id = clientStaff.partner_id;
    this.name = clientStaff.name;
    this.profile_upload_file_id = clientStaff.profile_upload_file_id;
    this.introduction = clientStaff.introduction;
    this.created = clientStaff.created;
    this.modified = clientStaff.modified;
    this.profile_url = null; // This should be set separately based on your file handling logic
  }
}
