import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { ReviewQuestionnaireInfo } from '@app-units/models/review/review-questionnaire-info';
import { Media } from '@app-units/models/media/media';

@Injectable()
export class ReviewQuestionnaireInfoHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(id: string): Observable<app.response<ReviewQuestionnaireInfo>> {
    return this.http
      .get(this.getUrl(id))
      .pipe(map(JsonConverter.toReviewQuestionnaireInfo));
  }

  saveItem(
    item: ReviewQuestionnaireInfo
  ): Observable<app.response<ReviewQuestionnaireInfo>> {
    return this.http
      .put(this.getUrl(String(item.id)), item)
      .pipe(map(JsonConverter.toReviewQuestionnaireInfo));
  }

  saveImage(
    reviewId: string,
    postingData: {
      file: Media;
      field: 'before' | 'after';
    }
  ): Observable<app.response<Media>> {
    const field = postingData.field;
    const data = { uuid: postingData.file.uuid };
    return this.http
      .put(this.getUrl(reviewId, true, field), data)
      .pipe(map(JsonConverter.toReviewQuestionnaireInfo));
  }

  deleteImage(reviewId: string, field: 'before' | 'after') {
    const url = `/reviews/${reviewId}/images/${field}_image`;
    return this.http
      .delete(this.getUrl(reviewId, true, field))
      .pipe(map(JsonConverter.toReviewQuestionnaireInfo));
  }

  createUpLoadUrl(): string {
    return '/api/upload';
  }

  private getUrl(
    id: string,
    isImgApi: boolean = false,
    field?: 'before' | 'after'
  ) {
    if (isImgApi && field) {
      return `/reviews/${id}/images/${field}_image`;
    }
    return `/reviews/${id}/questionnaire_info`;
  }
}
