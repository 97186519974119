import { TransferredOrderOriginInterface } from '@app-units/models/transferred-order/transferred-order-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class TransferredOrder implements OriginWrapper {
  partner_id: string;
  partner_name: string;
  tel: string;
  order_price: number;
  introduction_type: string;
  introduction_type_name: string;
  order_transfered_date: string;
  add_flag: boolean;
  research_status: string;
  research_status_name: string;
  estimate_status: string;
  estimate_status_name: string;
  contract_flag: boolean;
  contract_type: string;
  contract_type_name: string;
  prefecture: string;
  request_date: string;
  request_reason: string;
  charge_flag: number;
  charge_flag_name: string;
  research_result_reason: string;
  client_charge_result_id: string;
  memo: string;
  mails_csv_to_notify_order: string;
  address: string;
  /* SsType用 */
  name: string;
  id: string;
  /* forClient */
  isEditOrderPrice_: boolean;
  isEditIntroductionType_: boolean;

  private readonly origin: TransferredOrderOriginInterface;

  get posting_data(): TransferredOrderOriginInterface {
    return new TransferredOrigin(
      parseInt(this.partner_id),
      this.partner_name,
      this.tel,
      this.order_price,
      this.introduction_type,
      this.introduction_type_name,
      this.order_transfered_date,
      this.add_flag,
      this.research_status,
      this.research_status_name,
      this.estimate_status,
      this.estimate_status_name,
      this.contract_flag,
      this.contract_type,
      this.contract_type_name,
      this.prefecture,
      this.request_date,
      this.request_reason,
      this.charge_flag,
      this.charge_flag_name,
      this.research_result_reason,
      this.memo,
      this.client_charge_result_id,
      this.mails_csv_to_notify_order,
      this.address
    );
  }

  constructor(survey?: TransferredOrderOriginInterface) {
    this.origin = this.createSurveyInstance(survey);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createSurveyInstance(
    transferred: TransferredOrderOriginInterface
  ): TransferredOrigin {
    return transferred
      ? new TransferredOrigin(
          transferred.partner_id,
          transferred.partner_name,
          transferred.tel,
          transferred.order_price,
          transferred.introduction_type,
          transferred.introduction_type_name,
          transferred.order_transfered_date,
          transferred.add_flag,
          transferred.research_status,
          transferred.research_status_name,
          transferred.estimate_status,
          transferred.estimate_status_name,
          transferred.contract_flag,
          transferred.contract_type,
          transferred.contract_type_name,
          transferred.prefecture,
          transferred.request_date,
          transferred.request_reason,
          transferred.charge_flag,
          transferred.charge_flag_name,
          transferred.research_result_reason,
          transferred.memo,
          transferred.client_charge_result_id,
          transferred.mails_csv_to_notify_order,
          transferred.address
        )
      : new TransferredOrigin();
  }

  private initializeProperties(transferred: TransferredOrigin) {
    this.partner_id = _.isNumber(transferred.partner_id)
      ? String(transferred.partner_id)
      : null;
    this.partner_name = transferred.partner_name;
    this.tel = transferred.tel;
    this.order_price = transferred.order_price;
    this.introduction_type = transferred.introduction_type;
    this.introduction_type_name = transferred.introduction_type_name;
    this.order_transfered_date = transferred.order_transfered_date;
    this.add_flag = transferred.add_flag;
    this.research_status = transferred.research_status;
    this.research_status_name = transferred.research_status_name;
    this.estimate_status = transferred.estimate_status;
    this.estimate_status_name = transferred.estimate_status_name;
    this.contract_flag = transferred.contract_flag;
    this.contract_type = transferred.contract_type;
    this.contract_type_name = transferred.contract_type_name;
    this.prefecture = transferred.prefecture;
    this.request_date = transferred.request_date;
    this.request_reason = transferred.request_reason;
    this.charge_flag = transferred.charge_flag;
    this.charge_flag_name = transferred.charge_flag_name;
    this.research_result_reason = transferred.research_result_reason;
    this.memo = transferred.memo;
    this.client_charge_result_id = transferred.client_charge_result_id;
    this.mails_csv_to_notify_order = transferred.mails_csv_to_notify_order;
    this.address = transferred.address;

    this.id = this.partner_id;
    this.name = this.partner_name;
    if (this.prefecture) {
      this.name = this.name + '（' + this.prefecture + '）';
    }
  }
}

class TransferredOrigin implements TransferredOrderOriginInterface {
  partner_id: number;
  partner_name: string;
  tel: string;
  order_price: number;
  introduction_type: string;
  introduction_type_name: string;
  order_transfered_date: string;
  add_flag: boolean;
  research_status: string;
  research_status_name: string;
  estimate_status: string;
  estimate_status_name: string;
  contract_flag: boolean;
  contract_type: string;
  contract_type_name: string;
  prefecture: string;
  request_date: string;
  request_reason: string;
  charge_flag: number;
  charge_flag_name: string;
  research_result_reason: string;
  memo: string;
  client_charge_result_id: string;
  mails_csv_to_notify_order: string;
  address: string;

  constructor(
    partner_id?: number,
    partner_name?: string,
    tel?: string,
    order_price?: number,
    introduction_type?: string,
    introduction_type_name?: string,
    order_transfered_date?: string,
    add_flag?: boolean,
    research_status?: string,
    research_status_name?: string,
    estimate_status?: string,
    estimate_status_name?: string,
    contract_flag?: boolean,
    contract_type?: string,
    contract_type_name?: string,
    prefecture?: string,
    request_date?: string,
    request_reason?: string,
    charge_flag?: number,
    charge_flag_name?: string,
    research_result_reason?: string,
    memo?: string,
    client_charge_result_id?: string,
    mails_csv_to_notify_order?: string,
    address?: string
  ) {
    this.partner_id = _.isNumber(partner_id) ? partner_id : null;
    this.partner_name = partner_name ? partner_name : null;
    this.tel = tel ? tel : null;
    this.order_price = _.isNumber(order_price) ? order_price : null;
    this.introduction_type = introduction_type ? introduction_type : null;
    this.introduction_type_name = introduction_type_name
      ? introduction_type_name
      : null;
    this.order_transfered_date = order_transfered_date
      ? order_transfered_date
      : null;
    this.add_flag = _.isBoolean(add_flag) ? add_flag : null;
    this.research_status = research_status ? research_status : null;
    this.research_status_name = research_status_name
      ? research_status_name
      : null;
    this.estimate_status = estimate_status ? estimate_status : null;
    this.estimate_status_name = estimate_status_name
      ? estimate_status_name
      : null;
    this.contract_flag = _.isBoolean(contract_flag) ? contract_flag : null;
    this.contract_type = contract_type ? contract_type : null;
    this.contract_type_name = contract_type_name ? contract_type_name : null;
    this.prefecture = prefecture ? prefecture : null;
    this.request_date = request_date ? request_date : null;
    this.request_reason = request_reason ? request_reason : null;
    this.charge_flag = _.isNumber(charge_flag) ? charge_flag : null;
    this.charge_flag_name = charge_flag_name ? charge_flag_name : null;
    this.research_result_reason = research_result_reason
      ? research_result_reason
      : null;
    this.memo = memo ? memo : null;
    this.client_charge_result_id = client_charge_result_id
      ? client_charge_result_id
      : null;
    this.mails_csv_to_notify_order = mails_csv_to_notify_order
      ? mails_csv_to_notify_order
      : null;
    this.address = address ? address : null;
  }
}
