import { AddDeliveryOriginInterface } from '@app-units/models/add-delivery/add-delivery-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class AddDelivery extends SsSelective implements OriginWrapper {
  private readonly origin: AddDeliveryOriginInterface;

  id: string;
  user_for_add: number;
  user_for_add_name: string;
  add_introduce_date: string;
  add_introduce_request: string;
  add_introduce_request_name: string;
  completed_introduce_request: string;
  completed_introduce_request_name: string;
  call_again: number;
  call_again_name: string;

  get posting_data(): AddDeliveryOriginInterface {
    return new AddDeliveryOrigin(
      parseInt(this.id),
      this.user_for_add,
      this.user_for_add_name,
      this.add_introduce_date,
      parseInt(this.add_introduce_request),
      this.add_introduce_request_name,
      parseInt(this.completed_introduce_request),
      this.completed_introduce_request_name,
      this.call_again,
      this.call_again_name
    );
  }

  constructor(addDelivery?: AddDeliveryOriginInterface) {
    super();
    this.origin = this.createAddDeliveryInstance(addDelivery);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createAddDeliveryInstance(
    addDelivery: AddDeliveryOriginInterface
  ): AddDeliveryOrigin {
    return addDelivery
      ? new AddDeliveryOrigin(
          addDelivery.id,
          addDelivery.user_for_add,
          addDelivery.user_for_add_name,
          addDelivery.add_introduce_date,
          addDelivery.add_introduce_request,
          addDelivery.add_introduce_request_name,
          addDelivery.completed_introduce_request,
          addDelivery.completed_introduce_request_name,
          addDelivery.call_again,
          addDelivery.call_again_name
        )
      : new AddDeliveryOrigin();
  }

  private initializeProperties(addDelivery: AddDeliveryOrigin) {
    this.id =
      addDelivery.id || addDelivery.id === 0 ? String(addDelivery.id) : null;
    this.user_for_add = addDelivery.user_for_add
      ? addDelivery.user_for_add
      : null;
    this.user_for_add_name = addDelivery.user_for_add_name
      ? addDelivery.user_for_add_name
      : null;
    this.add_introduce_date = addDelivery.add_introduce_date
      ? addDelivery.add_introduce_date
      : null;
    this.add_introduce_request =
      addDelivery.add_introduce_request ||
      addDelivery.add_introduce_request === 0
        ? String(addDelivery.add_introduce_request)
        : null;
    this.add_introduce_request_name = addDelivery.add_introduce_request_name
      ? addDelivery.add_introduce_request_name
      : null;
    this.completed_introduce_request =
      addDelivery.completed_introduce_request ||
      addDelivery.completed_introduce_request === 0
        ? String(addDelivery.completed_introduce_request)
        : null;
    this.completed_introduce_request_name =
      addDelivery.completed_introduce_request_name
        ? addDelivery.completed_introduce_request_name
        : null;
    this.call_again = _.isNumber(addDelivery.call_again)
      ? addDelivery.call_again
      : null;
    this.call_again_name = addDelivery.call_again_name
      ? addDelivery.call_again_name
      : null;
  }
}

class AddDeliveryOrigin implements AddDeliveryOriginInterface {
  id: number;
  user_for_add: number;
  user_for_add_name: string;
  add_introduce_date: string;
  add_introduce_request: number;
  add_introduce_request_name: string;
  completed_introduce_request: number;
  completed_introduce_request_name: string;
  call_again: number;
  call_again_name: string;

  constructor(
    id?: number,
    user_for_add?: number,
    user_for_add_name?: string,
    add_introduce_date?: string,
    add_introduce_request?: number,
    add_introduce_request_name?: string,
    completed_introduce_request?: number,
    completed_introduce_request_name?: string,
    call_again?: number,
    call_again_name?: string
  ) {
    this.id = id || id === 0 ? id : null;
    this.user_for_add = user_for_add ? user_for_add : null;
    this.user_for_add_name = user_for_add_name ? user_for_add_name : null;
    this.add_introduce_date = add_introduce_date ? add_introduce_date : null;
    this.add_introduce_request =
      add_introduce_request || add_introduce_request === 0
        ? add_introduce_request
        : null;
    this.add_introduce_request_name = add_introduce_request_name
      ? add_introduce_request_name
      : null;
    this.completed_introduce_request =
      completed_introduce_request || completed_introduce_request === 0
        ? completed_introduce_request
        : null;
    this.completed_introduce_request_name = completed_introduce_request_name
      ? completed_introduce_request_name
      : null;
    this.call_again = _.isNumber(call_again) ? call_again : null;
    this.call_again_name = call_again_name ? call_again_name : null;
  }
}
