import { Injectable } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import * as _ from 'lodash';

@Injectable()
export class SsRemoteValidatorService {
  constructor() {}

  setError(formGroup: FormGroup, response: app.response<any>) {
    const { errors, result } = response;
    if (!result) {
      console.log(formGroup);
      this.setRemoteValidationErrors_(errors, formGroup);
    }
  }

  /**
   * サーバーサイドのバリデーション結果を、再帰処理でFormControlに反映する。
   * @param {{[key: string]: any}} parentErrors
   * @param {AbstractControl} formControls
   * @private
   */
  private setRemoteValidationErrors_(
    parentErrors: { [key: string]: any },
    formControls: AbstractControl
  ) {
    if (_.isNil(parentErrors)) {
      // errorがセットされていなければスルー。
      return;
    }
    if (formControls instanceof FormGroup) {
      _.forEach(formControls.controls, (control: AbstractControl, key) => {
        this.setRemoteValidationErrors_(parentErrors[key], control);
      });
    } else if (formControls instanceof FormArray) {
      _.forEach(formControls.controls, (control: AbstractControl, index) => {
        this.setRemoteValidationErrors_(parentErrors[index], control);
      });
    } else if (formControls instanceof FormControl) {
      // FormControlならerrorをセット
      formControls.setErrors(parentErrors ? { remote: parentErrors } : null);
    }
  }
}
