import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IRoutePaths, ROUTES_CONSTANT } from '@app-core/constants';
import { SsRouterService } from '@ss-core/services/ss-router/ss-router.service';
import * as _ from 'lodash';

@Injectable()
export class SsPageBackService {
  constructor(
    @Inject(ROUTES_CONSTANT) private RouteConstant: IRoutePaths,
    private location: Location,
    private ssRouterService: SsRouterService,
    private router: Router
  ) {}

  pageBack(activatedRoute: ActivatedRoute, isSkipBackToIndex?: boolean): void {
    if (!!this.ssRouterService.previousUrl) {
      // 遷移先があれば、もどる。
      this.location.back();
      return;
    }
    const currentPath = _.get(
      activatedRoute.routeConfig,
      'path',
      ''
    ).toString();
    if (!isSkipBackToIndex) {
      let paths: string[];
      if (currentPath === this.RouteConstant.COMMON.CREATE.path) {
        paths = _(this.router.url)
          .split('/')
          .slice(1, -1) // 先頭と最後（/create）を除去。
          .value();
      }
      if (
        _.startsWith(currentPath, this.RouteConstant.COMMON.VIEW.path + '/:')
      ) {
        paths = _(this.router.url)
          .split('/')
          .slice(1, -2) // 先頭と最後2つ（/view/:id）を除去。
          .value();
      }
      this.router.navigate(paths);
      return;
    }
    // 遷移先がなければ、RootPathにもどる。
    this.router.navigate([this.RouteConstant.ROOT_PATH.path]);
  }
}
