import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { SsFormBuilder } from '@blocks/utils/ss-form-builder/ss-form-builder';
import * as _ from 'lodash';

@Injectable()
export class SsSearchService {
  constructor(private fb: SsFormBuilder) {}

  parseSearch(search: any) {
    // pageは1にする
    const result = Object.assign({}, search, { page: '1' });

    Object.keys(result).forEach((key) => {
      const val = result[key];
      if (
        _.isUndefined(val) || // 値がundefined
        (_.isArray(val) && !val.length) || // 空配列
        (_.isString(val) && !val) // 空文字 の場合は項目ごと削除
      ) {
        delete result[key];
      }
    });
    return result;
  }

  /**
   * ネストされたFormGroupの値を、itemの値に更新する
   * @param item
   * @param formGroup
   */
  patchFormGroupValues(item: any, formGroup: FormGroup) {
    this.patchNestedValues_(item, formGroup);
  }

  /**
   * 再帰処理でネストされたFormGroupの値をセットする。
   * @param item
   * @param {AbstractControl} controls
   * @private
   */
  private patchNestedValues_(item: any, controls: AbstractControl) {
    if (controls instanceof FormArray) {
      while (controls.length) {
        controls.removeAt(0);
      }
      _.forEach(item, (value: any) => {
        if (_.isNil(value)) {
          controls.push(this.fb.groupFromModel({}));
        } else {
          controls.push(this.fb.groupFromModel(value));
        }
      });
      return;
    }
    _.forEach(item, (value: any, key: string) => {
      const currentControl = controls.get(key);
      if (_.isNil(currentControl)) {
        // 対象のcontrolがなければスルー。
        return;
      }
      if (_.isObject(value)) {
        // object or array
        this.patchNestedValues_(value, currentControl);
      } else if (currentControl instanceof FormControl) {
        currentControl.patchValue(value);
      } else if (currentControl instanceof FormArray) {
        // null
        while (currentControl.length) {
          currentControl.removeAt(0);
        }
      } else if (currentControl instanceof FormGroup) {
        // null
        currentControl.reset();
      }
    });
  }
}
