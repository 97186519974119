import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { ArticleConstructionInfo } from '@app-units/models/article-construction-info/article-construction-info';
import { ArticleConstructionInfoOriginInterface } from '@app-units/models/article-construction-info/article-construction-info-origin';
import { Observable } from 'rxjs';

@Injectable()
export class ArticleConstructionInfoHttpService {
  constructor(private http: HttpClient) {}

  fetchItem(id: string): Observable<app.response<ArticleConstructionInfo>> {
    return this.http
      .get(this.getUrl(id))
      .pipe(map(JsonConverter.toArticleConstructionInfo));
  }

  fetchPortalItem(
    id: string
  ): Observable<app.response<ArticleConstructionInfo>> {
    return this.http
      .get(`/portal/orders/${id}/article_construction_info`)
      .pipe(map(JsonConverter.toArticleConstructionInfo));
  }

  saveItem(
    item: ArticleConstructionInfoOriginInterface
  ): Observable<app.response<ArticleConstructionInfo>> {
    return this.http
      .put(this.getUrl(String(item.id)), item)
      .pipe(map(JsonConverter.toArticleConstructionInfo));
  }

  private getUrl(id: string) {
    return `/orders/${id}/article_construction_info`;
  }
}
