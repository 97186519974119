import { Profile } from '@app-units/models/profile/profile';
import { ProfileOriginInterface } from '@app-units/models/profile/profile-origin';
import { UserOriginInterface } from '@app-units/models/user/user-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

/**
 * @classdesc ユーザー情報
 * @export
 * @class User
 */
export class User extends SsSelective implements OriginWrapper {
  id: string;
  username: string;
  activated: boolean;
  last_login_date: string;
  created_date: string;
  modified_date: string;
  profile: Profile;
  name: string;
  call_count: string;
  client_call_count: string;
  transfer_count: string;
  role_name: string;
  order_list_view_mode_name: string;

  private readonly origin: UserOriginInterface;

  get posting_data(): UserOriginInterface {
    return new UserOrigin(
      parseInt(this.id),
      this.username,
      this.activated,
      this.last_login_date,
      this.created_date,
      this.modified_date,
      this.profile.posting_data,
      this.call_count,
      this.client_call_count,
      this.transfer_count,
      this.role_name,
      this.order_list_view_mode_name
    );
  }

  get fullName() {
    const last_name = this.profile.last_name ? this.profile.last_name : '';
    const first_name = this.profile.first_name ? this.profile.first_name : '';
    let result = last_name;
    if (first_name.length > 0 && last_name.length > 0) {
      result = result + ' ';
    }
    result = result + first_name;
    return result;
  }

  constructor(user?: UserOriginInterface) {
    super();
    this.origin = this.createUserInstance(user);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createUserInstance(user: UserOriginInterface): UserOrigin {
    return user
      ? new UserOrigin(
          user.id,
          user.username,
          user.activated,
          user.last_login_date,
          user.created_date,
          user.modified_date,
          user.profile,
          user.call_count,
          user.client_call_count,
          user.transfer_count,
          user.role_name,
          user.order_list_view_mode_name
        )
      : new UserOrigin();
  }

  private initializeProperties(user: UserOrigin) {
    this.id = user.id ? String(user.id) : null;
    this.username = user.username;
    this.activated = user.activated;
    this.last_login_date = user.last_login_date;
    this.created_date = user.created_date;
    this.modified_date = user.modified_date;
    this.profile = new Profile(user.profile);
    this.call_count = user.call_count;
    this.client_call_count = user.client_call_count;
    this.transfer_count = user.transfer_count;
    this.role_name = user.role_name;
    this.order_list_view_mode_name = user.order_list_view_mode_name;
  }
}

/**
 * @classdesc APIに合わせたUser情報クラス。
 * @description typescriptにprivate classの概念がないため、
 * 同一ファイル内に２つのクラスを実装。
 * @class UserOrigin
 */
class UserOrigin implements UserOriginInterface {
  id: number;
  username: string;
  activated: boolean;
  last_login_date: string;
  created_date: string;
  modified_date: string;
  profile: ProfileOriginInterface;
  call_count: string;
  client_call_count: string;
  transfer_count: string;
  role_name: string;
  order_list_view_mode_name: string;

  constructor(
    id?: number,
    username?: string,
    activated?: boolean,
    last_login_date?: string,
    created_date?: string,
    modified_date?: string,
    profile?: ProfileOriginInterface,
    call_count?: string,
    client_call_count?: string,
    transfer_count?: string,
    role_name?: string,
    order_list_view_mode_name?: string
  ) {
    this.id = id || null;
    this.username = username || null;
    this.activated = activated ? activated : false;
    this.last_login_date = last_login_date || null;
    this.created_date = created_date || null;
    this.modified_date = modified_date || null;
    this.profile = profile ? _.cloneDeep(profile) : null;
    this.call_count = call_count || null;
    this.client_call_count = client_call_count || null;
    this.transfer_count = transfer_count || null;
    this.role_name = role_name || null;
    this.order_list_view_mode_name = order_list_view_mode_name || null;
  }
}
