import { ActionEmailContentOriginInterface } from '@app-units/models/action-email-content/action-email-content-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class ActionEmailContent implements OriginWrapper {
  id: string;
  action_id: string;
  body: string;
  title: string;

  private readonly origin: ActionEmailContentOriginInterface;

  get posting_data(): ActionEmailContentOriginInterface {
    return new ActionEmailContentOrigin(
      parseInt(this.id),
      parseInt(this.action_id),
      this.body,
      this.title
    );
  }

  constructor(actionEmailContent?: ActionEmailContentOriginInterface) {
    this.origin = this.createActionMailInstance(actionEmailContent);
    this.initializeProperties(this.origin);
  }

  private createActionMailInstance(
    actionEmailContent: ActionEmailContentOriginInterface
  ): ActionEmailContentOrigin {
    return actionEmailContent
      ? new ActionEmailContentOrigin(
          actionEmailContent.id,
          actionEmailContent.action_id,
          actionEmailContent.body,
          actionEmailContent.title
        )
      : new ActionEmailContentOrigin();
  }

  private initializeProperties(note: ActionEmailContentOrigin) {
    this.id = _.isNumber(note.id) ? String(note.id) : null;
    this.action_id = _.isNumber(note.action_id) ? String(note.action_id) : null;
    this.body = note.body;
    this.title = note.title;
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }
}

class ActionEmailContentOrigin implements ActionEmailContentOriginInterface {
  id: number;
  action_id: number;
  body: string;
  title: string;

  constructor(id?: number, action_id?: number, body?: string, title?: string) {
    this.id = _.isNumber(id) ? id : null;
    this.action_id = _.isNumber(action_id) ? action_id : null;
    this.body = body ? body : null;
    this.title = title ? title : null;
  }
}
