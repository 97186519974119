import { ChargeDetailResultOriginInterface } from '@app-units/models/charge-detail-result/charge-detail-result-origin';
import { OriginWrapper } from '@ss-core/models';

export class ChargeDetailResult implements OriginWrapper {
  id: string;
  charge_detail_result: string;

  private readonly origin: ChargeDetailResultOriginInterface;

  get posting_data(): ChargeDetailResultOriginInterface {
    return new ChargeDetailResultOrigin(
      Number(this.id),
      this.charge_detail_result
    );
  }

  constructor(profile?: ChargeDetailResultOriginInterface) {
    this.origin = this.createChargeDetailResultInstance(profile);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createChargeDetailResultInstance(
    chargeDetailResult: ChargeDetailResultOriginInterface
  ): ChargeDetailResultOrigin {
    return chargeDetailResult
      ? new ChargeDetailResultOrigin(
          chargeDetailResult.id,
          chargeDetailResult.charge_detail_result
        )
      : new ChargeDetailResultOrigin();
  }

  private initializeProperties(chargeDetailResult: ChargeDetailResultOrigin) {
    this.id = chargeDetailResult.id ? String(chargeDetailResult.id) : null;
    this.charge_detail_result = chargeDetailResult.charge_detail_result;
  }
}

class ChargeDetailResultOrigin implements ChargeDetailResultOriginInterface {
  id: number;
  charge_detail_result: string;

  constructor(id?: number, charge_detail_result?: string) {
    this.id = id ? id : null;
    this.charge_detail_result = charge_detail_result
      ? charge_detail_result
      : null;
  }
}
