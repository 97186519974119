import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { AdInfo } from '@app-units/models/ad-info/ad-info';
import { Observable } from 'rxjs';

@Injectable()
export class AdInfoHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(clientId: string): Observable<app.response<AdInfo>> {
    return this.http
      .get(this.getUrl(clientId))
      .pipe(map(JsonConverter.toAdinfo));
  }

  saveItems(
    clientId: string,
    postingData: any
  ): Observable<app.response<AdInfo>> {
    return this.http
      .put(this.getUrl(clientId), postingData)
      .pipe(map(JsonConverter.toAdinfo));
  }

  createUpLoadUrl(): string {
    return '/api/upload';
  }

  private getUrl(id: string) {
    return `/clients/${id}/ad_info`;
  }
}
