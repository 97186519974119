import { OrderClientRelationOriginInterface } from '@app-units/models/order-client-relation/order-client-relation-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';
import * as dayjs from 'dayjs';

export class OrderClientRelation implements OriginWrapper {
  id: string;
  order_transfer_cd: string;
  estimate_status: string;
  next_call_date: string;
  next_call_timing: string;
  next_call_datetime: string;
  estimate_submission_date: string;
  /** FormControl生成用 */
  next_call_time_: string;

  private readonly origin: OrderClientRelationOriginInterface;

  get posting_data(): OrderClientRelationOriginInterface {
    return new OrderClientRelationOrigin(
      Number(this.id),
      this.order_transfer_cd,
      Number(this.estimate_status),
      this.next_call_date,
      Number(this.next_call_timing),
      this.next_call_datetime,
      this.estimate_submission_date
    );
  }

  constructor(orderClientRelation?: OrderClientRelationOriginInterface) {
    this.origin = this.createOrderClientRelationInstance(orderClientRelation);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createOrderClientRelationInstance(
    orderClientRelation: OrderClientRelationOrigin
  ): OrderClientRelationOrigin {
    return orderClientRelation
      ? new OrderClientRelationOrigin(
          orderClientRelation.id,
          orderClientRelation.order_transfer_cd,
          orderClientRelation.estimate_status,
          orderClientRelation.next_call_date,
          orderClientRelation.next_call_timing,
          orderClientRelation.next_call_datetime,
          orderClientRelation.estimate_submission_date
        )
      : new OrderClientRelationOrigin();
  }

  private initializeProperties(
    orderClientRelation: OrderClientRelationOriginInterface
  ) {
    this.id = _.isNumber(orderClientRelation.id)
      ? String(orderClientRelation.id)
      : null;
    this.order_transfer_cd = orderClientRelation.order_transfer_cd
      ? String(orderClientRelation.order_transfer_cd)
      : null;
    this.estimate_status = _.isNumber(orderClientRelation.estimate_status)
      ? String(orderClientRelation.estimate_status)
      : null;
    this.next_call_date = orderClientRelation.next_call_date;
    this.next_call_timing = _.isNumber(orderClientRelation.next_call_timing)
      ? String(orderClientRelation.next_call_timing)
      : null;
    this.next_call_datetime = orderClientRelation.next_call_datetime
      ? dayjs(
          orderClientRelation.next_call_datetime,
          'YYYY/MM/DD HH:mm'
        ).format('YYYY/MM/DD')
      : null;
    this.estimate_submission_date =
      orderClientRelation.estimate_submission_date;
    /** 次にかけるタイミングの「日時指定」（クライアント用作成） */
    this.next_call_time_ = orderClientRelation.next_call_datetime
      ? dayjs(
          orderClientRelation.next_call_datetime,
          'YYYY/MM/DD HH:mm'
        ).format()
      : null;
  }
}

class OrderClientRelationOrigin implements OrderClientRelationOriginInterface {
  id: number;
  order_transfer_cd: string;
  estimate_status: number;
  next_call_date: string;
  next_call_timing: number;
  next_call_datetime: string;
  estimate_submission_date: string;

  constructor(
    id?: number,
    order_transfer_cd?: string,
    estimate_status?: number,
    next_call_date?: string,
    next_call_timing?: number,
    next_call_datetime?: string,
    estimate_submission_date?: string
  ) {
    this.id = _.isNumber(id) ? id : null;
    this.order_transfer_cd = order_transfer_cd ? order_transfer_cd : null;
    this.estimate_status = _.isNumber(estimate_status) ? estimate_status : null;
    this.next_call_date = next_call_date ? next_call_date : null;
    this.next_call_timing = _.isNumber(next_call_timing)
      ? next_call_timing
      : null;
    this.next_call_datetime = next_call_datetime ? next_call_datetime : null;
    this.estimate_submission_date = estimate_submission_date
      ? estimate_submission_date
      : null;
  }
}
