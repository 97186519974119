import { AttachmentOriginInterface } from '@app-units/models/attachment/attachment-origin';
import { Media } from '@app-units/models/media/media';
import { MediaOriginInterface } from '@app-units/models/media/media-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class Attachment implements OriginWrapper {
  id: string;
  upload_file_id: string;
  order_id: string;
  created_date: string;
  created_user_id_name: string;
  doc_type: string;
  doc_type_name: string;
  client_id: string;
  client_name: string;
  media: Media;

  private readonly origin: AttachmentOriginInterface;

  get posting_data(): AttachmentOriginInterface {
    return new AttachmentOrigin(
      Number(this.id),
      Number(this.upload_file_id),
      Number(this.order_id),
      this.created_date,
      this.created_user_id_name,
      Number(this.doc_type),
      this.doc_type_name,
      Number(this.client_id),
      this.client_name,
      this.media.posting_data
    );
  }

  constructor(attachment?: AttachmentOriginInterface) {
    this.origin = this.createAttachmentInstance(attachment);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createAttachmentInstance(
    attachment: AttachmentOriginInterface
  ): AttachmentOrigin {
    return attachment
      ? new AttachmentOrigin(
          attachment.id,
          attachment.upload_file_id,
          attachment.order_id,
          attachment.created_date,
          attachment.created_user_id_name,
          attachment.doc_type,
          attachment.doc_type_name,
          attachment.client_id,
          attachment.client_name,
          attachment.media
        )
      : new AttachmentOrigin();
  }

  private initializeProperties(attachment: AttachmentOrigin) {
    this.id = _.isNumber(attachment.id) ? String(attachment.id) : null;
    this.upload_file_id = _.isNumber(attachment.upload_file_id)
      ? String(attachment.upload_file_id)
      : null;
    this.order_id = _.isNumber(attachment.order_id)
      ? String(attachment.order_id)
      : null;
    this.created_date = attachment.created_date;
    this.created_user_id_name = attachment.created_user_id_name;
    this.doc_type = _.isNumber(attachment.doc_type)
      ? String(attachment.doc_type)
      : null;
    this.doc_type_name = attachment.doc_type_name;
    this.client_id = _.isNumber(attachment.client_id)
      ? String(attachment.client_id)
      : null;
    this.client_name = attachment.client_name;
    this.media = new Media(attachment.media);
  }
}

class AttachmentOrigin implements AttachmentOriginInterface {
  id: number;
  upload_file_id: number;
  order_id: number;
  created_date: string;
  created_user_id_name: string;
  doc_type: number;
  doc_type_name: string;
  client_id: number;
  client_name: string;
  media: MediaOriginInterface;

  constructor(
    id?: number,
    upload_file_id?: number,
    order_id?: number,
    created_date?: string,
    created_user_id_name?: string,
    doc_type?: number,
    doc_type_name?: string,
    client_id?: number,
    client_name?: string,
    media?: MediaOriginInterface
  ) {
    this.id = _.isNumber(id) ? id : null;
    this.upload_file_id = _.isNumber(upload_file_id) ? upload_file_id : null;
    this.order_id = _.isNumber(order_id) ? order_id : null;
    this.created_date = created_date ? created_date : null;
    this.created_user_id_name = created_user_id_name
      ? created_user_id_name
      : null;
    this.doc_type = _.isNumber(doc_type) ? doc_type : null;
    this.doc_type_name = doc_type_name ? doc_type_name : null;
    this.client_id = _.isNumber(client_id) ? client_id : null;
    this.client_name = client_name ? client_name : null;
    this.media = media ? _.cloneDeep(media) : null;
  }
}
