import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

/** SsComponents */
import { SsPipeModule } from '@blocks/pipe/ss-pipe.module';
import { SsValidateErrorComponent } from '@blocks/validate-error/ss-validate-error/ss-validate-error.component';

@NgModule({
  imports: [CommonModule, SsPipeModule],
  declarations: [SsValidateErrorComponent],
  exports: [SsValidateErrorComponent],
})
export class SsValidateErrorModule {}
