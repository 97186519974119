import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SsEllipsisTextComponent } from './ss-ellipsis-text/ss-ellipsis-text.component';

@NgModule({
  imports: [CommonModule, TooltipModule.forRoot()],
  declarations: [SsEllipsisTextComponent],
  exports: [SsEllipsisTextComponent],
})
export class SsEllipsisTextModule {}
