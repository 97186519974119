import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { Attachment } from '@app-units/models/attachment/attachment';
import { Observable } from 'rxjs';

@Injectable()
export class EndDocsHttpService {
  constructor(private http: HttpClient) {}

  private url(
    isClientPortal: boolean = false,
    orderId: string,
    attachmentId?: string
  ): string {
    if (attachmentId) {
      return isClientPortal
        ? `/portal/orders/${orderId}/end_docs/${attachmentId}`
        : `/orders/${orderId}/end_docs/${attachmentId}`;
    }
    return isClientPortal
      ? `/portal/orders/${orderId}/end_docs`
      : `/orders/${orderId}/end_docs`;
  }

  fetchItems(
    orderId: string,
    isClientPortal: boolean = false
  ): Observable<app.response<Attachment[]>> {
    const url = this.url(isClientPortal, orderId);
    return this.http
      .get<app.response<Attachment[]>>(url)
      .pipe(map(JsonConverter.toAttachment));
  }

  postItem(
    orderId: string,
    postingData: any,
    isClientPortal: boolean = false
  ): Observable<app.response<Attachment[]>> {
    const url = this.url(isClientPortal, orderId);
    return this.http
      .post<app.response<Attachment[]>>(url, postingData)
      .pipe(map(JsonConverter.toAttachment));
  }

  deleteItem(
    orderId: string,
    attachmentId: string,
    isClientPortal: boolean = false
  ): Observable<app.response<Attachment[]>> {
    const url = this.url(isClientPortal, orderId, attachmentId);
    return this.http
      .delete<app.response<Attachment[]>>(url)
      .pipe(map(JsonConverter.toAttachment));
  }

  createUpLoadUrl(): string {
    return '/api/upload';
  }
}
