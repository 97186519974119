import { ClientInfoOriginInterface } from '@app-units/models/client-info/client-info-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

/**
 * @classdesc クライアント情報
 * @export
 * @class ClientInfo
 */
export class ClientInfo implements OriginWrapper {
  partner_id: string;
  partner_cd: string;
  partner_name: string;
  client_reaction: string;
  client_reaction_name: string;
  introduction_type: string;
  introduction_type_name: string;
  order_transfer_status: string;
  order_transfer_status_name: string;
  order_price: string;
  contract_type: string;
  contract_type_name: string;
  introduce_date: string;
  last_introduce_date: string;
  prefecture: string;
  is_order_transfer_candidate: boolean;
  tel: string;
  address: string;
  mails_csv_to_notify_order: string;
  order_transfered_date: string;
  tantousya: string;
  /* SsType用 */
  name: string;
  id: string;
  /* forClient */
  isEdit_: boolean;
  isEditOrderPrice_: boolean;
  isEditIntroductionType_: boolean;
  isEditRevertStatus_: boolean;
  /** クリップボードへコピー完了した旨のtooltipの表示状態 */
  isShowedCopiedMessage: boolean;
  /** オーダー転送時ダイアログでのチェック状態 */
  checked?: boolean;

  private readonly origin: ClientInfoOriginInterface;

  get posting_data(): ClientInfoOriginInterface {
    return new ClientInfoOrigin(
      parseInt(this.partner_id),
      this.partner_cd,
      this.partner_name,
      this.client_reaction,
      this.client_reaction_name,
      this.introduction_type,
      this.introduction_type_name,
      this.order_transfer_status,
      this.order_transfer_status_name,
      this.order_price,
      this.contract_type,
      this.contract_type_name,
      this.introduce_date,
      this.last_introduce_date,
      this.prefecture,
      this.is_order_transfer_candidate,
      this.tel,
      this.address,
      this.mails_csv_to_notify_order,
      this.order_transfered_date,
      this.tantousya
    );
  }

  constructor(clientInfo?: ClientInfoOriginInterface) {
    this.origin = this.createClientInfoInstance(clientInfo);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createClientInfoInstance(
    clientInfo: ClientInfoOriginInterface
  ): ClientInfoOrigin {
    return clientInfo
      ? new ClientInfoOrigin(
          clientInfo.partner_id,
          clientInfo.partner_cd,
          clientInfo.partner_name,
          clientInfo.client_reaction,
          clientInfo.client_reaction_name,
          clientInfo.introduction_type,
          clientInfo.introduction_type_name,
          clientInfo.order_transfer_status,
          clientInfo.order_transfer_status_name,
          clientInfo.order_price,
          clientInfo.contract_type,
          clientInfo.contract_type_name,
          clientInfo.introduce_date,
          clientInfo.last_introduce_date,
          clientInfo.prefecture,
          clientInfo.is_order_transfer_candidate,
          clientInfo.tel,
          clientInfo.address,
          clientInfo.mails_csv_to_notify_order,
          clientInfo.order_transfered_date,
          clientInfo.tantousya
        )
      : new ClientInfoOrigin();
  }

  private initializeProperties(clientInfo: ClientInfoOrigin) {
    this.partner_id = clientInfo.partner_id
      ? String(clientInfo.partner_id)
      : null;
    this.partner_name = clientInfo.partner_name;
    this.partner_cd = clientInfo.partner_cd;
    this.client_reaction = clientInfo.client_reaction
      ? clientInfo.client_reaction
      : null;
    this.client_reaction_name = clientInfo.client_reaction_name;
    this.introduction_type = clientInfo.introduction_type
      ? clientInfo.introduction_type
      : null;
    this.introduction_type_name = clientInfo.introduction_type_name;
    this.order_transfer_status = clientInfo.order_transfer_status
      ? clientInfo.order_transfer_status
      : null;
    this.order_transfer_status_name = clientInfo.order_transfer_status_name;
    this.order_price = clientInfo.order_price;
    this.contract_type = clientInfo.contract_type
      ? clientInfo.contract_type
      : null;
    this.contract_type_name = clientInfo.contract_type_name;
    this.introduce_date = clientInfo.introduce_date;
    this.last_introduce_date = clientInfo.last_introduce_date;
    this.prefecture = clientInfo.prefecture;
    this.is_order_transfer_candidate = clientInfo.is_order_transfer_candidate;
    this.tel = clientInfo.tel;
    this.address = clientInfo.address;
    this.mails_csv_to_notify_order = clientInfo.mails_csv_to_notify_order;
    this.order_transfered_date = clientInfo.order_transfered_date;
    this.tantousya = clientInfo.tantousya;
    this.id = this.partner_id;
    this.name = this.partner_name;
    if (this.prefecture) {
      this.name = this.name + '（' + this.prefecture + '）';
    }
  }
}

/**
 * @classdesc APIに合わせたクライアント情報クラス
 * @description typescriptにprivate classの概念がないため、
 * 同一ファイル内に２つのクラスを実装。
 * @class ClientInfoOriginInterface
 */
class ClientInfoOrigin implements ClientInfoOriginInterface {
  partner_id: number;
  partner_cd: string;
  partner_name: string;
  client_reaction: string;
  client_reaction_name: string;
  introduction_type: string;
  introduction_type_name: string;
  order_transfer_status: string;
  order_transfer_status_name: string;
  order_price: string;
  contract_type: string;
  contract_type_name: string;
  introduce_date: string;
  last_introduce_date: string;
  prefecture: string;
  is_order_transfer_candidate: boolean;
  tel: string;
  address: string;
  mails_csv_to_notify_order: string;
  order_transfered_date: string;
  tantousya: string;

  constructor(
    partner_id?: number,
    partner_cd?: string,
    partner_name?: string,
    client_reaction?: string,
    client_reaction_name?: string,
    introduction_type?: string,
    introduction_type_name?: string,
    order_transfer_status?: string,
    order_transfer_status_name?: string,
    order_price?: string,
    contract_type?: string,
    contract_type_name?: string,
    introduce_date?: string,
    last_introduce_date?: string,
    prefecture?: string,
    is_order_transfer_candidate?: boolean,
    tel?: string,
    address?: string,
    mails_csv_to_notify_order?: string,
    order_transfered_date?: string,
    tantousya?: string
  ) {
    this.partner_id = _.isNumber(partner_id) ? partner_id : null;
    this.partner_cd = partner_cd ? partner_cd : null;
    this.partner_name = partner_name ? partner_name : null;
    this.client_reaction = client_reaction ? client_reaction : null;
    this.client_reaction_name = client_reaction_name
      ? client_reaction_name
      : null;
    this.introduction_type = introduction_type ? introduction_type : null;
    this.introduction_type_name = introduction_type_name
      ? introduction_type_name
      : null;
    this.order_transfer_status = order_transfer_status
      ? order_transfer_status
      : null;
    this.order_transfer_status_name = order_transfer_status_name
      ? order_transfer_status_name
      : null;
    this.order_price = !_.isNil(order_price) ? order_price : null;
    this.contract_type = contract_type ? contract_type : null;
    this.contract_type_name = contract_type_name ? contract_type_name : null;
    this.introduce_date = introduce_date ? introduce_date : null;
    this.last_introduce_date = last_introduce_date ? last_introduce_date : null;
    this.prefecture = prefecture ? prefecture : null;
    this.is_order_transfer_candidate = _.isBoolean(is_order_transfer_candidate)
      ? is_order_transfer_candidate
      : null;
    this.tel = tel ? tel : null;
    this.address = address ? address : null;
    this.mails_csv_to_notify_order = mails_csv_to_notify_order
      ? mails_csv_to_notify_order
      : null;
    this.order_transfered_date = order_transfered_date
      ? order_transfered_date
      : null;
    this.tantousya = tantousya ? tantousya : null;
  }
}
