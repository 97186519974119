import { AdInfoOriginInterface } from '@app-units/models/ad-info/ad-info-origin';
import { Attachment } from '@app-units/models/attachment/attachment';
import { AttachmentOriginInterface } from '@app-units/models/attachment/attachment-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import * as _ from 'lodash';

export class AdInfo extends SsSelective {
  private readonly origin: AdInfoOriginInterface;

  ad1: Attachment;
  ad2: Attachment;
  ad3: Attachment;
  ad4: Attachment;
  ad5: Attachment;
  ad6: Attachment;

  get posting_data(): AdInfoOriginInterface {
    return new AdInfoOrigin(
      this.ad1.posting_data,
      this.ad2.posting_data,
      this.ad3.posting_data,
      this.ad4.posting_data,
      this.ad5.posting_data,
      this.ad6.posting_data
    );
  }

  constructor(adInfo?: AdInfoOriginInterface) {
    super();
    this.origin = this.createAdInfoInstance(adInfo);
    this.initializeProperties(this.origin);
  }

  private createAdInfoInstance(adInfo: AdInfoOriginInterface): AdInfoOrigin {
    return adInfo
      ? new AdInfoOrigin(
          adInfo.ad1,
          adInfo.ad2,
          adInfo.ad3,
          adInfo.ad4,
          adInfo.ad5,
          adInfo.ad6
        )
      : new AdInfoOrigin();
  }

  private initializeProperties(adInfo: AdInfoOrigin) {
    this.ad1 = adInfo.ad1 ? new Attachment(adInfo.ad1) : new Attachment();
    this.ad2 = adInfo.ad2 ? new Attachment(adInfo.ad2) : new Attachment();
    this.ad3 = adInfo.ad3 ? new Attachment(adInfo.ad3) : new Attachment();
    this.ad4 = adInfo.ad4 ? new Attachment(adInfo.ad4) : new Attachment();
    this.ad5 = adInfo.ad5 ? new Attachment(adInfo.ad5) : new Attachment();
    this.ad6 = adInfo.ad6 ? new Attachment(adInfo.ad6) : new Attachment();
  }
}

class AdInfoOrigin implements AdInfoOriginInterface {
  ad1: AttachmentOriginInterface;
  ad2: AttachmentOriginInterface;
  ad3: AttachmentOriginInterface;
  ad4: AttachmentOriginInterface;
  ad5: AttachmentOriginInterface;
  ad6: AttachmentOriginInterface;

  constructor(
    ad1?: AttachmentOriginInterface,
    ad2?: AttachmentOriginInterface,
    ad3?: AttachmentOriginInterface,
    ad4?: AttachmentOriginInterface,
    ad5?: AttachmentOriginInterface,
    ad6?: AttachmentOriginInterface
  ) {
    this.ad1 = ad1 ? _.cloneDeep(ad1) : null;
    this.ad2 = ad2 ? _.cloneDeep(ad2) : null;
    this.ad3 = ad3 ? _.cloneDeep(ad3) : null;
    this.ad4 = ad4 ? _.cloneDeep(ad4) : null;
    this.ad5 = ad5 ? _.cloneDeep(ad5) : null;
    this.ad6 = ad6 ? _.cloneDeep(ad6) : null;
  }
}
