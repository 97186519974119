import { ReportMonthlyDataOriginInterface } from '@app-units/models/report-monthly-data/report-monthly-data-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class ReportMonthlyData implements OriginWrapper {
  private readonly origin: ReportMonthlyDataOriginInterface;

  '1': number;
  '2': number;
  '3': number;
  '4': number;
  '5': number;
  '6': number;
  '7': number;
  '8': number;
  '9': number;
  '10': number;
  '11': number;
  '12': number;

  get posting_data(): ReportMonthlyDataOriginInterface {
    return new ReportMonthlyDataOrigin(
      this['1'],
      this['2'],
      this['3'],
      this['4'],
      this['5'],
      this['6'],
      this['7'],
      this['8'],
      this['9'],
      this['10'],
      this['11'],
      this['12']
    );
  }

  constructor(reportMonthlyData?: ReportMonthlyDataOriginInterface) {
    this.origin = this.createReportMonthlyDataInfonstance(reportMonthlyData);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createReportMonthlyDataInfonstance(
    reportMonthlyData: ReportMonthlyDataOriginInterface
  ): ReportMonthlyDataOrigin {
    return reportMonthlyData
      ? new ReportMonthlyDataOrigin(
          reportMonthlyData['1'],
          reportMonthlyData['2'],
          reportMonthlyData['3'],
          reportMonthlyData['4'],
          reportMonthlyData['5'],
          reportMonthlyData['6'],
          reportMonthlyData['7'],
          reportMonthlyData['8'],
          reportMonthlyData['9'],
          reportMonthlyData['10'],
          reportMonthlyData['11'],
          reportMonthlyData['12']
        )
      : new ReportMonthlyDataOrigin();
  }

  private initializeProperties(reportMonthlyData: ReportMonthlyDataOrigin) {
    this['1'] = reportMonthlyData['1'];
    this['2'] = reportMonthlyData['2'];
    this['3'] = reportMonthlyData['3'];
    this['4'] = reportMonthlyData['4'];
    this['5'] = reportMonthlyData['5'];
    this['6'] = reportMonthlyData['6'];
    this['7'] = reportMonthlyData['7'];
    this['8'] = reportMonthlyData['8'];
    this['9'] = reportMonthlyData['9'];
    this['10'] = reportMonthlyData['10'];
    this['11'] = reportMonthlyData['11'];
    this['12'] = reportMonthlyData['12'];
  }
}

class ReportMonthlyDataOrigin implements ReportMonthlyDataOriginInterface {
  '1': number;
  '2': number;
  '3': number;
  '4': number;
  '5': number;
  '6': number;
  '7': number;
  '8': number;
  '9': number;
  '10': number;
  '11': number;
  '12': number;

  constructor(
    _1?: number,
    _2?: number,
    _3?: number,
    _4?: number,
    _5?: number,
    _6?: number,
    _7?: number,
    _8?: number,
    _9?: number,
    _10?: number,
    _11?: number,
    _12?: number
  ) {
    this['1'] = _.isNumber(_1) ? _1 : 0;
    this['2'] = _.isNumber(_2) ? _2 : 0;
    this['3'] = _.isNumber(_3) ? _3 : 0;
    this['4'] = _.isNumber(_4) ? _4 : 0;
    this['5'] = _.isNumber(_5) ? _5 : 0;
    this['6'] = _.isNumber(_6) ? _6 : 0;
    this['7'] = _.isNumber(_7) ? _7 : 0;
    this['8'] = _.isNumber(_8) ? _8 : 0;
    this['9'] = _.isNumber(_9) ? _9 : 0;
    this['10'] = _.isNumber(_10) ? _10 : 0;
    this['11'] = _.isNumber(_11) ? _11 : 0;
    this['12'] = _.isNumber(_12) ? _12 : 0;
  }
}
