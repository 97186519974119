import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

/**
 * for Sentry
 * - エラー検知用
 */
Sentry.init({
  dsn: 'https://4b118046beb54417a81f9970aebfd69b@o484317.ingest.sentry.io/5537374',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'https://manage.gaiheki.support',
        'https://manage-stg.gaiheki.support',
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

/**
 * @desc ログイン認証後アプリ（AppModule）のエントリポイント
 * @returns {Promise<any>}
 */
function main() {
  return platformBrowserDynamic().bootstrapModule(AppModule);
}

document.addEventListener('DOMContentLoaded', () => {
  main().catch((err) => console.error(err));
});
