import { ActionNoteOriginInterface } from '@app-units/models/action-note/action-note-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import * as _ from 'lodash';

export class ActionNote extends SsSelective {
  private readonly origin: ActionNoteOriginInterface;

  id: string;
  action_note: string;

  get posting_data(): ActionNoteOriginInterface {
    return new ActionNoteOrigin(parseInt(this.id), this.action_note);
  }

  constructor(note?: ActionNoteOriginInterface) {
    super();
    this.origin = this.createActionNoteInstance(note);
    this.initializeProperties(this.origin);
  }

  private createActionNoteInstance(
    note: ActionNoteOriginInterface
  ): ActionNoteOrigin {
    return note
      ? new ActionNoteOrigin(note.id, note.action_note)
      : new ActionNoteOrigin();
  }

  private initializeProperties(note: ActionNoteOrigin) {
    this.id = _.isNumber(note.id) ? String(note.id) : null;
    this.action_note = note.action_note ? note.action_note : null;
  }
}

class ActionNoteOrigin implements ActionNoteOriginInterface {
  id: number;
  action_note: string;

  constructor(id?: number, action_note?: string) {
    this.id = _.isNumber(id) ? id : null;
    this.action_note = action_note ? action_note : null;
  }
}
