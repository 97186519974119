import {
  range as observableRange,
  from as observableFrom,
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';

import { tap, filter, map } from 'rxjs/operators';
import { OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

/**
 * selectiveのmodel
 */
export class SsSelective {
  id?: string;
  _selected?: boolean;
  _disabled?: boolean;
}

/**
 * selectiveのconfig
 */
export interface SsSelectiveConfig {
  /** 複数選択フラグ */
  multiple?: boolean;
  /** 選択対象の一覧データが変更（ページングなど）された後も選択状態を保持しておくフラグ */
  pageStraddle?: boolean;
}

/**
 * selectiveのconfig初期値
 */
const SsSelectiveConfigDefault: SsSelectiveConfig = {
  multiple: false,
  pageStraddle: true,
};

/**
 * dialogイベント
 */
export enum SELECTIVE_STATE {
  /** 選択候補がない場合 */
  EMPTY = 0,
  /** 未選択状態 */
  NONE,
  /** 全選択状態 */
  ALL,
  /** 部分的に選択状態 */
  PARTIAL,
}

/**
 * 選択中データのストリーム
 */
export interface SsSelectiveChanges {
  /** 選択中のId配列 */
  selectedId: string[];
  /** 選択中のデータ配列 */
  selectedItem: SsSelective[];
}

/**
 *
 * SsSelectiveAbstract
 * @description 一覧データ選択
 *
 */
export abstract class SsSelectiveAbstract implements OnChanges {
  /** 選択対象の一覧データ */
  abstract items: SsSelective[];
  /** 選択中データ */
  abstract selected: string[] | SsSelective[];
  /** config設定メソッド */
  abstract setUpSelectiveConfig(): SsSelectiveConfig;

  /** 選択中データ Subject*/
  selected$ = new Subject<SsSelectiveChanges>();
  /** 選択状態 Subject*/
  selectedState$ = new BehaviorSubject<SELECTIVE_STATE>(null);

  /** config */
  private _selectiveConfig: SsSelectiveConfig;
  /** 選択中データ */
  private _selectedItem: SsSelective[] = [];
  /** 選択状態 Subject*/
  private _selectedState: SELECTIVE_STATE;
  /** 最後に選択状態を変更した一覧のindex値 */
  private _lastIndex: number;

  constructor() {}

  ///////////////////////////////////
  // NG LIFE CYCLE //////////////////
  ///////////////////////////////////

  ngOnChanges(changes: SimpleChanges) {
    // _selectiveConfigの初期化
    if (!this._selectiveConfig) {
      const config = this.setUpSelectiveConfig() || {};
      this._selectiveConfig = Object.assign(
        {},
        SsSelectiveConfigDefault,
        config
      );
    }

    if (changes['selected']) {
      const selectedItem = [];
      _.each(this.selected, (selected) => {
        if (_.isPlainObject(selected)) {
          selectedItem.push(selected);
        }
      });
      this.selectedItem = selectedItem;
      this._updateItemsSelected();
    }

    // 選択対象の一覧データが更新された時
    if (changes['items']) {
      if (this._selectiveConfig.pageStraddle) {
        // 選択状態を保持する場合
        this._updateItemsSelected();
        this._updateSelectedState();
      } else {
        // 選択状態を保持しない場合
        this.selectedItem = [];
        this._changeSelective();
      }
      this._lastIndex = null;
    }
  }

  ///////////////////////////////////
  // EVENT HANDLER //////////////////
  ///////////////////////////////////

  /**
   * 選択ハンドラ
   */
  private onSelective(event: MouseEvent, item: SsSelective, index: number) {
    if (event) {
      event.stopPropagation();
    }
    // disabled状態なら処理しない
    if (this.isSelectiveDisabled(item)) {
      return;
    }
    // shift選択の判定
    const isShiftSelective =
      event.shiftKey &&
      this._selectiveConfig.multiple &&
      !_.isNil(this._lastIndex);
    this._updateSelective(item, index, isShiftSelective);
  }

  /**
   * 全選択ハンドラ（pageはまたがない）
   */
  private onAllSelective(event: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    if (!this.items || !this.items.length) {
      return;
    }
    const selected = this._selectedState === SELECTIVE_STATE.NONE;
    this._bulkUpdateSelective(observableFrom(this.items), selected);
  }

  /**
   * 全選択解除ハンドラ
   */
  onAllClear() {
    this._allClearSelective();
    this._changeSelective();
  }

  ///////////////////////////////////
  // ITEM STATE CHANGES /////////////
  ///////////////////////////////////

  /**
   * 選択状態の更新
   */
  private _updateSelective(
    item: SsSelective,
    index: number,
    isShiftSelective: boolean
  ) {
    // 変更後の選択状態
    const selected = !item._selected;
    // 複数選択判定
    const { multiple } = this._selectiveConfig;

    // shiftKeyを押しながらの場合
    if (isShiftSelective) {
      this._shiftSelective(index, selected);
      this._lastIndex = index;
      return;
    }

    // 単数選択の場合は一旦全てクリア
    if (!multiple) {
      this._allClearSelective();
    }

    // 一覧データのitemの選択状態を変更
    item._selected = selected;

    if (selected) {
      this._addSelectedItem([item]);
    } else {
      if (multiple) {
        this._removeSelectedItem([item]);
      } else {
        // 単数選択で選択解除の場合はここで変更イベント発火
        this._changeSelective();
      }
    }

    this._lastIndex = index;
  }

  /**
   * 選択中データ（selectedItem）を追加
   */
  private _addSelectedItem(items: SsSelective[]) {
    // 正式に追加するitem格納用
    const addItems = [];
    // 選択中データのidを配列で取得
    const selectedId = _.map(this.selectedItem, (item) =>
      this.selectiveTargetProp(item)
    );

    // 追加データをstreamで流して（重複追加の対応）
    observableFrom(items)
      .pipe(
        // 選択中データにないものだけを絞り込んで
        filter(
          (item) => selectedId.indexOf(this.selectiveTargetProp(item)) === -1
        )
      )
      .subscribe(
        (item) => addItems.push(item),
        () => {},
        () => {
          this.selectedItem = [...this.selectedItem, ...addItems];
          this._changeSelective();
        }
      );
  }

  /**
   * 選択中データ（selectedItem）を削除
   */
  private _removeSelectedItem(items: SsSelective[]) {
    const itemId = _.map(items, (item) => this.selectiveTargetProp(item));
    this.selectedItem = _.reject(
      this.selectedItem,
      (item) => itemId.indexOf(this.selectiveTargetProp(item)) !== -1
    );
    this._changeSelective();
  }

  /**
   * 全ての選択状態をクリア（一覧側と選択中データ側の両方）
   */
  private _allClearSelective() {
    // 一覧データ側をクリア
    if (this.items) {
      observableFrom(this.items)
        .pipe(filter((item) => item._selected))
        .subscribe((item) => (item._selected = false));
    }
    // 選択中データ側をクリア
    this.selectedItem = [];
  }

  /**
   * 一括で選択状態を変更（一覧側と選択中データ側の両方）
   */
  private _bulkUpdateSelective(
    observable: Observable<SsSelective>,
    selected: boolean
  ) {
    const updateItems = [];

    observable
      .pipe(
        // itemを配列に格納しておく
        tap((item) => updateItems.push(item))
      )
      .subscribe(
        // 選択状態更新（一覧側）
        (item) => (item._selected = selected),
        () => {},
        // selectedItem側（選択中データ）も更新
        () => {
          if (selected) {
            this._addSelectedItem(updateItems);
          } else {
            this._removeSelectedItem(updateItems);
          }
        }
      );
  }

  /**
   * 一括選択（shiftKey）
   */
  private _shiftSelective(index: number, selected: boolean) {
    // 一つ前に変更したindexを取得
    const { _lastIndex } = this;
    // 一つ前に変更したのとこれから変更するindexの間のカウントを絶対値で取得
    const count = Math.abs(index - _lastIndex) + 1;
    // 選択対象の一覧データを取得
    const { items } = this;
    // 開始indexを設定
    const start = _lastIndex < index ? _lastIndex : index;

    // 変更するitemをstreamにする
    const target$ = observableRange(start, count).pipe(
      // indexからitemに変換
      map((i) => items[i]),
      // 選択変更すべきものだけを絞り込み
      filter(
        (item) => item._selected != selected && !this.isSelectiveDisabled(item)
      )
    );

    this._bulkUpdateSelective(target$, selected);
  }

  /**
   * 選択中データの変更を流す
   */
  private _changeSelective() {
    this.selected$.next({
      selectedId: _.map(this.selectedItem, (item) =>
        this.selectiveTargetProp(item)
      ),
      selectedItem: _.clone(this.selectedItem),
    });
    this._updateSelectedState();
  }

  /**
   * 一覧側の選択状態をselectedItem（保持してる選択中データ）と同期させる
   */
  private _updateItemsSelected() {
    // 選択中データのidを配列で取得
    const selectedId = _.map(this.selectedItem, (item) =>
      this.selectiveTargetProp(item)
    );
    if (!selectedId.length || !this.items) {
      return;
    }

    // 一覧側のデータをstreamで流して
    observableFrom(this.items)
      .pipe(
        // 選択中データにあれば
        filter(
          (item) => selectedId.indexOf(this.selectiveTargetProp(item)) !== -1
        )
      )
      // 選択状態にする
      .subscribe((item) => (item._selected = true));
  }

  /**
   * 全選択状態の変更を流す
   */
  private _updateSelectedState() {
    // 一覧側で選択状態になってるものだけを取得
    const itemsSelected = _.filter(this.items, (item) => item._selected);

    // 選択状態判定
    let result;
    if (this.items && this.items.length) {
      if (itemsSelected.length) {
        if (itemsSelected.length >= this.items.length) {
          // 全選択状態
          result = SELECTIVE_STATE.ALL;
        } else {
          // 部分的に選択状態
          result = SELECTIVE_STATE.PARTIAL;
        }
      } else {
        // 未選択状態
        result = SELECTIVE_STATE.NONE;
      }
    } else {
      // 選択候補（items）がない場合
      result = SELECTIVE_STATE.EMPTY;
    }

    this._selectedState = result;
    this.selectedState$.next(result);
  }

  /**
   * selectedItem setter
   */
  private set selectedItem(items: SsSelective[]) {
    this._selectedItem = items;
  }

  /**
   * selectedItem getter
   */
  private get selectedItem() {
    return this._selectedItem;
  }

  ///////////////////////////////////
  // OTHER //////////////////////////
  ///////////////////////////////////

  /**
   * disabed判定（override前提）
   */
  isSelectiveDisabled(item: SsSelective) {
    return item._disabled;
  }

  /**
   * 選択する為のプロパティ設定
   */
  selectiveTargetProp(item: SsSelective) {
    return String(item.id);
  }
}
