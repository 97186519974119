import { from as observableFrom, Observable } from 'rxjs';

import { tap, filter } from 'rxjs/operators';
import { Component, Inject, Input } from '@angular/core';
import { ISsConstant, SS_CONSTANT } from '@ss-core/constants';
import { SsRoute } from '@ss-core/models';
import { AuthService } from '@ss-core/services/auth/auth.service';
import * as _ from 'lodash';
import { appRoutes } from '../../../app.routes';
import { PROFILE_ROLE } from '@app-units/models/profile/profile';

/**
 * @classdesc ヘッダ
 * @export
 * @class HeaderComponent
 */
@Component({
  // headerはhtmlタグとかぶるのでappのprefixをつける
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() isHiddenHeaderMenu: boolean;

  /** プロジェクトタイトル */
  projectTitle: string;
  /** ナビゲーション情報 */
  navigation: SsRoute[] = [];
  /** 課金除外申請のリンクURL **/
  linkToRequest: string;

  constructor(
    @Inject(SS_CONSTANT) SS_CONSTANT: ISsConstant,
    private authService: AuthService
  ) {
    // プロジェクトタイトルを取得
    this.projectTitle = SS_CONSTANT.PROJECT_TITLE;

    // AuthUserを取得
    const authUser = this.authService.authUser();

    // ログインユーザーがクライアントだったら（クライアントポータルなら）表示
    if (authUser.profile.role === PROFILE_ROLE.CLIENT && authUser.username) {
      this.linkToRequest = `https://doors-my.com/doors/client/returns?id=${authUser.username}&id_check=done&from=client_portal`;
    }

    // 表示するrouteを設定（ナビゲーション）
    observableFrom(appRoutes)
      .pipe(
        // titleが設定されてるrouteのみを絞り込み
        filter((route) => this.filterDisplayRoute_(route)),
        tap((route) => this.removeUnDisplayChildren_(route)),
        // ログインユーザーの権限に従って、表示するメニューを絞る
        filter((route) => route.data.isDoorsPage === authUser.isDoors)
      )
      .subscribe((route) => {
        this.navigation.push(route);
      })
      .unsubscribe();
  }

  private filterDisplayRoute_(route): boolean {
    return route.data && route.data.title && route.data.icon;
  }

  /**
   * サイドメニューに表示しないルーティングの除去を再帰的に実施します。
   * @param route
   * @private
   */
  private removeUnDisplayChildren_(route) {
    if (!!route.children) {
      _.remove(route.children, (child) => !this.filterDisplayRoute_(child));
    }
    _.forEach(route.children, (child) => this.removeUnDisplayChildren_(child));
  }
}
