import { ProfileOriginInterface } from '@app-units/models/profile/profile-origin';
import { OriginWrapper, SsType } from '@ss-core/models';
import * as _ from 'lodash';

/** ユーザー権限種別 */
export enum PROFILE_ROLE {
  // 管理者
  ADMIN = '0',
  // 販売担当
  DELIVERY = '1',
  // 架電担当
  CALL = '2',
  // 追加架電担当
  ADD_CALL = '3',
  // クライアント
  CLIENT = '4',
  // アフィリエイト
  AFFILIATE = '5',
  // 一般
  GENERAL = '9',
}

/**
 * @classdesc ユーザープロフィール情報
 * @export
 * @class Profile
 */
export class Profile implements OriginWrapper {
  last_name: string;
  first_name: string;
  email: string;
  role: string;
  order_list_view_mode_id: string;

  private readonly origin: ProfileOriginInterface;

  get posting_data(): ProfileOriginInterface {
    return new ProfileOrigin(
      this.last_name,
      this.first_name,
      this.email,
      parseInt(this.role),
      parseInt(this.order_list_view_mode_id)
    );
  }

  constructor(profile?: ProfileOriginInterface) {
    this.origin = this.createProfileInstance(profile);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createProfileInstance(profile: ProfileOrigin): ProfileOrigin {
    return profile
      ? new ProfileOrigin(
          profile.last_name,
          profile.first_name,
          profile.email,
          profile.role,
          profile.order_list_view_mode_id
        )
      : new ProfileOrigin();
  }

  private initializeProperties(profile: ProfileOrigin) {
    this.last_name = profile.last_name;
    this.first_name = profile.first_name;
    this.email = profile.email;
    this.role = /^[0-9]$/.test(String(profile.role))
      ? String(profile.role)
      : null;
    this.order_list_view_mode_id = /^[0-9]$/.test(
      String(profile.order_list_view_mode_id)
    )
      ? String(profile.order_list_view_mode_id)
      : null;
  }
}

/**
 * @classdesc APIに合わせたユーザープロフィール情報クラス
 * @description typescriptにprivate classの概念がないため、
 * 同一ファイル内に２つのクラスを実装。
 * @class ProfileOrigin
 */
class ProfileOrigin implements ProfileOriginInterface {
  last_name: string;
  first_name: string;
  email: string;
  role: number;
  order_list_view_mode_id: number;

  constructor(
    last_name?: string,
    first_name?: string,
    email?: string,
    role?: number,
    order_list_view_mode_id?: number
  ) {
    this.last_name = last_name ? last_name : null;
    this.first_name = first_name ? first_name : null;
    this.email = email ? email : null;
    this.role = /^[0-9]$/.test(String(role)) ? role : null;
    this.order_list_view_mode_id = /^[0-9]$/.test(
      String(order_list_view_mode_id)
    )
      ? order_list_view_mode_id
      : null;
  }
}
