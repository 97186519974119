import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { ReviewMails } from '@app-units/models/review/review-mails';
import { Observable } from 'rxjs';

type SendMail = {
  review_request_mail_text: string;
};
export type SendMailPreview = {
  body: string;
  title: string;
  from: {
    name: string;
    email: string;
  };
  to: {
    name: string;
    email: string;
  };
};

@Injectable()
export class ReviewMailsHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(id: string): Observable<app.response<ReviewMails>> {
    return this.http
      .get(this.getUrl(id))
      .pipe(map(JsonConverter.toReviewMails));
  }

  saveItem(item: ReviewMails): Observable<app.response<ReviewMails>> {
    return this.http
      .put(this.getUrl(String(item.review_id)), item)
      .pipe(map(JsonConverter.toReviewMails));
  }

  getMailPreview(
    order_id,
    data: SendMail
  ): Observable<app.response<SendMailPreview>> {
    const url = `/orders/${order_id}/mails/send_review_request/preview`;
    return this.http.post<app.response<SendMailPreview>>(url, data);
  }

  sendMail(order_id: string, data: SendMail): Observable<app.response<any>> {
    const url = `/orders/${order_id}/mails/send_review_request`;
    return this.http.post<any>(url, data);
  }

  private getUrl(id: string) {
    return `/orders/${id}/review_mails`;
  }
}
