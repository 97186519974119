/**
 * @desc temaplateで使用する定数 interface
 * @export
 * @interface ITemlateConstans
 */
export interface ITemlateConstans {
  // ss-selectで選択候補がない場合の文言
  SS_SELECT_NO_CHOICE: string;
}

/**
 * temaplateで使用する定数
 */
export const TEMPLATE_CONSTANT: ITemlateConstans = {
  SS_SELECT_NO_CHOICE: '該当する項目が見つかりません',
};

/**
 * 業者MapURL
 */
export const TRADER_MAP_URL =
  'https://script.google.com/a/macros/doors.holdings/s/AKfycby4kV7vgxvLncprKodV_NfRb5MOObHRNXG1OMFKbSNUo-ZCSwrf/exec';
