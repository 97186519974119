import { FormBuilder, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class SsFormBuilder extends FormBuilder {
  public groupFromModel(model: any): FormGroup {
    // 参照元に影響させないように、ディープコピーして処理する。
    const formModel: any = _.cloneDeep(model);
    return this.group(this.buildFormGroup_(formModel));
  }

  /**
   * ネストしている要素に対して、再帰処理でFormGroupまたはFormArrayに置換します。
   * @param model
   * @return {any}
   * @private
   */
  private buildFormGroup_(model: any): any {
    this.removeReservedWord_(model);
    _.forEach(model, (value: any, key: string) => {
      if (_.isArray(value)) {
        const formArray: any[] = [];
        _.forEach(value, (formControl) => {
          if (_.isNull(formControl)) {
            formArray.push(formControl);
          } else {
            formArray.push(this.group(this.buildFormGroup_(formControl)));
          }
        });
        model[key] = this.array(formArray);
      } else if (_.isNull(value)) {
        /* do nothing */
      } else if (_.isBoolean(value)) {
        /* do nothing */
      } else if (_.isObject(value)) {
        model[key] = this.group(this.buildFormGroup_(value));
      }
    });
    return model;
  }

  /**
   * modelの予約語を取り除く
   * @param model
   */
  private removeReservedWord_(model: any): void {
    delete model.origin;
    delete model.meta;
  }
}
