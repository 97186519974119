import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  ClientsHttpService,
  OrdersHttpService,
  ReviewsHttpService,
  ProgressHttpService,
  UsersHttpService,
} from '@app-core/http-services';
import { ActionsHttpService } from '@app-core/http-services/actions/actions-http.service';
import { AdInfoHttpService } from '@app-core/http-services/ad-info/ad-info-http.service';
import { AddDeliveryHttpService } from '@app-core/http-services/add-delivery/add-delivery-http.service';
import { AgainDeliveryHttpService } from '@app-core/http-services/again-delivery/again-delivery-http.service';
import { ArticleConstructionInfoHttpService } from '@app-core/http-services/article-construction-info/article-construction-info-http.service';
import { ClientActionsHttpService } from '@app-core/http-services/client-actions/client-actions-http.service';
import { ClientDocsHttpService } from '@app-core/http-services/client-docs/client-docs-http.service';
import { ClientInfoHttpService } from '@app-core/http-services/client-info/client-info-http.service';
import { ClientManagementDocsHttpService } from '@app-core/http-services/client-management-docs/client-management-docs-http.service';
import { ClientPortalEntryInfoService } from '@app-core/http-services/client-portal-entry-info/client-portal-entry-info.service';
import { ClientPortalOfferedOrdersHttpService } from '@app-core/http-services/client-portal-offered-orders/client-portal-offered-orders-http.service';
import { ClientPortalOrdersHttpService } from '@app-core/http-services/client-portal-orders/client-portal-orders-http.service';
import { ClientPortalProgressHttpService } from '@app-core/http-services/client-portal-progress/client-portal-progress-http.service';
import { ClientsBasicInfoHttpService } from '@app-core/http-services/clients-basic-info/clients-basic-info-http.service';
import { ContentHttpService } from '@app-core/http-services/content/content-http.service';
import { ContractInfoHttpService } from '@app-core/http-services/contract-info/contract-info-http.service';
import { ContractHttpService } from '@app-core/http-services/contract/contract-http.service';
import { EndDocsHttpService } from '@app-core/http-services/end-docs/end-docs-http.service';
import { EndInfoHttpService } from '@app-core/http-services/end-info/end-info-http.service';
import { MailHttpService } from '@app-core/http-services/mail/mail-http.service';
import { MyAspHttpService } from '@app-core/http-services/my-asp/my-asp-http.service';
import { OfferedOrderHistoriesHttpService } from '@app-core/http-services/offered-order-histories/offered-order-histories-http.service';
import { OfferedOrdersHttpService } from '@app-core/http-services/offered-orders/offered-orders-http.service';
import { QuestionnaireMemoInfoHttpService } from '@app-core/http-services/questionnaire-memo-info/questionnaire-memo-info-http.service';
import { ReportsHttpService } from '@app-core/http-services/reports/reports-http.service';
import { SmsInfoHttpService } from '@app-core/http-services/sms-info/sms-info-http.service';
import { SurveysHttpService } from '@app-core/http-services/surveys/surveys-http.service';
import { ReviewManageInfoHttpService } from '@app-core/http-services/review-manage-info/review-manage-info-http.service';
import { ReviewQuestionnaireInfoHttpService } from '@app-core/http-services/review-questionnaire-info/review-questionnaire-info-http.service';
import { ReviewOrderClientInfoHttpService } from '@app-core/http-services/review-order-client-info/review-order-client-info-http.service';
import { ReviewMailsHttpService } from '@app-core/http-services/review-mails/review-mails-http.service';
import { TransferredOrdersHttpService } from '@app-core/http-services/transferred-orders/transferred-orders-http.service';
import { AddressBookService } from '@app-core/services/address-book/address-book.service';
import { BeforeUnloadGuard } from '@app-core/services/before-unload/before-unload-guard';
import { RouterPrevService } from '@app-core/services/router-prev/router-prev.service';
import { AppSharedModule } from '@app-shared/app-shared.module';
import { CookieModule } from 'ngx-cookie';
import {
  AuthUserProfileComponent,
  ErrorComponent,
  HeaderComponent,
  PageTitleComponent,
  SideNavComponent,
} from './components';
import { RouteConstant, ROUTES_CONSTANT } from './constants';
import { ConfigResolve } from './resolves';
import {
  ConfigHttpService,
  NotificationCenterService,
  PageTitleService,
} from './services';

// CORE_PROVIDERS
const CORE_PROVIDERS = [
  // ルーティング定数
  {
    provide: ROUTES_CONSTANT,
    useValue: RouteConstant,
  },
  ConfigResolve,
  ConfigHttpService,
  PageTitleService,
  NotificationCenterService,
  AddressBookService,
  // --------------
  UsersHttpService,
  ClientsHttpService,
  OrdersHttpService,
  ReviewsHttpService,
  EndDocsHttpService,
  EndInfoHttpService,
  AddDeliveryHttpService,
  SmsInfoHttpService,
  AgainDeliveryHttpService,
  ContractInfoHttpService,
  SurveysHttpService,
  ArticleConstructionInfoHttpService,
  ClientInfoHttpService,
  ProgressHttpService,
  ActionsHttpService,
  MyAspHttpService,
  ClientPortalOfferedOrdersHttpService,
  ClientPortalProgressHttpService,
  ClientPortalOrdersHttpService,
  ContractHttpService,
  ContentHttpService,
  ClientDocsHttpService,
  ClientActionsHttpService,
  AdInfoHttpService,
  RouterPrevService,
  ClientPortalEntryInfoService,
  OfferedOrderHistoriesHttpService,
  MailHttpService,
  ClientManagementDocsHttpService,
  BeforeUnloadGuard,
  OfferedOrdersHttpService,
  TransferredOrdersHttpService,
  ClientsBasicInfoHttpService,
  ReportsHttpService,
  QuestionnaireMemoInfoHttpService,
  ReviewManageInfoHttpService,
  ReviewQuestionnaireInfoHttpService,
  ReviewOrderClientInfoHttpService,
  ReviewMailsHttpService,
];

// CORE_COMPONENTS
const CORE_COMPONENTS = [
  ErrorComponent,
  HeaderComponent,
  AuthUserProfileComponent,
  PageTitleComponent,
  SideNavComponent,
];

/**
 * @classdesc ログイン認証後のアプリ全体で共有したいproviderやModuleを入れるmodule
 * @description
 * ・component・directiveはAppModuleでのみ使用するものだけを入れる
 * ・AppModuleでのみimport
 * ・その他のmoduleではimportしてはいけない
 */
@NgModule({
  imports: [AppSharedModule, CommonModule, CookieModule.forRoot()],
  declarations: [...CORE_COMPONENTS],
  exports: [...CORE_COMPONENTS],
})
export class AppCoreModule {
  static forRoot(): ModuleWithProviders<AppCoreModule> {
    return {
      ngModule: AppCoreModule,
      providers: [...CORE_PROVIDERS],
    };
  }
}
