/**
 * ReviewモデルとなるClass
 * - APIの戻り値をプロパティにセットする
 * - フィールドは必ず存在するものとする（Optionalにはならない）
 * - 既定メンバでないプロパティは生成しない
 * - Non-null
 */
export class ReviewMails {
  /** @todo SsSelectiveではid:stringだった。stringである意味があるか？ */
  review_id: number = 0;
  /** メール送信日時 */
  review_request_mail_send_datetime: string = '';
  /** メール送信用テキスト */
  review_request_mail_text: string = '';

  /**
   * @example fb.groupFromModel(new ReviewMails());
   */
  constructor(data?) {
    if (data) {
      this.review_id = data.review_id;
      this.review_request_mail_send_datetime =
        data.review_request_mail_send_datetime;
      this.review_request_mail_text = data.review_request_mail_text;
    }
  }

  /**
   * nullを初期値に変換して、コンストラクタに渡す
   * @example const data = ReviewMails.initData(json.data)
   */
  static initData(data: ReviewMails) {
    let procData = {} as any;
    Object.entries(data).forEach(([key, val]) => {
      switch (key) {
        // number | nullのプロパティ
        case 'review_id':
          procData[`${key}`] = val ?? 0;
          return;

        // Nullを返すメンバの大部分は string | null
        default:
          procData[`${key}`] = val ?? '';
          return;
      }
    });

    return new ReviewMails(procData as ReviewMails);
  }
}
