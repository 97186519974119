import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DatepickerConfig, DatepickerModule } from 'ngx-bootstrap/datepicker';

/** SsComponents */
import { SsBirthdaySelectComponent } from '@blocks/form/ss-birthday-select/ss-birthday-select.component';
import { SsBtnGroupComponent } from '@blocks/form/ss-btn-group/ss-btn-group.component';
import { SsButtonComponent } from '@blocks/form/ss-button/ss-button.component';
import { SsCheckboxComponent } from '@blocks/form/ss-checkbox/ss-checkbox.component';
import { SsDateSelectComponent } from '@blocks/form/ss-date-select/ss-date-select.component';
import { SsDatepickerComponent } from '@blocks/form/ss-datepicker/ss-datepicker.component';
import { datepickerConfig } from '@blocks/form/ss-datepicker/ss-datepicker.config';
import { SsFileInputComponent } from '@blocks/form/ss-file-input/ss-file-input.component';
import { SsIncInputDirective } from '@blocks/form/ss-inc-input/ss-inc-input.directive';
import { SsInputComponent } from '@blocks/form/ss-input/ss-input.component';
import { SsMonthSelectComponent } from '@blocks/form/ss-month-select/ss-month-select.component';
import { SsRadioComponent } from '@blocks/form/ss-radio/ss-radio.component';
import { SsSetFirstFocusDirective } from '@blocks/form/ss-set-first-focus/ss-set-first-focus.directive';
import { SsTextAreaAutoSizeDirective } from '@blocks/form/ss-textarea/ss-textarea-auto-size.directive';
import { SsTextareaComponent } from '@blocks/form/ss-textarea/ss-textarea.component';
import { SsTokenInputComponent } from '@blocks/form/ss-token-input/ss-token-input.component';
import { SsYearSelectComponent } from '@blocks/form/ss-year-select/ss-year-select.component';
import { SsSelectModule } from '@blocks/select/ss-select.module';
import { SsValidateErrorModule } from '@blocks/validate-error/ss-validate-error.module';

/**
 * SsRadioComponentの依存関係解消のために明示的にimportの順番を記述。
 * SsRadioComponentの後に読み込む。
 */
import { SsRadioGroupComponent } from '@blocks/form/ss-radio-group/ss-radio-group.component';

/**
 * SsCheckboxComponentの依存関係解消のために明示的にimportの順番を記述。
 * SsCheckboxComponentの後に読み込む。
 */
import { SsCheckboxGroupComponent } from '@blocks/form/ss-checkbox-group/ss-checkbox-group.component';
import { SsDebounceClickDirective } from '@blocks/form/ss-debounce-click/ss-debounce-click.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SsValidateErrorModule,
    DatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    SsSelectModule,
    BsDropdownModule,
  ],
  declarations: [
    SsIncInputDirective,
    SsCheckboxComponent,
    SsRadioComponent,
    SsRadioGroupComponent,
    SsBtnGroupComponent,
    SsCheckboxGroupComponent,
    SsInputComponent,
    SsDatepickerComponent,
    SsButtonComponent,
    SsTextareaComponent,
    SsSetFirstFocusDirective,
    SsBirthdaySelectComponent,
    SsYearSelectComponent,
    SsMonthSelectComponent,
    SsDateSelectComponent,
    SsFileInputComponent,
    SsTokenInputComponent,
    SsTextAreaAutoSizeDirective,
    SsDebounceClickDirective,
  ],
  exports: [
    SsIncInputDirective,
    SsCheckboxComponent,
    SsRadioComponent,
    SsRadioGroupComponent,
    SsBtnGroupComponent,
    SsCheckboxGroupComponent,
    SsInputComponent,
    SsDatepickerComponent,
    SsButtonComponent,
    SsTextareaComponent,
    SsSetFirstFocusDirective,
    SsBirthdaySelectComponent,
    SsYearSelectComponent,
    SsMonthSelectComponent,
    SsDateSelectComponent,
    SsFileInputComponent,
    SsTokenInputComponent,
    SsTextAreaAutoSizeDirective,
    SsDebounceClickDirective,
  ],
})
export class SsFormModule {
  static forRoot(): ModuleWithProviders<SsFormModule> {
    return {
      ngModule: SsFormModule,
      providers: [
        // bootstrapのdatepickerの初期option設定
        { provide: DatepickerConfig, useFactory: datepickerConfig },
      ],
    };
  }
}
