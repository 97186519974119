import { SurveyOriginInterface } from '@app-units/models/survey/survey-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

/**
 * @classdesc アンケート
 * @export
 * @class Survey
 */
export class Survey extends SsSelective implements OriginWrapper {
  private readonly origin: SurveyOriginInterface;

  id: string;
  order_id: number;
  title: string;
  published_flag_name: string;
  published_flag: number;
  memo: string;
  created_date: string;
  created_user_id: number;
  modified_date: string;
  modified_user_id: number;
  /** formControl生成用 */
  is_edit: boolean;

  get posting_data(): SurveyOriginInterface {
    return new SurveyOrigin(
      parseInt(this.id),
      this.order_id,
      this.title,
      this.published_flag_name,
      this.published_flag,
      this.memo,
      this.created_date,
      this.created_user_id,
      this.modified_date,
      this.modified_user_id
    );
  }

  constructor(survey?: SurveyOriginInterface) {
    super();
    this.origin = this.createSurveyInstance(survey);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createSurveyInstance(survey: SurveyOriginInterface): SurveyOrigin {
    return survey
      ? new SurveyOrigin(
          survey.id,
          survey.order_id,
          survey.title,
          survey.published_flag_name,
          survey.published_flag,
          survey.memo,
          survey.created_date,
          survey.created_user_id,
          survey.modified_date,
          survey.modified_user_id
        )
      : new SurveyOrigin();
  }

  private initializeProperties(survey: SurveyOrigin) {
    this.id = survey.id ? String(survey.id) : null;
    this.order_id = _.isNumber(survey.order_id) ? survey.order_id : null;
    this.title = survey.title ? survey.title : null;
    this.published_flag_name = survey.published_flag_name
      ? survey.published_flag_name
      : null;
    this.published_flag = _.isNumber(survey.published_flag)
      ? survey.published_flag
      : null;
    this.memo = survey.memo ? survey.memo : null;
    this.created_date = survey.created_date ? survey.created_date : null;
    this.created_user_id = _.isNumber(survey.created_user_id)
      ? survey.created_user_id
      : null;
    this.modified_date = survey.modified_date ? survey.modified_date : null;
    this.modified_user_id = _.isNumber(survey.modified_user_id)
      ? survey.modified_user_id
      : null;
    this.is_edit = false;
  }
}

class SurveyOrigin implements SurveyOriginInterface {
  id: number;
  order_id: number;
  title: string;
  published_flag_name: string;
  published_flag: number;
  memo: string;
  created_date: string;
  created_user_id: number;
  modified_date: string;
  modified_user_id: number;

  constructor(
    id?: number,
    order_id?: number,
    title?: string,
    published_flag_name?: string,
    published_flag?: number,
    memo?: string,
    created_date?: string,
    created_user_id?: number,
    modified_date?: string,
    modified_user_id?: number
  ) {
    this.id = id ? id : null;
    this.order_id = _.isNumber(order_id) ? order_id : null;
    this.title = title ? title : null;
    this.published_flag_name = published_flag_name ? published_flag_name : null;
    this.published_flag = _.isNumber(published_flag) ? published_flag : null;
    this.memo = memo ? memo : null;
    this.created_date = created_date ? created_date : null;
    this.created_user_id = _.isNumber(created_user_id) ? created_user_id : null;
    this.modified_date = modified_date ? modified_date : null;
    this.modified_user_id = _.isNumber(modified_user_id)
      ? modified_user_id
      : null;
  }
}
