import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// bootstrap
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';

// TooltipConfig
export function tooltipConfig(): TooltipConfig {
  return Object.assign(new TooltipConfig(), {
    placement: 'top',
    container: 'body',
  });
}

// blocks
import { SsLayoutModule } from '@blocks/_draft/layout/ss-layout.module';
import { SsBusyModule } from '@blocks/busy/ss-busy.module';
import { SsDialogModule } from '@blocks/dialog/ss-dialog.module';
import { SsEditButtonModule } from '@blocks/edit-button/ss-edit-button.module';
import { SsEllipsisTextModule } from '@blocks/ellipsis-text/ss-ellipsis-text.module';
import { SsFileUploadModule } from '@blocks/file-upload/ss-file-upload.module';
import { SsFormModule } from '@blocks/form/ss-form.module';
import { SsFreezeListModule } from '@blocks/freeze-list/ss-freeze-list.module';
import { SsPageBackModule } from '@blocks/page-back/ss-page-back.module';
import { SsPaginationModule } from '@blocks/pagination/ss-pagination.module';
import { SsPanelModule } from '@blocks/panel/ss-panel.module';
import { SsPermissionModule } from '@blocks/permission/ss-permission.module';
import { SsPipeModule } from '@blocks/pipe/ss-pipe.module';
import { SsScrollTopButtonModule } from '@blocks/scroll-top-button/ss-scroll-top-button.module';
import { SsSelectModule } from '@blocks/select/ss-select.module';
import { SsSelectiveModule } from '@blocks/selective/ss-selective.module';
import { SsSortModule } from '@blocks/sort/ss-sort.module';
import { SsValidateErrorModule } from '@blocks/validate-error/ss-validate-error.module';

// components
import {
  ClientsSelectComponent,
  UsersSelectComponent,
} from '@app-shared/components';
import { OfferedCountTagComponent } from '@app-shared/components/offered-count-tag/offered-count-tag.component';
import { TinymceModule } from 'angular2-tinymce';
import { ClientInfoSelectComponent } from './components/client-info-select/client-info-select.component';
import { ClientsToOrderSelectComponent } from '@app-shared/components/clients-to-order-select/clients-to-order-select.component';

/**
 * @classdesc App内の各moduleで使用したい部品系（component・directive・pipeなど）を入れるmodule
 * @desc
 * ・各moduleでimportする必要がある
 * ・このmoduleにはアプリ全体でsingletonにしたいproviderを入れてはダメ
 *   => lazyLoadしたmoduleではサービスのコピーを作成するのでアプリ全体で共有されなくなる
 * @export
 * @class AppSharedModule
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SsPaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ButtonsModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    SsSelectModule,
    SsFreezeListModule,
    SsBusyModule,
    SsDialogModule.forRoot(),
    SsPipeModule,
    SsValidateErrorModule,
    SsSelectiveModule,
    SsFormModule.forRoot(),
    SsPermissionModule,
    SsFileUploadModule,
    SsPageBackModule,
    SsEditButtonModule,
    SsPanelModule,
    SsScrollTopButtonModule,
    SsSortModule,
    SsEllipsisTextModule,
    TimepickerModule.forRoot(),
    TinymceModule.withConfig({
      language: 'ja',
      menubar: false,
      statusbar: false,
      plugins: ['textcolor', 'autoresize'],
      toolbar:
        'bold italic underline strikethrough removeformat | fontsizeselect forecolor backcolor',
    }),
    // SsLayoutModule,
  ],
  declarations: [
    UsersSelectComponent,
    ClientsSelectComponent,
    ClientsToOrderSelectComponent,
    ClientInfoSelectComponent,
    OfferedCountTagComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BsDropdownModule,
    TooltipModule,
    ButtonsModule,
    PopoverModule,
    SsPaginationModule,
    SsSelectModule,
    SsFreezeListModule,
    SsBusyModule,
    SsDialogModule,
    SsPipeModule,
    SsValidateErrorModule,
    SsSelectiveModule,
    SsFormModule,
    UsersSelectComponent,
    ClientsSelectComponent,
    ClientsToOrderSelectComponent,
    SsPermissionModule,
    SsFileUploadModule,
    SsPageBackModule,
    SsEditButtonModule,
    SsPanelModule,
    SsScrollTopButtonModule,
    SsSortModule,
    SsEllipsisTextModule,
    TimepickerModule,
    ClientInfoSelectComponent,
    TinymceModule,
    OfferedCountTagComponent,
    // SsLayoutModule
  ],
})
export class AppSharedModule {
  static forRoot(): ModuleWithProviders<AppSharedModule> {
    return {
      ngModule: AppSharedModule,
      providers: [
        // bootstrapのtooltipの初期option設定
        { provide: TooltipConfig, useFactory: tooltipConfig },
      ],
    };
  }
}
