import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeletedItem } from '@ss-core/models/deleted-item';
import { Observable } from 'rxjs';

@Injectable()
export class DeletedItemService {
  constructor(private http: HttpClient) {}

  checkOrderId(id: string): Observable<boolean> {
    return this.http
      .get(`/orders/${id}/deleted`)
      .pipe(map(this.getIsDeletedFlag));
  }

  checkClientId(id: string): Observable<boolean> {
    return this.http
      .get(`/clients/${id}/deleted`)
      .pipe(map(this.getIsDeletedFlag));
  }

  checkClientPortalOfferedOrderId(id: string): Observable<boolean> {
    return this.http
      .get(`/portal/offered_orders/${id}/deleted`)
      .pipe(map(this.getIsDeletedFlag));
  }

  checkClientPortalOrderId(id: string): Observable<boolean> {
    return this.http
      .get(`/portal/orders/${id}/deleted`)
      .pipe(map(this.getIsDeletedFlag));
  }

  private getIsDeletedFlag(response) {
    return !response.data.is_deleted;
  }
}
