import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SsBusyModule } from '@blocks/busy/ss-busy.module';
import { SsEditButtonModule } from '@blocks/edit-button/ss-edit-button.module';
import { SsPanelComponent } from './ss-panel/ss-panel.component';

@NgModule({
  imports: [CommonModule, SsEditButtonModule, SsBusyModule],
  declarations: [SsPanelComponent],
  exports: [SsPanelComponent],
})
export class SsPanelModule {}
