import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { QuestionnaireMemoInfo } from '@app-units/models/questionnaire-memo-info/questionnaire-memo-info';
import { QuestionnaireMemoInfoOriginInterface } from '@app-units/models/questionnaire-memo-info/questionnaire-memo-info-origin';
import { Observable } from 'rxjs';

@Injectable()
export class QuestionnaireMemoInfoHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(id: string): Observable<app.response<QuestionnaireMemoInfo>> {
    return this.http
      .get(this.getUrl(id))
      .pipe(map(JsonConverter.toQuestionnaireMemoInfo));
  }

  saveItem(
    item: QuestionnaireMemoInfoOriginInterface
  ): Observable<app.response<QuestionnaireMemoInfo>> {
    return this.http
      .put(this.getUrl(String(item.id)), item)
      .pipe(map(JsonConverter.toQuestionnaireMemoInfo));
  }

  private getUrl(id: string) {
    return `/orders/${id}/questionnaire_memo`;
  }
}
