import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { Attachment } from '@app-units/models/attachment/attachment';
import { Observable } from '../../../../../../node_modules/rxjs';

@Injectable()
export class ClientDocsHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(orderId: string): Observable<app.response<Attachment[]>> {
    return this.http
      .get(this.url(orderId))
      .pipe(map(JsonConverter.toAttachment));
  }

  postItem(
    orderId: string,
    postingData: any
  ): Observable<app.response<Attachment[]>> {
    return this.http
      .post(this.url(orderId), postingData)
      .pipe(map(JsonConverter.toAttachment));
  }

  deleteItem(
    orderId: string,
    attachmentId: string
  ): Observable<app.response<Attachment[]>> {
    return this.http
      .delete(this.url(orderId, attachmentId))
      .pipe(map(JsonConverter.toAttachment));
  }

  createUpLoadUrl(): string {
    return '/api/upload';
  }

  private url(orderId: string, clientDocId?: string): string {
    if (clientDocId) {
      return `/orders/${orderId}/client_docs/${clientDocId}`;
    }
    return `/orders/${orderId}/client_docs`;
  }
}
