import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { Contract } from '@app-units/models/contract/contract';
import { ContractOriginInterface } from '@app-units/models/contract/contract-origin';
import { Observable } from 'rxjs';

@Injectable()
export class ContractHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(clientId: string): Observable<app.response<Contract>> {
    return this.http
      .get(this.getUrl(clientId))
      .pipe(map(JsonConverter.toContract));
  }

  saveItem(
    clientId: string,
    postingData: ContractOriginInterface
  ): Observable<app.response<Contract>> {
    return this.http
      .put(this.getUrl(clientId), postingData)
      .pipe(map(JsonConverter.toContract));
  }

  private getUrl(id: string) {
    return `/clients/${id}/contract`;
  }
}
