import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SsPageBackComponent } from '@blocks/page-back/ss-page-back/ss-page-back.component';
import { SsPageBackService } from './services/ss-page-back.service';

@NgModule({
  imports: [CommonModule],
  declarations: [SsPageBackComponent],
  exports: [SsPageBackComponent],
  providers: [SsPageBackService],
})
export class SsPageBackModule {}
