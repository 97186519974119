<div id="login-page">
    <form class="ui form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="logo">
                    <img
                        src="../../../assets/images/app/logo.png"
                        alt="logo"
                        width="250"
                    />
                </div>
                <table>
                    <tbody>
                        <tr>
                            <th>ユーザー名</th>
                            <td>
                                <input
                                    type="text"
                                    formControlName="username"
                                    class="form-control"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>パスワード</th>
                            <td>
                                <input
                                    type="password"
                                    formControlName="password"
                                    class="form-control"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-right" *ngIf="!isPrivate">
                    <a href="/remind_passwords" target="_blank"
                        >パスワード忘れの方はこちら</a
                    >
                </div>
            </div>
            <div class="panel-footer text-center">
                <button type="submit" class="btn btn-success">ログイン</button>
            </div>
        </div>
    </form>
</div>

<!--<pre>{{user.value | json}}</pre>-->
