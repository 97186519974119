<div id="app-wrapper">
    <app-header
        *ngIf="isAuthenticated"
        [isHiddenHeaderMenu]="isHiddenHeaderMenu"
    ></app-header>

    <div _ngcontent-wuq-c0="" class="container" id="page-content-wrapper">
        <!-- routingで設定されたcomponentが表示される-->
        <router-outlet (activate)="onActivate()"></router-outlet>
    </div>
</div>
