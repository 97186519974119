<div id="error-page">
    <div class="error-content">
        <div class="title">
            <i class="flat-icon100-caution"></i><br />
            <p>NOT FOUND</p>
            <b>このページはご利用いただけません</b>
        </div>
        <p>リンクに問題があるか、ページが削除された可能性があります。</p>
    </div>

    <!--<div class="app-error-content" ng-if="$ctrl.status === '403'">
        <div class="title">
            <i class="flat-icon100-caution"></i><br/>
            <p>FORBIDDEN</p>
            <b>アクセス権がありません</b>
        </div>
        <p>アクセス権がないため表示できません。</p>
    </div>  -->
</div>
