import { InjectionToken } from '@angular/core';
import { Routes } from '@angular/router';
import { SsRoute } from '@ss-core/models';

// ルーティング定数をDIして使用する為に、InjectionTokenを定義
export let ROUTES_CONSTANT = new InjectionToken('routes.constant');

/**
 * @desc ルーティング定数 interface
 * @export
 * @interface IRoutePaths
 */
export interface IRoutePaths {
  ROOT_PATH: SsRoute;

  /** 共通path */
  COMMON: {
    // 一覧
    INDEX: SsRoute;
    // 詳細
    VIEW: SsRoute;
    // 登録
    CREATE: SsRoute;
  };

  /** オーダー管理 */
  ORDER: SsRoute;

  /** 口コミ管理 */
  REVIEWS: SsRoute;

  /** クライアント管理 */
  CLIENTS: SsRoute;

  /** ユーザー管理 */
  USERS: SsRoute;

  /** クライアント追加 */
  ORDER_CLIENT_ADD: SsRoute;

  /** クライアントポータル Home */
  CLIENT_PORTAL_HOME: SsRoute;

  /** クライアントポータル オーダー管理 */
  CLIENT_PORTAL_ORDERS: SsRoute;

  /** クライアントポータル 案件管理 */
  CLIENT_PORTAL_OFFERED_ORDERS: SsRoute;

  /** オーダーエクスポート */
  ORDER_EXPORT: SsRoute;
}

/**
 * ルーティング定数
 */
export const RouteConstant: IRoutePaths = {
  ROOT_PATH: {
    path: '',
    data: {},
  },
  /* dataの指定のないproperty（configResolveParamなど）は親のActivatedRouteから、引き継がれます。 */
  COMMON: {
    INDEX: {
      path: '',
      data: {},
    },
    VIEW: {
      path: 'view',
      data: {
        title: '',
        icon: '',
      },
    },
    CREATE: {
      path: 'create',
      data: {
        title: '新規登録',
        icon: '',
      },
    },
  },

  ORDER: {
    path: 'orders',
    data: {
      title: 'オーダー管理',
      configResolveParam: 'order',
      icon: 'note-check',
      isDoorsPage: true,
    },
  },

  REVIEWS: {
    path: 'reviews',
    data: {
      title: '口コミ管理',
      configResolveParam: 'review',
      icon: 'balloon',
      isDoorsPage: true,
    },
  },

  CLIENTS: {
    path: 'clients',
    data: {
      title: 'クライアント管理',
      configResolveParam: 'client',
      icon: 'paint-format',
      isDoorsPage: true,
    },
  },

  USERS: {
    path: 'users',
    data: {
      title: 'ユーザー管理',
      configResolveParam: 'user',
      icon: 'users',
      isDoorsPage: true,
    },
  },

  ORDER_CLIENT_ADD: {
    path: 'order_client_add',
    data: {
      title: 'クライアント追加',
      configResolveParam: 'order',
      icon: '',
      isDoorsPage: true,
    },
  },

  CLIENT_PORTAL_HOME: {
    path: 'client_portal_home',
    data: {
      title: 'トップ',
      configResolveParam: 'client_portal',
      icon: 'home',
      isDoorsPage: false,
    },
  },

  CLIENT_PORTAL_ORDERS: {
    path: 'client_portal_orders',
    data: {
      title: 'オーダー一覧',
      configResolveParam: 'client_portal',
      icon: 'note-check',
      isDoorsPage: false,
    },
  },

  CLIENT_PORTAL_OFFERED_ORDERS: {
    path: 'client_portal_offered_orders',
    data: {
      title: '案件一覧',
      configResolveParam: 'client_portal',
      icon: 'document',
      isDoorsPage: false,
    },
  },

  ORDER_EXPORT: {
    path: 'order_export',
    data: {
      title: '売上管理',
      configResolveParam: 'order_export',
      icon: 'document',
      isDoorsPage: true,
    },
  },
};
