import { take, filter, skip, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Observer } from 'rxjs';

@Injectable()
export class SsQueryParamsService {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  /**
   *
   * URLのqueryParamsをもとに検索条件を流す
   *
   * @param defaultSearch 初期検索条件
   * @param isInitialSearch 初回検索フラグ
   *
   */
  activatedParams(
    defaultSearch: app.search,
    isInitialSearch: boolean
  ): Observable<app.search> {
    // 初期条件が設定されてるかのフラグ
    const isDefaultSearch =
      _.isPlainObject(defaultSearch) && !_.isEmpty(defaultSearch);
    // 初期条件をコピーして返す
    const cloneDefaultSearch = () => Object.assign({}, defaultSearch);

    // 初回のfilter( urlのパラメータがある or 初回検索フラグが立っている => true )
    const firstFilter = (params) => !_.isEmpty(params) || isInitialSearch;
    // 初回のmap( urlのパラメータがない And 初期条件が設定されてれば => 初期条件をコピーして返す )
    const firstMapper = (params) =>
      _.isEmpty(params) && isDefaultSearch ? cloneDefaultSearch() : params;

    /**
     * 返却するObservableを作成
     */
    return Observable.create((observer: Observer<app.search>) => {
      // 初回
      this.activatedRoute.queryParams
        .pipe(
          take(1),
          filter(firstFilter),
          map(firstMapper),
          map((params) => this.paramsParse(params))
        )
        .subscribe((params) => observer.next(params));

      // 2回目以降
      const subscription = this.activatedRoute.queryParams
        .pipe(
          skip(1),
          map((params) => this.paramsParse(params))
        )
        .subscribe((params) => observer.next(params));

      // createしたObservableがunsubscribeされたら呼び出される
      return () => {
        // subscriptionの後始末
        subscription.unsubscribe();
      };
    });
  }

  /**
   * 流れてきたqueryParamsの値を変換処理
   */
  private paramsParse(params) {
    // 配列変換
    const parseToArrary = (value: string) => {
      // カンマが入ってる場合は区切る、入ってない場合はそのまま配列に入れる
      return _.isString(value) && value.indexOf(',') !== -1
        ? value.split(',')
        : [value];
    };

    // key名の先頭文字が'_'の場合は配列変換対象とする
    return _.mapValues(params, (value, key) => {
      return key.charAt(0) === '_' ? parseToArrary(value) : value;
    });
  }

  /**
   *
   * URLのqueryParamsを変更する
   *
   */
  changeRouteQueryParams(params: app.search, replace?: boolean) {
    const encodedParams = this.paramsEncodeUrl(params);

    this.router.navigate([], {
      queryParams: encodedParams,
      relativeTo: this.activatedRoute,
      replaceUrl: replace,
    });
  }

  /**
   * queryParamsをurl用に変換する
   */
  private paramsEncodeUrl(params: app.search) {
    return _.mapValues(params, (value, key) => {
      if (_.startsWith(key, '_') && _.isArray(value)) {
        // 配列の場合は、カンマ区切りの文字列に変換
        return value.join(',');
      }
      return value;
    });
  }
}
