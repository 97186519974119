import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { DeletedItemService } from '@ss-core/services/deleted-item/deleted-item.service';
import { Observable } from 'rxjs';

@Injectable()
export class DeletedItemGuard implements CanActivate {
  constructor(private deletedItemService: DeletedItemService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const configResolveParam = route.data.configResolveParam;
    if (configResolveParam === 'order') {
      return this.deletedItemService.checkOrderId(route.params.id);
    } else if (configResolveParam === 'client') {
      return this.deletedItemService.checkClientId(route.params.id);
    } else if (state.url.match('client_portal_orders')) {
      return this.deletedItemService.checkClientPortalOrderId(route.params.id);
    } else if (state.url.match('client_portal_offered_orders')) {
      return this.deletedItemService.checkClientPortalOfferedOrderId(
        route.params.id
      );
    }
    return true;
  }
}
