import { SsDialogService } from '@blocks/dialog/ss-dialog.service';
import { SsDialogComponent } from '@blocks/dialog/ss-dialog/ss-dialog.component';
import { Observable, Observer } from 'rxjs';

/**
 * dialogイベント
 */
export enum DIALOG_EVENT {
  /** dialogでの処理が完了した時 */
  DONE = 1,
  /** dialogを閉じた時（キャンセル） */
  CLOSE,
}

/**
 *
 * SsDialogResult
 * @description dialogの結果 (subscribeで流れてくるデータ)
 *
 */
export interface SsDialogResult {
  action: DIALOG_EVENT;
  data?: any;
}

/**
 *
 * SsDialogDialogComponent
 * @description dialogとして表示したいcomponentでextendして使用
 *
 */
export class SsModalDialogComponent<T> {
  constructor(protected ssDialogService: SsDialogService) {}

  /** dialogの結果を流すためのObserver */
  private dialogObserver: Observer<SsDialogResult>;
  /** dialogの呼び出し元から渡されるデータ */
  fillData: T;
  /** 親component */
  dialog: SsDialogComponent;

  /**
   * dialogを閉じるハンドラ
   */
  onClose() {
    this.dialogObserver.next({
      action: DIALOG_EVENT.CLOSE,
    });
    this.dialogObserver.complete();
  }

  /**
   * dialogの処理完了のハンドラ
   */
  onDone(result: SsDialogResult) {
    this.dialogObserver.next(result);
    this.dialogObserver.complete();
  }

  /**
   * 呼び出し元からの連携処理
   */
  fill(data: any) {
    function isObject(value) {
      const type = typeof value;
      return value != null && (type == 'object' || type == 'function');
    }
    // dialogの呼び出し元から渡されるデータをセット
    this.fillData = isObject(data) ? { ...data } : data;

    // dialogの結果を流すためのObservableを作成して返却
    return Observable.create((observer) => {
      this.dialogObserver = observer;
      return () => {
        this.ssDialogService.removeDialog(this);
      };
    });
  }
}
