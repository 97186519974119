import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { ActionMessageOriginInterface } from '@app-units/models/action-message/action-message-origin';
import { Action } from '@app-units/models/action/action';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class ClientActionsHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(id: string): Observable<app.response<Action[]>> {
    return this.http.get(this.getUrl(id)).pipe(map(JsonConverter.toAction));
  }

  saveItem(
    id: string,
    postingData: ActionMessageOriginInterface
  ): Observable<app.response<Action[]>> {
    if (postingData && postingData.id) {
      return this.http
        .put(this.getUrl(id, String(postingData.id)), postingData)
        .pipe(map(JsonConverter.toAction));
    }
    return this.http
      .post(this.getUrl(id), postingData)
      .pipe(map(JsonConverter.toAction));
  }

  deleteItem(id: string, actionId: string): Observable<app.response<Action[]>> {
    return this.http
      .delete(this.getUrl(id, actionId))
      .pipe(map(JsonConverter.toAction));
  }

  sendMail(id: string, postingData: any): Observable<app.response<Action[]>> {
    return this.http
      .post(`${this.getUrl(id)}/mail`, postingData)
      .pipe(map(JsonConverter.toAction));
  }

  private getUrl(id: string, actionId?: string) {
    if (!_.isNil(actionId)) {
      return `/clients/${id}/actions/${actionId}`;
    }
    return `/clients/${id}/actions`;
  }
}
