import { map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  Data,
} from '@angular/router';
import { RouteConstant } from '@app-core/constants';
import { ISsConstant, SS_CONSTANT } from '@ss-core/constants';
import { AuthService } from '@ss-core/services/auth/auth.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class RoleGuard implements CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkRoleOfAuthUser(route.data).pipe(
      map((isAuth) => (isAuth ? true : this.activateError()))
    );
  }

  private checkRoleOfAuthUser(data: Data): Observable<boolean> {
    return Observable.create((observer) => {
      // ログイン認証済みかを取得
      const isAuthenticated = this.authService.isAuthenticated();
      if (!isAuthenticated) {
        observer.next(false);
      }
      // ログインユーザー情報を取得
      const authUser = this.authService.authUser();
      if (_.isNil(authUser)) {
        observer.next(false);
      }
      observer.next(authUser.isDoors === data.isDoorsPage);
    });
  }

  private activateError(): boolean {
    // 閲覧権限がない場合はTOPページに遷移
    this.router.navigate([''], { replaceUrl: true });
    return false;
  }
}
