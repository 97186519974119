import { CompatibleCitiesOriginInterface } from '@app-units/models/compatible-cities/compatible-cities-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

/**
 * @classdesc 対応市区町村
 * @export
 * @class CompatibleCities
 */
export class CompatibleCities implements OriginWrapper {
  city_id: number;
  pref_cd: number;
  city_name: string;
  name_including_gun_houreishi: string;

  private readonly origin: CompatibleCitiesOriginInterface;

  get posting_data(): CompatibleCitiesOriginInterface {
    return new CompatibleCitiesOrigin(
      this.city_id,
      this.pref_cd,
      this.city_name,
      this.name_including_gun_houreishi
    );
  }

  constructor(compatibleCities?: CompatibleCitiesOriginInterface) {
    this.origin = this.createClientInfoInstance(compatibleCities);
    this.initializeCities(this.origin);
  }

  resetToOrigin() {
    this.initializeCities(this.origin);
  }

  private createClientInfoInstance(
    compatibleCities: CompatibleCitiesOriginInterface
  ): CompatibleCitiesOrigin {
    return compatibleCities
      ? new CompatibleCitiesOrigin(
          compatibleCities.city_id,
          compatibleCities.pref_cd,
          compatibleCities.city_name
        )
      : new CompatibleCitiesOrigin();
  }

  private initializeCities(compatibleCities: CompatibleCitiesOrigin) {
    this.city_id = compatibleCities.city_id;
    this.pref_cd = compatibleCities.pref_cd;
    this.city_name = compatibleCities.city_name;
    this.name_including_gun_houreishi =
      compatibleCities.name_including_gun_houreishi;
  }
}

class CompatibleCitiesOrigin implements CompatibleCitiesOriginInterface {
  city_id: number;
  pref_cd: number;
  city_name: string;
  name_including_gun_houreishi: string;

  constructor(
    city_id?: number,
    pref_cd?: number,
    city_name?: string,
    name_including_gun_houreishi?: string
  ) {
    this.city_id = _.isNumber(city_id) ? city_id : 0; // nullを0に変更
    this.pref_cd = _.isNumber(pref_cd) ? pref_cd : 0; // nullを0に変更
    this.city_name = city_name ? city_name : '';
    this.name_including_gun_houreishi = name_including_gun_houreishi
      ? name_including_gun_houreishi
      : '';
  }
}
