import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { Survey } from '@app-units/models/survey/survey';
import { SurveyOriginInterface } from '@app-units/models/survey/survey-origin';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class SurveysHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(id: string): Observable<app.response<Survey[]>> {
    return this.http.get(this.getUrl(id)).pipe(map(JsonConverter.toSurvey));
  }

  postSurvey(
    postingData: SurveyOriginInterface,
    orderId: string
  ): Observable<app.response<Survey[]>> {
    return this.http
      .post(this.getUrl(orderId), postingData)
      .pipe(map(JsonConverter.toSurvey));
  }

  putSurvey(
    postingData: SurveyOriginInterface,
    orderId: string,
    surveyId: string
  ): Observable<app.response<Survey>> {
    return this.http
      .put(this.getUrl(orderId, surveyId), postingData)
      .pipe(map(JsonConverter.toSurvey));
  }

  deleteSurvey(
    orderId: string,
    surveyId: string
  ): Observable<app.response<Survey[]>> {
    const url = this.getUrl(orderId, surveyId);
    return this.http
      .delete<app.response<Survey[]>>(url)
      .pipe(map(JsonConverter.toSurvey));
  }

  private getUrl(orderId: string, surveyId?: string) {
    if (!_.isNil(surveyId)) {
      return `/orders/${orderId}/surveys/${surveyId}`;
    }
    return `/orders/${orderId}/surveys`;
  }
}
