import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { EntryInfo } from '@app-units/models/entry-info/entry-info';
import { EntryInfoOriginInterface } from '@app-units/models/entry-info/entry-info-origin';
import { Observable } from 'rxjs';

@Injectable()
export class ClientPortalEntryInfoService {
  constructor(private http: HttpClient) {}

  fetchItem(orderId: string): Observable<app.response<EntryInfo>> {
    return this.http
      .get(this.getUrl(orderId))
      .pipe(map(JsonConverter.toEntryInfo));
  }

  saveItem(
    orderId: string,
    postingData: EntryInfoOriginInterface
  ): Observable<app.response<EntryInfo>> {
    return this.http
      .put(this.getUrl(orderId), postingData)
      .pipe(map(JsonConverter.toEntryInfo));
  }

  private getUrl(id: string) {
    return `/portal/offered_orders/${id}/entry_info`;
  }
}
