import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { OrderSendOfferMailOriginInterface } from '@app-units/models/orde-send-offer-mail/orde-send-offer-mail-origin';
import { OrderLastEditTimestamp } from '@app-units/models/order-last-edit-timestamp/order-last-edit-timestamp';
import { Order } from '@app-units/models/order/order';
import { OrderOriginInterface } from '@app-units/models/order/order-origin';
import { SsUrlSearchParamsService } from '@blocks/utils/ss-url-search-params/ss-url-search-params.service';
import { ISsConstant, SS_CONSTANT } from '@ss-core/constants/ss.constant';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class OrdersSearchParams {
  /** ページ数 */
  page?: string = null;
  /** ソート */
  order?: string = null;
  /** 表示件数 */
  limit?: string = null;
  /** ids（カンマ区切り）*/
  ids?: string = null;
  /** id（開始）*/
  id_start?: string = null;
  /** id（終了）*/
  id_end?: string = null;
  /** オーダー転送日（開始）*/
  transfer_start?: string = null;
  /** 1社目のオーダー転送日（終了）*/
  transfer_end?: string = null;
  /** 1社目のオーダー転送日（開始）*/
  first_order_transfer_start?: string = null;
  /** オーダー転送日（終了）*/
  first_order_transfer_end?: string = null;
  /** 申込元 */
  _source_sites?: string[] = null;
  /** 流入元 */
  _inflow_sources?: string[] = null;
  /** 申込日（開始） */
  request_datetime_start?: string = null;
  /** 申込日（終了） */
  request_datetime_end?: string = null;
  /** エンド名 */
  end_name?: string = null;
  /** 業者紹介日（開始）*/
  introduce_date_start?: string = null;
  /** 業者紹介日（終了）*/
  introduce_date_end?: string = null;
  /** セカンド架電依頼日（開始）*/
  second_call_date_start?: string = null;
  /** セカンド架電依頼日（終了）*/
  second_call_date_end?: string = null;
  /** エンド電話番号 */
  end_tel?: string = null;
  /** 携帯・PHS番号のみ */
  sms_only?: boolean = null;
  /** ラベル */
  label?: string = null;
  /** エンドE-Mail */
  end_email?: string = null;
  /** 都道府県 */
  prefecture?: string = null;
  /** 引上げランク */
  pick_up_rank_type_id?: string = null;
  /** 補助金、助成金の希望 */
  order_require_subsidy_type_id?: string = null;
  /** ローン/火災保険の利用希望 */
  order_payment_method_type_id?: string = null;
  /**　市区町村　*/
  city?: string = null;
  /** 次回連絡予定日（開始）　*/
  next_call_date_start?: string = null;
  /** 次回連絡予定日（終了）　*/
  next_call_date_end?: string = null;
  /** 再架電 */
  _call_again?: string[] = null;
  /** 担当者 */
  user?: string = null;
  /** 架電担当 */
  calling?: boolean = null;
  /** 販売担当 */
  delivery?: boolean = null;
  /** ガイドブック送付担当 */
  sent_guidebook?: boolean = null;
  /**　クライアントID */
  client_id?: string = null;
  /**　追加紹介日（開始）*/
  add_introduce_start?: string = null;
  /**　追加紹介日（終了）*/
  add_introduce_end?: string = null;
  /** 契約確認受取日（開始） */
  confirm_contract_start?: string = null;
  /** 契約確認受取日（終了） */
  confirm_contract_end?: string = null;
  /** アンケート */
  _published_flags?: string[] = [];
  /** 販売完了日（開始） */
  delivery_complete_start?: string = null;
  /** 販売完了日（終了） */
  delivery_complete_end?: string = null;
  /** 課金調査状況 */
  _research_statuses?: string[] = null;
  /** 課金調査 */
  research?: boolean = null;
  /** オーダー転送 */
  order_transfer?: boolean = null;
  /** ステータス（親） */
  _order_status_searches?: string[] = null;
  /**　ステータス(子)　*/
  _order_statuses?: string[] = null;
  /** 後追いステータス */
  _order_trailing_statuses?: string[] = null;
  /* 転送済クライアントID */
  transferred_client_id?: string = null;
  /* 未転送クライアントID */
  not_transferred_client_id?: string = null;
  /** ガイドブック送付日（開始） */
  guidebook_sent_date_start?: string = null;
  /** ガイドブック送付日（終了） */
  guidebook_sent_date_end?: string = null;
  /** 請求書発行日（開始） */
  invoiced_date_start?: string = null;
  /** 請求書発行日（終了） */
  invoiced_date_end?: string = null;
}

@Injectable()
export class OrdersHttpService {
  private get resource(): string {
    return '/orders';
  }

  constructor(
    private http: HttpClient,
    private ssUrlSearchParams: SsUrlSearchParamsService,
    @Inject(SS_CONSTANT) private SS_CONSTANT: ISsConstant
  ) {}

  fetchItems(search: OrdersSearchParams): Observable<app.response<Order[]>> {
    const params = this.ssUrlSearchParams.parseParams(search);
    return this.http
      .get(this.resource, { params: params })
      .pipe(map(JsonConverter.toOrder));
  }

  saveItem(order: OrderOriginInterface): Observable<app.response<Order>> {
    return this.http
      .post(this.resource, order)
      .pipe(map(JsonConverter.toOrder));
  }

  deleteItem(orderId: string): Observable<app.response<any>> {
    const url = `${this.resource}/${orderId}`;
    return this.http.delete<app.response<any>>(url);
  }

  fetchItemEditTimestamp(
    orderId: string
  ): Observable<app.response<OrderLastEditTimestamp>> {
    const url = `${this.resource}/${orderId}/edit_timestamp`;
    return this.http.get(url).pipe(map(JsonConverter.toOrderLastEditTimestamp));
  }

  updateOrderStatus(
    orderStatus: number,
    orderIds: number[]
  ): Observable<app.response<any>> {
    const url = `${this.resource}/order_status`;
    return this.http.put<app.response<any>>(url, {
      orderStatus: orderStatus,
      orderIds: orderIds,
    });
  }

  updateOrderAssignee(
    userId: number,
    orderIds: number[],
    role: number
  ): Observable<app.response<any>> {
    const url = `${this.resource}/assignee`;
    return this.http.put<app.response<any>>(url, {
      userId: userId,
      orderIds: orderIds,
      role: role,
    });
  }

  /* 編集権限の取得 */
  confirmAuth(
    orderId: string
  ): Observable<app.response<{ editable: boolean }>> {
    const url = `${this.resource}/${orderId}/auth`;
    return this.http.get<app.response<any>>(url);
  }

  exportCsvURL(paramsString: string): string {
    return (
      this.SS_CONSTANT.API_BASE_URL + this.resource + '/csv?' + paramsString
    );
  }

  exportClientOrderSummaryURL(params: {
    startDate: string;
    endDate: string;
    version: string;
  }): string {
    const queryParams = new HttpParams()
      .set('startDate', params.startDate)
      .set('endDate', params.endDate)
      .set('version', params.version);
    return `${
      this.SS_CONSTANT.API_BASE_URL
    }/export-client-order-summary?${queryParams.toString()}`;
  }

  isekiURL(paramsString: string): string {
    const url = window.location.origin + this.resource + '?' + paramsString;
    return `${this.SS_CONSTANT.ISEKI_LINK_URL}?url=${url}`;
  }

  fetchItemPrevNext(
    orderId: string,
    search: OrdersSearchParams
  ): Observable<app.response<{ next_id: number; prev_id: number }>> {
    const url = `${this.resource}/prev_next/${orderId}`;
    const params = this.ssUrlSearchParams.parseParams(search);
    return this.http.get<app.response<any>>(url, { params: params });
  }

  /* 宛先メール配信 */
  sendOfferMails(
    orderId: string,
    data: OrderSendOfferMailOriginInterface
  ): Observable<app.response<any>> {
    const url = `${this.resource}/${orderId}/mails/send_offer`;
    return this.http.post<app.response<any>>(url, data);
  }

  /* お断りメールの送信 */
  sendRejectMail(orderId: string): Observable<app.response<any>> {
    const url = `${this.resource}/${orderId}/mails/send_reject`;
    return this.http.post<app.response<any>>(url, {});
  }

  /* エンド情報の入力チェック */
  checkRequired(orderId: string): Observable<app.response<any>> {
    const url = `/orders/${orderId}/order_transfer_required`;
    return this.http.get<app.response<any>>(url, {});
  }
}
