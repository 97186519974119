import { OrderLastEditTimestampOriginInterface } from '@app-units/models/order-last-edit-timestamp/order-last-edit-timestamp-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class OrderLastEditTimestamp implements OriginWrapper {
  user_id: string;
  modified_date: string;
  first_name: string;
  last_name: string;

  private readonly origin: OrderLastEditTimestampOriginInterface;

  get posting_data(): OrderLastEditTimestampOriginInterface {
    return new OrderLastEditTimestampOrigin(
      Number(this.user_id),
      this.modified_date,
      this.first_name,
      this.last_name
    );
  }

  get fullName() {
    const last_name = this.last_name ? this.last_name : '';
    const first_name = this.first_name ? this.first_name : '';
    let result = last_name;
    if (first_name.length > 0 && last_name.length > 0) {
      result = result + ' ';
    }
    result = result + first_name;
    return result;
  }

  constructor(orderLastEditTimestamp?: OrderLastEditTimestampOriginInterface) {
    this.origin = this.createOrderLastEditTimestampInstance(
      orderLastEditTimestamp
    );
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createOrderLastEditTimestampInstance(
    orderLastEditTimestamp: OrderLastEditTimestampOrigin
  ): OrderLastEditTimestampOrigin {
    return orderLastEditTimestamp
      ? new OrderLastEditTimestampOrigin(
          orderLastEditTimestamp.user_id,
          orderLastEditTimestamp.modified_date,
          orderLastEditTimestamp.first_name,
          orderLastEditTimestamp.last_name
        )
      : new OrderLastEditTimestampOrigin();
  }

  private initializeProperties(
    orderLastEditTimestamp: OrderLastEditTimestampOriginInterface
  ) {
    this.user_id = _.isNumber(orderLastEditTimestamp.user_id)
      ? String(orderLastEditTimestamp.user_id)
      : null;
    this.modified_date = orderLastEditTimestamp.modified_date;
    this.first_name = orderLastEditTimestamp.first_name;
    this.last_name = orderLastEditTimestamp.last_name;
  }
}

class OrderLastEditTimestampOrigin
  implements OrderLastEditTimestampOriginInterface
{
  user_id: number;
  modified_date: string;
  first_name: string;
  last_name: string;

  constructor(
    user_id?: number,
    modified_date?: string,
    first_name?: string,
    last_name?: string
  ) {
    this.user_id = _.isNumber(user_id) ? user_id : null;
    this.modified_date = modified_date ? modified_date : null;
    this.first_name = first_name ? first_name : null;
    this.last_name = last_name ? last_name : null;
  }
}
