import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { ClientPortalProgress } from '@app-units/models/client-portal-progress/client-portal-progress';
import { OrderClientRelationOriginInterface } from '@app-units/models/order-client-relation/order-client-relation-origin';
import { Observable } from 'rxjs';

@Injectable()
export class ClientPortalProgressHttpService {
  constructor(private http: HttpClient) {}

  fetchItem(id: string): Observable<app.response<ClientPortalProgress>> {
    return this.http
      .get(this.getUrl(id))
      .pipe(map(JsonConverter.toClientPortalProgress));
  }

  saveItem(
    orderId: string,
    postingData: OrderClientRelationOriginInterface
  ): Observable<app.response<ClientPortalProgress>> {
    return this.http
      .put(this.getUrl(orderId), postingData)
      .pipe(map(JsonConverter.toClientPortalProgress));
  }

  private getUrl(id: string) {
    return `/portal/orders/${id}/estimate_progress`;
  }
}
