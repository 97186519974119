import { MediaOriginInterface } from '@app-units/models/media/media-origin';
import { OriginWrapper } from '@ss-core/models';

export class Media implements OriginWrapper {
  uuid: string;
  name: string;
  mime_type: string;
  src: string;

  private readonly origin: MediaOriginInterface;

  get posting_data(): MediaOriginInterface {
    return new MediaOrigin(this.uuid, this.name, this.mime_type, this.src);
  }

  constructor(media?: MediaOriginInterface) {
    this.origin = this.createMediaInstance(media);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createMediaInstance(media: MediaOriginInterface): MediaOrigin {
    return media
      ? new MediaOrigin(media.uuid, media.name, media.mime_type, media.src)
      : new MediaOrigin();
  }

  private initializeProperties(media: MediaOrigin) {
    this.uuid = media.uuid;
    this.name = media.name;
    this.mime_type = media.mime_type;
    this.src = media.src;
  }
}

class MediaOrigin implements MediaOriginInterface {
  uuid: string;
  name: string;
  mime_type: string;
  src: string;

  constructor(uuid?: string, name?: string, mine_type?: string, src?: string) {
    this.uuid = uuid ? uuid : null;
    this.name = name ? name : null;
    this.mime_type = mine_type ? mine_type : null;
    this.src = src ? src : null;
  }
}
