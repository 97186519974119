import { ActionMailTemplateOriginInterface } from '@app-units/models/action-mail-template/action-mail-template-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';

export class ActionMailTemplate extends SsSelective {
  body: string;
  title: string;

  private readonly origin: ActionMailTemplateOriginInterface;

  get posting_data(): ActionMailTemplateOriginInterface {
    return new ActionMailTemplateOrigin(this.body, this.title);
  }

  constructor(actionMail?: ActionMailTemplateOriginInterface) {
    super();
    this.origin = this.createActionMailInstance(actionMail);
    this.initializeProperties(this.origin);
  }

  private createActionMailInstance(
    actionMail: ActionMailTemplateOriginInterface
  ): ActionMailTemplateOrigin {
    return actionMail
      ? new ActionMailTemplateOrigin(actionMail.body, actionMail.title)
      : new ActionMailTemplateOrigin();
  }

  private initializeProperties(note: ActionMailTemplateOrigin) {
    this.body = note.body ? note.body : null;
    this.title = note.title ? note.title : null;
  }
}

class ActionMailTemplateOrigin implements ActionMailTemplateOriginInterface {
  body: string;
  title: string;

  constructor(body?: string, title?: string) {
    this.body = body ? body : null;
    this.title = title ? title : null;
  }
}
