import * as _ from 'lodash';

interface OptionInterface {
  relations?: number[];
  group?: number[];
  className?: string;
  previousElm?: string;
}

/**
 * @classdesc 区分値系Model
 * @export
 * @class SsType
 */
export class SsType {
  id: number = null;
  name: string = null;
  enable: boolean = null;
  options: OptionInterface = null;
  constructor(
    id?: number,
    name?: string,
    enable?: boolean,
    options?: OptionInterface
  ) {
    this.id = _.isNumber(id) ? id : null;
    this.name = name ? name : null;
    this.enable = enable === false ? false : true;
    if (options) {
      options = _.omitBy(options, _.isEmpty);
      this.options = !_.isEmpty(options) ? options : null;
    }
  }
}
