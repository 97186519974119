import { ClientPortalProgressOriginInterface } from '@app-units/models/client-portal-progress/client-portal-progress-origin';
import { OrderClientRelation } from '@app-units/models/order-client-relation/order-client-relation';
import { OrderClientRelationOriginInterface } from '@app-units/models/order-client-relation/order-client-relation-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class ClientPortalProgress implements OriginWrapper {
  order_client_relation: OrderClientRelation;
  charge_flag: number;

  private readonly origin: ClientPortalProgressOriginInterface;

  get posting_data(): ClientPortalProgressOriginInterface {
    return new ClientPortalProgressOrigin(
      this.order_client_relation.posting_data,
      this.charge_flag
    );
  }

  constructor(clientPortalProgress?: ClientPortalProgressOriginInterface) {
    this.origin = this.createClientPortalProgressInstance(clientPortalProgress);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createClientPortalProgressInstance(
    clientPortalProgress: ClientPortalProgressOrigin
  ): ClientPortalProgressOrigin {
    return clientPortalProgress
      ? new ClientPortalProgressOrigin(
          clientPortalProgress.order_client_relation,
          clientPortalProgress.charge_flag
        )
      : new ClientPortalProgressOrigin();
  }

  private initializeProperties(
    clientPortalProgress: ClientPortalProgressOrigin
  ) {
    this.order_client_relation = new OrderClientRelation(
      clientPortalProgress.order_client_relation
    );
    this.charge_flag = clientPortalProgress.charge_flag;
  }
}

class ClientPortalProgressOrigin
  implements ClientPortalProgressOriginInterface
{
  order_client_relation: OrderClientRelationOriginInterface;
  charge_flag: number;

  constructor(
    order_client_relation?: OrderClientRelationOriginInterface,
    charge_flag?: number
  ) {
    this.order_client_relation = order_client_relation
      ? _.cloneDeep(order_client_relation)
      : null;
    this.charge_flag = charge_flag ? charge_flag : null;
  }
}
