import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { Content } from '@app-units/models/content/content';
import { ContentOriginInterface } from '@app-units/models/content/content-origin';
import { Observable } from 'rxjs';

@Injectable()
export class ContentHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(clientId: string): Observable<app.response<Content>> {
    return this.http
      .get(this.getUrl(clientId))
      .pipe(map(JsonConverter.toContent));
  }

  saveItem(
    clientId: string,
    postingData: ContentOriginInterface
  ): Observable<app.response<Content>> {
    return this.http
      .put(this.getUrl(clientId), postingData)
      .pipe(map(JsonConverter.toContent));
  }

  saveImage(
    clientId: string,
    postingData: any
  ): Observable<app.response<Content>> {
    return this.http
      .put(this.getUrl(clientId, true), postingData)
      .pipe(map(JsonConverter.toContent));
  }

  deleteImage(clientId: string) {
    return this.http
      .delete(this.getUrl(clientId, true))
      .pipe(map(JsonConverter.toContent));
  }

  createUpLoadUrl(): string {
    return '/api/upload';
  }

  private getUrl(id: string, imageApiUrl?: boolean) {
    if (imageApiUrl) {
      return `/clients/${id}/assignee_upload_file`;
    }
    return `/clients/${id}/content`;
  }
}
