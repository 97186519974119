import { map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { Client } from '@app-units/models/client/client';
import { SsUrlSearchParamsService } from '@blocks/utils/ss-url-search-params/ss-url-search-params.service';
import { ISsConstant, SS_CONSTANT } from '@ss-core/constants/ss.constant';
import { Observable } from 'rxjs';

export class ClientsSearchParams {
  /** ページ数 */
  page?: string = null;
  /** ソート */
  order?: string = null;
  /** 表示件数 */
  limit?: string = null;
  /** 業者紹介日（開始）*/
  term_start?: string = null;
  /** 業者紹介日（終了）*/
  term_end?: string = null;
  /** オーダー転送日（開始）*/
  transfer_start?: string = null;
  /** オーダー転送日（終了）*/
  transfer_end?: string = null;
  /** id（開始）*/
  id_start?: string = null;
  /** id（終了）*/
  id_end?: string = null;
  /** コード */
  partner_cd?: string = null;
  /** 紹介数（開始） */
  intro_start?: string = null;
  /** 紹介数（終了） */
  intro_end?: string = null;
  /** クライアント名 */
  client_name?: string = null;
  /** 住所 */
  address?: string = null;
  /** 契約数（開始） */
  contract_start?: string = null;
  /** 契約数（終了） */
  contract_end?: string = null;
  /** 電話番号 */
  tel?: string = null;
  /** E-Mail */
  email?: string = null;
  /** キャンセル数（開始） */
  cancel_start?: string = null;
  /** キャンセル数（終了） */
  cancel_end?: string = null;
  /** 自動判定 */
  _auto_rank?: string[] = null;
  /** 単価（開始） */
  order_price_start?: string = null;
  /** 単価（終了） */
  order_price_end?: string = null;
  /** 契約率（開始） */
  rate_start?: string = null;
  /** 契約率（終了） */
  rate_end?: string = null;
  /** 販売上限（開始） */
  max_order_transfer_start?: string = null;
  /** 販売上限（終了） */
  max_order_transfer_end?: string = null;
  /** 手数料率（開始） */
  commission_rate_start?: string = null;
  /** 手数料率（終了） */
  commission_rate_end?: string = null;
  /** 販売ステータス */
  _delivery_statuses?: string[] = null;
  /** 要注意先 */
  _attention_types?: string[] = null;
  /** 契約プラン */
  _contract_types?: string[] = null;
  /** クライアント */
  memo_about_partner?: string = null;
}

export class ClientsForOrderAddSearchParams {
  /** ページ数 */
  page?: string = null;
  /** ページ数 */
  limit?: number = null;
  /** ソート */
  order?: string = null;
  /** クライアント名 */
  partner_name?: string = null;
  /** エンド住所 */
  end_address?: string = null;
  /** 半径 */
  radius?: string = null;
  /** 契約プラン */
  _contract_types?: string[] = null;
  /** 単価（開始） */
  order_price_start?: string = null;
  /** 単価（終了）*/
  order_price_end?: string = null;
  /** 判定 */
  _auto_ranks?: string[] = null;
  /** 要注意先 */
  _attention_types?: string[] = null;
  /** 最終案件販売日（開始） */
  offered_date_start?: string = null;
  /** 最終案件販売日（終了） */
  offered_date_end?: string = null;
  /** オーダーID */
  order_id?: string;
  /** レスポンスのモード */
  only_patner_name: number = null;
}

@Injectable()
export class ClientsHttpService {
  private get resource(): string {
    return '/clients';
  }

  constructor(
    private http: HttpClient,
    private ssUrlSearchParams: SsUrlSearchParamsService,
    @Inject(SS_CONSTANT) private SS_CONSTANT: ISsConstant
  ) {}

  fetchItems(search: ClientsSearchParams): Observable<app.response<Client[]>> {
    const params = this.ssUrlSearchParams.parseParams(search);
    return this.http
      .get(this.resource, { params: params })
      .pipe(map(JsonConverter.toClient));
  }

  fetchItem(id: string): Observable<app.response<Client>> {
    return this.http
      .get(`${this.resource}/${id}`)
      .pipe(map(JsonConverter.toClient));
  }

  fetchItemsForOrderAdd(
    orderId: string,
    search: ClientsForOrderAddSearchParams
  ): Observable<app.response<Client[]>> {
    const params = this.ssUrlSearchParams.parseParams(search);
    return this.http
      .get(`/orders/${orderId}${this.resource}`, { params: params })
      .pipe(map(JsonConverter.toClient));
  }

  saveMemoForOrderAdd(
    orderId: string,
    clientId: string,
    memo: string,
    clientMemoId?: string
  ): Observable<app.response<any>> {
    const postData = { client_id: clientId, memo: memo };
    if (!clientMemoId) {
      const url = `/orders/${orderId}/client_memos`;
      return this.http.post<app.response<any>>(url, postData);
    } else {
      const url = `/orders/${orderId}/client_memos/${clientMemoId}`;
      return this.http.put<app.response<any>>(url, postData);
    }
  }

  createClient(postingData: any): Observable<app.response<any>> {
    return this.http.post<app.response<any>>(this.resource, postingData);
  }

  deleteClient(id: string): Observable<app.response<any>> {
    return this.http.delete<app.response<any>>(`${this.resource}/${id}`);
  }

  exportCsvURL(paramsString: string): string {
    return (
      this.SS_CONSTANT.API_BASE_URL + this.resource + '/csv?' + paramsString
    );
  }

  isekiURL(paramsString: string): string {
    const url = window.location.origin + this.resource + '?' + paramsString;
    return `${this.SS_CONSTANT.ISEKI_LINK_URL}?url=${url}`;
  }

  // TODO: 使ってないかも？調べる
  fetchItemPrevNext(
    clientId: string,
    search: ClientsSearchParams
  ): Observable<app.response<{ next_id: string; prev_id: string }>> {
    const params = this.ssUrlSearchParams.parseParams(search);
    const url = this.resource + `/${clientId}/prev_next`;
    return this.http.get<app.response<{ next_id: string; prev_id: string }>>(
      url,
      { params: params }
    );
  }
}
