import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxUploaderModule } from 'ngx-uploader';

/** SsComponents */
import { SsBusyModule } from '@blocks/busy/ss-busy.module';
import { SsFileUploadThumComponent } from '@blocks/file-upload/ss-file-upload-thum/ss-file-upload-thum.component';
import { SsFileUploadComponent } from '@blocks/file-upload/ss-file-upload/ss-file-upload.component';
import { SsPipeModule } from '@blocks/pipe/ss-pipe.module';
import { SsValidateErrorModule } from '@blocks/validate-error/ss-validate-error.module';

@NgModule({
  imports: [
    CommonModule,
    NgxUploaderModule,
    TooltipModule.forRoot(),
    SsValidateErrorModule,
    SsBusyModule,
    SsPipeModule,
  ],
  declarations: [SsFileUploadComponent, SsFileUploadThumComponent],
  exports: [SsFileUploadComponent, SsFileUploadThumComponent],
})
export class SsFileUploadModule {}
