import { map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { User } from '@app-units/models/user/user';
import { SsUrlSearchParamsService } from '@blocks/utils/ss-url-search-params/ss-url-search-params.service';
import { ISsConstant, SS_CONSTANT } from '@ss-core/constants/ss.constant';
import { AuthService } from '@ss-core/services';
import { Observable } from 'rxjs';

export class UsersSearchParams {
  /** ページ数 */
  page?: string = null;
  /** ソート */
  order?: string = null;
  /** 対象期間（開始） */
  term_start?: string = null;
  /** 対象期間（終了） */
  term_end?: string = null;
  /** ユーザーID（開始） */
  id_start?: string = null;
  /** ユーザーID（終了） */
  id_end?: string = null;
  /** 担当者 */
  user?: string = null;
  /** 権限 */
  _roles?: string[] = null;

  /* 名前絞り込み */
  user_name?: string = null;
}

@Injectable()
export class UsersHttpService {
  private get resource(): string {
    return '/users';
  }

  constructor(
    private http: HttpClient,
    private ssUrlSearchParams: SsUrlSearchParamsService,
    private authService: AuthService,
    @Inject(SS_CONSTANT) private SS_CONSTANT: ISsConstant
  ) {}

  fetchItems(search: UsersSearchParams): Observable<app.response<User[]>> {
    const params = this.ssUrlSearchParams.parseParams(search);
    return this.http
      .get(this.resource, { params: params })
      .pipe(map(JsonConverter.toUser));
  }

  /** 架電数CSVを出力 */
  exportCsvURL(paramsString: string): string {
    return (
      this.SS_CONSTANT.API_BASE_URL +
      this.resource +
      '/call_count_csv?' +
      paramsString
    );
  }

  /** 転送数CSVを出力 */
  exportTransferCsv(paramsString: string): string {
    return (
      this.SS_CONSTANT.API_BASE_URL +
      this.resource +
      '/transfer_count_csv?' +
      paramsString
    );
  }

  /* ユーザ新規登録 */
  createUser(postingData: any): Observable<app.response<any>> {
    return this.http.post<app.response<any>>(this.resource, postingData);
  }

  /* ユーザ編集 */
  updateUser(postingData: any): Observable<app.response<any>> {
    const user_id = postingData.user_id;
    return this.http.put<app.response<any>>(
      `${this.resource}/${user_id}`,
      postingData
    );
  }

  /* ユーザ取得（1件） */
  fetchItem(user_id: string): Observable<app.response<User>> {
    return this.http
      .get(`${this.resource}/${user_id}`)
      .pipe(map(JsonConverter.toUser));
  }
}
