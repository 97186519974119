import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { AgainDelivery } from '@app-units/models/again-delivery/again-delivery';
import { AgainDeliveryOriginInterface } from '@app-units/models/again-delivery/again-delivery-origin';
import { Observable } from 'rxjs';

@Injectable()
export class AgainDeliveryHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(id: string): Observable<app.response<AgainDelivery>> {
    return this.http
      .get(this.getUrl(id))
      .pipe(map(JsonConverter.toAgainDelivery));
  }

  saveItem(
    item: AgainDeliveryOriginInterface
  ): Observable<app.response<AgainDelivery>> {
    return this.http
      .put(this.getUrl(String(item.id)), item)
      .pipe(map(JsonConverter.toAgainDelivery));
  }

  private getUrl(id: string) {
    return `/orders/${id}/again_delivery`;
  }
}
