import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, Observer } from 'rxjs';

import { ConfigHttpService } from '@app-core/http-services/config/config-http.service';
import { SsConfig, SsRoute } from '@ss-core/models';

export const CONFIG_RESOLVE = 'config';

/**
 * @classdesc ページのconfig取得 Resolve
 * @export
 * @class ConfigResolve
 * @implements {Resolve<any>}
 */
@Injectable()
export class ConfigResolve implements Resolve<any> {
  constructor(private configService: ConfigHttpService) {}

  /** config取得 */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SsConfig> {
    const routeConfig = route.routeConfig as SsRoute;
    const param = routeConfig.data.configResolveParam;
    return this.configService.fetchConfig(param);
  }
}
