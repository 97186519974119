import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  OnDestroy,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SsModalDialogComponent } from '@blocks/dialog/ss-modal-dialog/ss-modal-dialog.component';

/**
 *
 * SsDialogComponent
 * @description dialogとして表示したいcomponentの親
 *
 */
@Component({
  selector: 'ss-dialog',
  template: `
    <div
      #container
      class="modal fade"
      style="display:block !important;"
      role="dialog"
    >
      <ng-container #element></ng-container>
    </div>
  `,
  styleUrls: ['./ss-dialog.component.scss'],
})
export class SsDialogComponent implements OnDestroy {
  /** dialogの追加先Ref */
  @ViewChild('element', { static: true, read: ViewContainerRef })
  private element: ViewContainerRef;
  /** dialogのcontainer（外部からHTMLのclassを操作する用） */
  @ViewChild('container', { static: true }) public container: ElementRef;

  /** 保持するdialogComponent */
  private dialog: SsModalDialogComponent<any>;

  constructor(
    private injector: Injector,
    private readonly resolver: ComponentFactoryResolver
  ) {}

  /**
   * 引数で渡されたDialogComponentを生成してelementに追加
   */
  addComponent<T>(DialogComponent: Type<SsModalDialogComponent<T>>) {
    // DialogComponentを生成するfactoryを用意
    const factory = this.resolver.resolveComponentFactory(DialogComponent);

    // injectorを生成
    const injector = Injector.create({
      providers: [],
      parent: this.element.injector,
    });

    // Angular内で使用できるように生成
    const dialogComponentRef = factory.create(injector);

    // 生成したdialogComponentをelementに追加
    this.element.insert(dialogComponentRef.hostView);

    // 生成したdialogComponentを保持しておく
    this.dialog = dialogComponentRef.instance as SsModalDialogComponent<T>;

    // 自身をdialogComponentに設定しておく
    this.dialog.dialog = this;

    // 生成したdialogComponentを返却
    return this.dialog;
  }

  ngOnDestroy() {
    // console.log('DESTROY SsDialog!!!!!')
  }
}
