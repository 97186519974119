import { Media } from '@app-units/models/media/media';
import { MediaOriginInterface } from '@app-units/models/media/media-origin';

/**
 * ReviewモデルとなるClass
 * - APIの戻り値をプロパティにセットする
 * - フィールドは必ず存在するものとする（Optionalにはならない）
 * - 既定メンバでないプロパティは生成しない
 * - Non-null
 */
export class ReviewQuestionnaireInfo {
  /** @todo SsSelectiveではid:stringだった。stringである意味があるか？ */
  id: number = 0;
  reviewer_name: string = '';
  /** 年代 */
  reviewer_era_id: number = 0;
  reviewer_era_name: string = '';
  /** 性別 */
  reviewer_gender_id: number = 0;
  reviewer_gender_name: string = '';
  /** 施工回数 */
  construction_count: number = 0;
  construction_count_name: string = '';
  /** 施工開始年月 */
  construction_start_date: string = '';
  /** 施工期間 */
  construction_period: string = '';
  /** 施工価格 */
  construction_price: string = '';
  /** 内容 */
  construction_type_names: string[] = [];
  /** 総合評価 */
  general_evalution_id: number = 0;
  general_evalution_comment: string = '';
  /** 担当者の印象 */
  staff_evalution_id: number = 0;
  staff_evalution_comment: string = '';
  /** ビジョンや考え方 */
  vision_evalution_id: number = 0;
  vision_evalution_comment: string = '';
  /** 提案力（提案内容等） */
  suggestion_evalution_id: number = 0;
  suggestion_evalution_comment: string = '';
  /** 仕上がり・満足度 */
  satisfaction_evalution_id: number = 0;
  satisfaction_evalution_comment: string = '';
  /** 価格・費用の納得感 */
  price_evalution_id: number = 0;
  price_evalution_comment: string = '';
  /** 保証やアフターサービス */
  after_service_evalution_id: number = 0;
  after_service_evalution_comment: string = '';
  /** 施工前の画像 */
  before_image_media: Media = new Media();
  /** 施工後の画像 */
  after_image_media: Media = new Media();
  /** ラベル */
  label: string = '';

  /**
   * @example fb.groupFromModel(new ReviewQuestionnaireInfo());
   */
  constructor(data?) {
    if (data) {
      this.id = data.id;
      this.reviewer_name = data.reviewer_name;
      this.reviewer_era_id = data.reviewer_era_id;
      this.reviewer_era_name = data.reviewer_era_name;
      this.reviewer_gender_id = data.reviewer_gender_id;
      this.reviewer_gender_name = data.reviewer_gender_name;
      this.construction_count = data.construction_count;
      this.construction_count_name = data.construction_count_name;
      this.construction_start_date = data.construction_start_date;
      this.construction_period = data.construction_period;
      this.construction_price = data.construction_price;
      this.construction_type_names = data.construction_type_names;
      this.general_evalution_id = data.general_evalution_id;
      this.general_evalution_comment = data.general_evalution_comment;
      this.staff_evalution_id = data.staff_evalution_id;
      this.staff_evalution_comment = data.staff_evalution_comment;
      this.vision_evalution_id = data.vision_evalution_id;
      this.vision_evalution_comment = data.vision_evalution_comment;
      this.suggestion_evalution_id = data.suggestion_evalution_id;
      this.suggestion_evalution_comment = data.suggestion_evalution_comment;
      this.satisfaction_evalution_id = data.satisfaction_evalution_id;
      this.satisfaction_evalution_comment = data.satisfaction_evalution_comment;
      this.price_evalution_id = data.price_evalution_id;
      this.price_evalution_comment = data.price_evalution_comment;
      this.after_service_evalution_id = data.after_service_evalution_id;
      this.after_service_evalution_comment =
        data.after_service_evalution_comment;
      this.before_image_media = new Media(data.before_image_media);
      this.after_image_media = new Media(data.after_image_media);
      this.label = data.label;
    }
  }

  /**
   * nullを初期値に変換して、コンストラクタに渡す
   * @example const data = ReviewQuestionnaireInfo.initData(json.data)
   */
  static initData(data: ReviewQuestionnaireInfo) {
    let procData = {} as any;
    Object.entries(data).forEach(([key, val]) => {
      switch (key) {
        case 'before_image_media':
        case 'after_image_media':
          procData[`${key}`] = val ?? new Media();
          return;
        default:
          procData[`${key}`] = val ?? '';
          return;
      }
    });

    return new ReviewQuestionnaireInfo(procData as ReviewQuestionnaireInfo);
  }
}
