import { Component } from '@angular/core';
import { SsDialogService } from '@blocks/dialog/ss-dialog.service';
import {
  DIALOG_EVENT,
  SsModalDialogComponent,
} from '@blocks/dialog/ss-modal-dialog/ss-modal-dialog.component';

/**
 * SsConfirmDialogComponentへ渡すデータ
 */
export interface ConfirmDialogFillData {
  confirm: string;
  confirmTitle: string;
  confirmText: string;
  cancelText: string;
  confirmType: string;
}

/**
 *
 * SsConfirmDialogComponent
 * @description 確認dialog
 *
 */
@Component({
  selector: 'ss-confirm-dialog',
  template: `
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header modal-header-gray">
          <h4 class="modal-title">
            <i *ngIf="fillData.confirmType == 'confirm'"></i>
            <i
              *ngIf="fillData.confirmType == 'warning'"
              class="flat-icon-caution"
            ></i>
            <i
              *ngIf="fillData.confirmType == 'information'"
              class="flat-icon-info"
            ></i>
            <span [innerHTML]="fillData.confirmTitle"></span>
          </h4>
        </div>

        <div class="modal-body">
          <div [innerHTML]="fillData.confirm" class="text"></div>
        </div>

        <div class="modal-footer" [ngSwitch]="fillData.confirmType">
          <ng-container *ngSwitchCase="'confirm'">
            <button
              type="button"
              class="btn btn-default"
              (click)="onSubmit()"
              [innerHTML]="fillData.confirmText"
              [autofocus]="'autofocus'"
            ></button>
            <button
              type="button"
              class="btn btn-default"
              (click)="onClose()"
              [innerHTML]="fillData.cancelText"
            ></button>
          </ng-container>
          <ng-container *ngSwitchCase="'warning'">
            <button
              type="button"
              class="btn btn-default"
              (click)="onSubmit()"
              [innerHTML]="fillData.confirmText"
            ></button>
            <button
              type="button"
              class="btn btn-default"
              (click)="onClose()"
              [innerHTML]="fillData.cancelText"
              [autofocus]="'autofocus'"
            ></button>
          </ng-container>
          <ng-container *ngSwitchCase="'information'">
            <button
              type="button"
              class="btn btn-default"
              (click)="onSubmit()"
              [innerHTML]="fillData.confirmText"
            ></button>
          </ng-container>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./ss-confirm-dialog.component.scss'],
})
export class SsConfirmDialogComponent extends SsModalDialogComponent<ConfirmDialogFillData> {
  constructor(ssDialogService: SsDialogService) {
    super(ssDialogService);
  }

  /**
   * 「はい」ハンドラ
   */
  onSubmit() {
    this.onDone({ action: DIALOG_EVENT.DONE });
  }
}
