import { ActionMessage } from '@app-units/models/action-message/action-message';
import { ActionMessageOriginInterface } from '@app-units/models/action-message/action-message-origin';
import { ActionOriginInterface } from '@app-units/models/action/action-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

/**
 * @classdesc オーダーアクション情報
 * @export
 * @class Action
 */
export class Action extends SsSelective implements OriginWrapper {
  private readonly origin: ActionOriginInterface;

  date: string;
  messages: ActionMessage[];

  get posting_data(): ActionOriginInterface {
    return new ActionOrigin(
      this.date,
      _.map(this.messages, (message: ActionMessage) => message.posting_data)
    );
  }

  constructor(action?: ActionOriginInterface) {
    super();
    this.origin = this.createActionInstance(action);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createActionInstance(action: ActionOriginInterface): ActionOrigin {
    return action
      ? new ActionOrigin(action.date, action.messages)
      : new ActionOrigin();
  }

  private initializeProperties(action: ActionOrigin) {
    this.date = action.date ? action.date : null;
    this.messages = _.map(
      action.messages,
      (message: ActionMessageOriginInterface) => new ActionMessage(message)
    );
  }
}

class ActionOrigin implements ActionOriginInterface {
  date: string;
  messages: ActionMessageOriginInterface[];

  constructor(date?: string, messages?: ActionMessageOriginInterface[]) {
    this.date = date ? date : null;
    this.messages = messages ? _.cloneDeep(messages) : null;
  }
}
