import { PortalOfferedOrderOriginInterface } from '@app-units/models/portal-offered-order/portal-offered-order-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class PortalOfferedOrder implements OriginWrapper {
  private readonly origin: PortalOfferedOrderOriginInterface;

  id: string;
  order_cd: string;
  client_reaction: number;
  client_reaction_name: string;
  offered_date: string;
  prefecture_name: string;
  city: string;
  article_type_name: string;
  article_age: string;
  floor_name: string;
  floor_area_name: string;
  repair_request_timing: string;
  repair_request_name: string;
  entry_due_date: string;

  get posting_data(): PortalOfferedOrderOriginInterface {
    return new PortalOfferedOrderOrigin(
      parseInt(this.id),
      this.order_cd,
      this.client_reaction,
      this.client_reaction_name,
      this.offered_date,
      this.prefecture_name,
      this.city,
      this.article_type_name,
      this.article_age,
      this.floor_name,
      this.floor_area_name,
      this.repair_request_timing,
      this.repair_request_name,
      this.entry_due_date
    );
  }

  constructor(portalOfferedOrder?: PortalOfferedOrderOriginInterface) {
    this.origin = this.createPortalOfferedOrderInstance(portalOfferedOrder);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createPortalOfferedOrderInstance(
    portalOfferedOrder: PortalOfferedOrderOriginInterface
  ): PortalOfferedOrderOrigin {
    return portalOfferedOrder
      ? new PortalOfferedOrderOrigin(
          portalOfferedOrder.id,
          portalOfferedOrder.order_cd,
          portalOfferedOrder.client_reaction,
          portalOfferedOrder.client_reaction_name,
          portalOfferedOrder.offered_date,
          portalOfferedOrder.prefecture_name,
          portalOfferedOrder.city,
          portalOfferedOrder.article_type_name,
          portalOfferedOrder.article_age,
          portalOfferedOrder.floor_name,
          portalOfferedOrder.floor_area_name,
          portalOfferedOrder.repair_request_timing,
          portalOfferedOrder.repair_request_name,
          portalOfferedOrder.entry_due_date
        )
      : new PortalOfferedOrderOrigin();
  }

  private initializeProperties(portalOfferedOrder: PortalOfferedOrderOrigin) {
    this.id = !_.isNil(portalOfferedOrder.id)
      ? String(portalOfferedOrder.id)
      : null;
    this.order_cd = portalOfferedOrder.order_cd;
    this.client_reaction = portalOfferedOrder.client_reaction;
    this.client_reaction_name = portalOfferedOrder.client_reaction_name;
    this.offered_date = portalOfferedOrder.offered_date;
    this.prefecture_name = portalOfferedOrder.prefecture_name;
    this.city = portalOfferedOrder.city;
    this.article_type_name = portalOfferedOrder.article_type_name;
    this.article_age = portalOfferedOrder.article_age;
    this.floor_name = portalOfferedOrder.floor_name;
    this.floor_area_name = portalOfferedOrder.floor_area_name;
    this.repair_request_timing = portalOfferedOrder.repair_request_timing;
    this.repair_request_name = portalOfferedOrder.repair_request_name;
    this.entry_due_date = portalOfferedOrder.entry_due_date;
  }
}

class PortalOfferedOrderOrigin implements PortalOfferedOrderOriginInterface {
  id: number;
  order_cd: string;
  client_reaction: number;
  client_reaction_name: string;
  offered_date: string;
  prefecture_name: string;
  city: string;
  article_type_name: string;
  article_age: string;
  floor_name: string;
  floor_area_name: string;
  repair_request_timing: string;
  repair_request_name: string;
  entry_due_date: string;

  constructor(
    id?: number,
    order_cd?: string,
    client_reaction?: number,
    client_reaction_name?: string,
    offered_date?: string,
    prefecture_name?: string,
    city?: string,
    article_type_name?: string,
    article_age?: string,
    floor_name?: string,
    floor_area_name?: string,
    repair_request_timing?: string,
    repair_request_name?: string,
    entry_due_date?: string
  ) {
    this.id = id ? id : null;
    this.order_cd = order_cd ? order_cd : null;
    this.client_reaction = !_.isNil(client_reaction)
      ? Number(client_reaction)
      : null;
    this.client_reaction_name = client_reaction_name
      ? client_reaction_name
      : null;
    this.offered_date = offered_date ? offered_date : null;
    this.prefecture_name = prefecture_name ? prefecture_name : null;
    this.city = city ? city : null;
    this.article_type_name = article_type_name ? article_type_name : null;
    this.article_age = article_age ? article_age : null;
    this.floor_name = floor_name ? floor_name : null;
    this.floor_area_name = floor_area_name ? floor_area_name : null;
    this.repair_request_timing = repair_request_timing
      ? repair_request_timing
      : null;
    this.repair_request_name = repair_request_name ? repair_request_name : null;
    this.entry_due_date = entry_due_date ? entry_due_date : null;
  }
}
