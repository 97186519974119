import { of, Observable } from 'rxjs';

import { tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { SsConfig, SsType } from '@ss-core/models';
import { AuthService } from '@ss-core/services';
import * as _ from 'lodash';

/**
 * @classdesc ページConfigサービス
 * @export
 * @class ConfigHttpService
 */
@Injectable()
export class ConfigHttpService {
  // 取得したconfigのキャッシュ
  private cached = {};

  /** URL */
  private get resouse(): string {
    return '/configs';
  }

  constructor(private http: HttpClient, private authService: AuthService) {}

  /**  */
  fetchConfig(name: string): Observable<SsConfig> {
    // キャッシュ取得
    const cached = this.cached[name];

    if (cached) {
      // キャッシュされてればコピーして使う
      console.info(name + '-config cached');
      return of(Object.assign({}, cached));
    } else {
      // searchParams作成
      let params = new HttpParams();
      params = params.append('app_name', name);

      // 未取得ならbackendから取得
      return this.http.get(this.resouse, { params }).pipe(
        map(this.mapConfig.bind(this)),
        // 取得したconfigをキャッシュしておく
        tap((config) => (this.cached[name] = config))
      );
    }
  }

  /** レスポンスをapp.response.dataにmap */
  private mapConfig(response: app.response<SsConfig>) {
    /** SsTypeのインスタンスを生成しtypesとしてdataに挿入する */
    _.each(response.data.types, (types, key) => {
      if (_.isArray(response.data.types[key])) {
        response.data.types[key] = _.map(types, (type: any) => {
          return new SsType(type.id, type.name, type.enable, {
            relations: type.relations,
            group: type.group,
          });
        });
      }
    });

    return response.data;
  }
}
