import { ActionMailTemplate } from '@app-units/models/action-mail-template/action-mail-template';
import { ActionNote } from '@app-units/models/action-note/action-note';
import { Action } from '@app-units/models/action/action';
import { AdInfo } from '@app-units/models/ad-info/ad-info';
import { AddDelivery } from '@app-units/models/add-delivery/add-delivery';
import { AgainDelivery } from '@app-units/models/again-delivery/again-delivery';
import { ArticleConstructionInfo } from '@app-units/models/article-construction-info/article-construction-info';
import { Attachment } from '@app-units/models/attachment/attachment';
import { AuthUser } from '@app-units/models/auth-user/auth-user';
import { ChargeDetailResult } from '@app-units/models/charge-detail-result/charge-detail-result';
import { ClientBasicInfo } from '@app-units/models/client-basic-info/client-basic-info';
import { ClientInfoButtonState } from '@app-units/models/client-info-button-state/client-info-button-state';
import { ClientInfo } from '@app-units/models/client-info/client-info';
import { ClientStaff } from '@app-units/models/client-staff/client-staff';
import { ClientPortalOrder } from '@app-units/models/client-portal-order/client-portal-order';
import { ClientPortalProgress } from '@app-units/models/client-portal-progress/client-portal-progress';
import { Client } from '@app-units/models/client/client';
import { CompatiblePrefecture } from '@app-units/models/compatible-prefecture/compatible-prefecture';
import { CompatibleCities } from '@app-units/models/compatible-cities/compatible-cities';
import { Content } from '@app-units/models/content/content';
import { ContractInfo } from '@app-units/models/contract-info/contract-info';
import { Contract } from '@app-units/models/contract/contract';
import { EndInfo } from '@app-units/models/end-info/end-info';
import { EntryInfo } from '@app-units/models/entry-info/entry-info';
import { OfferedOrderHistory } from '@app-units/models/offered-order-history/offered-order-history';
import { OrderClientRelation } from '@app-units/models/order-client-relation/order-client-relation';
import { OrderLastEditTimestamp } from '@app-units/models/order-last-edit-timestamp/order-last-edit-timestamp';
import { Order } from '@app-units/models/order/order';
import { Review } from '@app-units/models/review/review';
import { ReviewManageInfo } from '@app-units/models/review/review-manage-info';
import { ReviewQuestionnaireInfo } from '@app-units/models/review/review-questionnaire-info';
import { ReviewOrderClientInfo } from '@app-units/models/review/review-order-client-info';
import { ReviewMails } from '@app-units/models/review/review-mails';
import { PortalOfferedOrder } from '@app-units/models/portal-offered-order/portal-offered-order';
import { Progress } from '@app-units/models/progress/progress';
import { QuestionnaireMemoInfo } from '@app-units/models/questionnaire-memo-info/questionnaire-memo-info';
import { Report } from '@app-units/models/report/report';
import { SmsInfo } from '@app-units/models/sms-info/sms-info';
import { Survey } from '@app-units/models/survey/survey';
import { TransferredOrder } from '@app-units/models/transferred-order/transferred-order';
import { User } from '@app-units/models/user/user';
import * as _ from 'lodash';

/**
 * @classdesc ResponseのJsonからdataを
 * 任意のクラスのインスタンスへ変換する。
 * @description typescriptにstatic classの概念がないため、
 * constructorをprivateにしている。
 * @export
 * @class JsonConverter
 *
 * TODO: これらの変換処理はServiceが受け持つべきで、集約は無駄では？
 */
export class JsonConverter {
  /**
   * インスタンスを生成できないようにする。
   */
  private constructor() {}

  public static toClient(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new Client(data);
        })
      : new Client(json.data);
    return json;
  }

  public static toUser(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new User(data);
        })
      : new User(json.data);
    return json;
  }

  public static toAuthUser(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new AuthUser(data);
        })
      : new AuthUser(json.data);
    return json;
  }

  public static toReview(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return Review.initData(data);
        })
      : Review.initData(json.data);
    return json;
  }

  public static toReviewManageInfo(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return ReviewManageInfo.initData(data);
        })
      : ReviewManageInfo.initData(json.data);
    return json;
  }

  public static toReviewQuestionnaireInfo(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return ReviewQuestionnaireInfo.initData(data);
        })
      : ReviewQuestionnaireInfo.initData(json.data);
    return json;
  }

  public static toReviewOrderClientInfo(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return ReviewOrderClientInfo.initData(data);
        })
      : ReviewOrderClientInfo.initData(json.data);
    return json;
  }

  public static toReviewMails(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return ReviewMails.initData(data);
        })
      : ReviewMails.initData(json.data);
    return json;
  }

  public static toOrder(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new Order(data);
        })
      : new Order(json.data);
    return json;
  }

  public static toOrderLastEditTimestamp(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new OrderLastEditTimestamp(data);
        })
      : new OrderLastEditTimestamp(json.data);
    return json;
  }

  public static toAttachment(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new Attachment(data);
        })
      : new Attachment(json.data);
    return json;
  }

  public static toEndInfo(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new EndInfo(data);
        })
      : new EndInfo(json.data);
    return json;
  }

  public static toAddDelivery(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new AddDelivery(data);
        })
      : new AddDelivery(json.data);
    return json;
  }

  public static toAgainDelivery(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new AgainDelivery(data);
        })
      : new AgainDelivery(json.data);
    return json;
  }

  public static toContractInfo(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new ContractInfo(data);
        })
      : new ContractInfo(json.data);
    return json;
  }

  public static toSurvey(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new Survey(data);
        })
      : new Survey(json.data);
    return json;
  }

  public static toArticleConstructionInfo(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new ArticleConstructionInfo(data);
        })
      : new ArticleConstructionInfo(json.data);
    return json;
  }

  public static toAction(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new Action(data);
        })
      : new Action(json.data);
    return json;
  }

  public static toActionSimpleTel(response): app.response<any> {
    const json = response;
    return json;
  }

  public static toActionNote(response): app.response<any> {
    const json = response;
    json.data = new ActionNote(json.data);
    return json;
  }

  public static toActionMailTemplate(response): app.response<any> {
    const json = response;
    json.data = new ActionMailTemplate(json.data);
    return json;
  }

  public static toClientInfo(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new ClientInfo(data);
        })
      : new ClientInfo(json.data);
    return json;
  }

  public static toClientInfoButtonState(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new ClientInfoButtonState(data);
        })
      : new ClientInfoButtonState(json.data);
    return json;
  }

  public static toClientStaff(response): app.response<any> {
    const json = response;
    json.clientStaffs = Array.isArray(json.clientStaffs)
      ? _.map(json.clientStaffs, (data: any) => {
          return new ClientStaff(data);
        })
      : [new ClientStaff(json.clientStaffs)];
    return json;
  }

  public static toProgress(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new Progress(data);
        })
      : new Progress(json.data);
    return json;
  }

  public static toClientPortalProgress(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new ClientPortalProgress(data);
        })
      : new ClientPortalProgress(json.data);
    return json;
  }

  public static toOrderClientRelation(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new OrderClientRelation(data);
        })
      : new OrderClientRelation(json.data);
    return json;
  }

  public static toContract(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new Contract(data);
        })
      : new Contract(json.data);
    return json;
  }

  public static toSmsInfo(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new SmsInfo(data);
        })
      : new SmsInfo(json.data);
    return json;
  }

  public static toContent(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new Content(data);
        })
      : new Content(json.data);
    return json;
  }

  public static toAdinfo(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new AdInfo(data);
        })
      : new AdInfo(json.data);
    return json;
  }

  public static toEntryInfo(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new EntryInfo(data);
        })
      : new EntryInfo(json.data);
    return json;
  }

  public static toOfferedOrderHistory(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new OfferedOrderHistory(data);
        })
      : new OfferedOrderHistory(json.data);
    return json;
  }

  public static toPortalOfferedOrder(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new PortalOfferedOrder(data);
        })
      : new PortalOfferedOrder(json.data);
    return json;
  }

  public static toClientPortalOrders(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new ClientPortalOrder(data);
        })
      : new ClientPortalOrder(json.data);
    return json;
  }

  public static toTransferredOrder(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new TransferredOrder(data);
        })
      : new TransferredOrder(json.data);
    return json;
  }

  public static toChargeDetailResult(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new ChargeDetailResult(data);
        })
      : new ChargeDetailResult(json.data);
    return json;
  }
  public static toClientBasicInfo(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new ClientBasicInfo(data);
        })
      : new ClientBasicInfo(json.data);
    return json;
  }
  public static toCompatiblePrefectre(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new CompatiblePrefecture(data);
        })
      : new CompatiblePrefecture(json.data);
    return json;
  }
  public static toCompatibleCities(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new CompatibleCities(data);
        })
      : new CompatibleCities(json.data);
    return json;
  }
  public static toReport(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new Report(data);
        })
      : new Report(json.data);
    return json;
  }
  public static toQuestionnaireMemoInfo(response): app.response<any> {
    const json = response;
    json.data = Array.isArray(json.data)
      ? _.map(json.data, (data: any) => {
          return new QuestionnaireMemoInfo(data);
        })
      : new QuestionnaireMemoInfo(json.data);
    return json;
  }
}
