import { SsRoute } from '@ss-core/models';
import { AuthGuard } from '@ss-core/services';
import { RoleGuard } from '@ss-core/services/role-guard/role-guard';

import { RouteConstant } from '@app-core/constants';
import { ConfigResolve } from '@app-core/resolves';
import { LoginComponent } from '@app-pages/login/login.component';
import { ErrorComponent } from '@app-core/components/error/error.component';

/**
 * トップレベルのルーティング設定
 */
export const appRoutes: SsRoute[] = [
  /** クライアントポータル Home */
  {
    path: RouteConstant.CLIENT_PORTAL_HOME.path,
    loadChildren: () =>
      import('./pages/client-portal-home/client-portal-home-page.module').then(
        (m) => m.ClientPortalHomePageModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    resolve: { config: ConfigResolve },
    data: RouteConstant.CLIENT_PORTAL_HOME.data,
  },

  /** クライアントポータル 案件管理 */
  {
    path: RouteConstant.CLIENT_PORTAL_OFFERED_ORDERS.path,
    loadChildren: () =>
      import(
        './pages/client-portal-offered-orders/client-portal-offered-orders-page.module'
      ).then((m) => m.ClientPortalOfferedOrdersPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    resolve: { config: ConfigResolve },
    data: RouteConstant.CLIENT_PORTAL_OFFERED_ORDERS.data,
  },

  /** クライアントポータル オーダー管理 */
  {
    path: RouteConstant.CLIENT_PORTAL_ORDERS.path,
    loadChildren: () =>
      import(
        './pages/client-portal-orders/client-portal-orders-page.module'
      ).then((m) => m.ClientPortalOrdersPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    resolve: { config: ConfigResolve },
    data: RouteConstant.CLIENT_PORTAL_ORDERS.data,
  },

  /** 口コミ管理 */
  {
    path: RouteConstant.REVIEWS.path,
    loadChildren: () =>
      import('./pages/reviews/reviews-page.module').then(
        (m) => m.ReviewsPageModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    resolve: { config: ConfigResolve },
    data: RouteConstant.REVIEWS.data,
  },

  /** オーダー管理 */
  {
    path: RouteConstant.ORDER.path,
    loadChildren: () =>
      import('./pages/orders/orders-page.module').then(
        (m) => m.OrdersPageModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    resolve: { config: ConfigResolve },
    data: RouteConstant.ORDER.data,
  },

  /** クライアント管理 */
  {
    path: RouteConstant.CLIENTS.path,
    loadChildren: () =>
      import('./pages/clients/clients-page.module').then(
        (m) => m.ClientsPageModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    resolve: { config: ConfigResolve },
    data: RouteConstant.CLIENTS.data,
  },

  /** ユーザー管理 */
  {
    path: RouteConstant.USERS.path,
    loadChildren: () =>
      import('./pages/users/users-page.module').then((m) => m.UsersPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    resolve: { config: ConfigResolve },
    data: RouteConstant.USERS.data,
  },

  /** クライアント追加 */
  {
    path: RouteConstant.ORDER_CLIENT_ADD.path,
    loadChildren: () =>
      import('./pages/order-client-add-page/order-client-add-page.module').then(
        (m) => m.OrderClientAddPageModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    resolve: { config: ConfigResolve },
    data: RouteConstant.ORDER_CLIENT_ADD.data,
  },

  /** オーダー書き出し画面 */
  {
    path: RouteConstant.ORDER_EXPORT.path,
    loadChildren: () =>
      import('./pages/order-export/order-export.module').then(
        (m) => m.OrderExportModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    resolve: { config: ConfigResolve },
    data: RouteConstant.ORDER_EXPORT.data,
  },

  /** login */
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'login',
      icon: '',
    },
  },

  /** ランディングページへリダイレクト */
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/landing/landing-page.module').then(
        (m) => m.LandingPageModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: {
      title: '',
      icon: '',
    },
  },

  /** 404 Not Found */
  {
    path: '**',
    component: ErrorComponent,
  },
];
