import { map, filter, tap, take } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, Router } from '@angular/router';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

import { ISsConstant, SS_CONSTANT } from '../../constants';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';

/**
 * @classdesc routerでのアクセス制御（認証）
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 * @implements {CanLoad}
 */
@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  private loginPath: string;
  private defaultPath: string;

  constructor(
    @Inject(SS_CONSTANT) SS_CONSTANT: ISsConstant,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {
    // 定数から遷移先のroutePath名を取得
    this.loginPath = 'login';
    this.defaultPath = '';
  }

  /**
   * moduleのlazyLoadのアクセス制御
   * - データを読み込ませる前にリダイレクトしたい→canLoad
   * - canLoadの返値をObservableにしたい場合は、利用回数を制限する必要がある
   */
  canLoad(route: Route): Observable<boolean> {
    // ログイン認証確認
    return this.isAuthenticated().pipe(
      take(1),
      filter((isAuth) => !isAuth),
      tap(() => {
        //認証失敗の場合
        console.warn('moduleのlazyLoad 認証失敗', route);
        this.replaceNavigate([this.loginPath]);
      })
    );
  }

  /**
   * コンポーネントへのアクセス制御
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    // ログイン画面かの判定
    const isLoginRoute = route.routeConfig.path === this.loginPath;
    // ログイン認証確認
    return this.isAuthenticated().pipe(
      map((isAuth) =>
        isAuth
          ? this.activateSuccess(isLoginRoute)
          : this.activateError(isLoginRoute)
      )
    );
  }

  /**
   * ログイン認証確認
   */
  private isAuthenticated(): Observable<boolean> {
    // ログイン認証済みかを取得
    const isAuthenticated = this.authService.isAuthenticated();
    // backendへ認証確認が失敗した事があるかを取得
    const isAuthRequestFailed = this.authService.isAuthRequestFailed;

    // Observableを作成して返却
    return Observable.create((observer) => {
      if (isAuthenticated) {
        // 認証済みの場合
        observer.next(true);
      } else if (isAuthRequestFailed) {
        // 認証確認が失敗済みの場合
        observer.next(false);
      } else {
        // 認証済みでない場合、ログイン者情報を取得してみる
        this.authService.fetchUser().subscribe(
          (response) => {
            if (response.data.id) {
              observer.next(true);
            } else {
              observer.next(false);
            }
          },
          (error) => observer.next(false)
        );
      }
    });
  }

  /**
   * コンポーネントへのアクセス成功処理
   */
  private activateSuccess(isLoginRoute: boolean): boolean {
    if (isLoginRoute) {
      // ログイン画面の場合は、初期ページに遷移
      this.replaceNavigate([this.defaultPath]);
      return false;
    } else {
      // その他の画面は、そのまま遷移
      return true;
    }
  }

  /**
   * コンポーネントへのアクセス失敗処理
   */
  private activateError(isLoginRoute: boolean): boolean {
    console.warn('activate 認証失敗');
    if (isLoginRoute) {
      // ログイン画面の場合は、そのまま遷移
      return true;
    } else {
      // その他の画面は、ログインページに遷移
      this.replaceNavigate([this.loginPath]);
      return false;
    }
  }

  /**
   * ページの遷移（historyの履歴は残さない）
   */
  private replaceNavigate(loginPaths: string[]) {
    if (environment.production) {
      this.router.navigate(loginPaths, { replaceUrl: true });
    } else {
      /** 401なら/loginに遷移 **/
      loginPaths.unshift(location.origin);
      location.replace(loginPaths.join('/'));
    }
  }
}
