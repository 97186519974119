import { InjectionToken } from '@angular/core';

// 定数をDIして使用する為に、InjectionTokenを定義
export const SS_CONSTANT = new InjectionToken('ss.constant');

/**
 *
 */

/**
 * @desc アプリ定数 interface
 * @export
 * @interface ISsConstant
 */
export interface ISsConstant {
  /** プロジェクトタイトル */
  PROJECT_TITLE: string;
  /** APIのベースURL */
  API_BASE_URL: string;
  /** ログインページのpath */
  LOGIN_PATH: string;
  /** オーダー詳細 > クライアント追加 で追加成功した時にsessionStorageに保存しておくKey名 */
  ORDER_CLIENT_ADD_SUCCESS_RESPONSE_KEY: string;
  /** order管理、client管理からの井関システムへの遷移先URL */
  ISEKI_LINK_URL: string;
}

/**
 * アプリ定数
 */
export const SsConstant: ISsConstant = {
  PROJECT_TITLE: '外壁塗装の窓口',
  API_BASE_URL: '/api',
  LOGIN_PATH: 'login',
  ORDER_CLIENT_ADD_SUCCESS_RESPONSE_KEY:
    'order_client_add_success_response_key',
  ISEKI_LINK_URL: 'https://iseki.app/doors/system',
};
