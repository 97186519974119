import '../styles/styles.scss';

import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { ToastInterceptor } from './http-interceptor';
import { AppComponent } from './app.component';
import { LoginComponent } from '@app-pages/login/login.component';
import { appRoutes } from './app.routes';
import { AppCoreModule } from '@app-core/app-core.module';
import { AppSharedModule } from '@app-shared/app-shared.module';
import { SsCoreModule } from '@ss-core/ss-core.module';

/**
 * @classdesc AppModule
 * @export
 * @class AppModule
 */
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SsCoreModule.forRoot(),
    AppCoreModule.forRoot(),
    AppSharedModule.forRoot(),
    RouterModule.forRoot(appRoutes),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [RouterModule],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ToastInterceptor,
      multi: true,
    },
  ],
  declarations: [AppComponent, LoginComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
