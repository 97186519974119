import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class SsUrlSearchParamsService {
  constructor() {}

  parseParams(search: any): HttpParams {
    let params = new HttpParams();
    for (const key in search) {
      if (search[key]) {
        params = params.append(key, search[key]);
      }
    }
    return params;
  }
}
