import { tap, map, filter } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { RouteConstant } from '@app-core/constants';
import { ISsConstant, SS_CONSTANT } from '@ss-core/constants';
import { SsRouteData } from '@ss-core/models';

/**
 * @classdesc ページタイトル設定Service
 * @export
 * @class PageTitleService
 */
@Injectable()
export class PageTitleService {
  /** ページタイトル（route.data）のSubject */
  public title: BehaviorSubject<SsRouteData[]> = new BehaviorSubject<
    SsRouteData[]
  >(null);
  private _prevRootTitle: SsRouteData[];
  private _prevRouteComponent: string;

  constructor(
    @Inject(SS_CONSTANT) private SS_CONSTANT: ISsConstant,
    private titleService: Title,
    router: Router,
    activatedRoute: ActivatedRoute
  ) {
    /**
     * ページ切り替わりのタイミングで自動でtitleを設定
     */
    router.events
      .pipe(
        // 切り替わり完了のイベントだけを絞り込み
        filter((event) => event instanceof NavigationEnd),
        // streamをactivatedRouteに変更
        map(() => activatedRoute),
        map((event) => {
          const routes = [];
          while (!!event.firstChild) {
            routes.push(event.firstChild);
            event = event.firstChild;
          }
          return routes;
        }),
        tap((routes) => (this._prevRouteComponent = _.last(routes).component)),
        // streamをSsRouteDataの配列に変更
        map((routes) => {
          return (
            routes
              .filter((route) => route.outlet === 'primary')
              // pathが空のindexページは、親のdataを引き継いでしまい、結果重複する為取り除く。
              .filter(
                (route) =>
                  route.routeConfig.path !== RouteConstant.COMMON.INDEX.path
              )
              .map((route) => route.routeConfig.data)
              .filter((data) => {
                if (data?.title) return true;
                else return false;
              })
          );
        })
      )
      .subscribe((routeDatas) => {
        this.title.next(routeDatas);
        // this.setTitleTag(routeDatas);
        this._prevRootTitle = routeDatas;
      });
  }

  /**
   * ページタイトルを変更
   */
  resetTitle(titles: SsRouteData[]) {
    this.title.next(titles);
  }

  /**
   * ページタイトルを追加
   */
  addTitle(titles: SsRouteData[]) {
    this.title.next([...this._prevRootTitle, ...titles]);
  }

  /**
   * ページタイトル（meta）を設定
   */
  private setTitleTag(titles: SsRouteData[]) {
    const currentPage = _.first(titles);
    this.titleService.setTitle(
      `${!!currentPage ? currentPage.title : ''} | ${
        this.SS_CONSTANT.PROJECT_TITLE
      }`
    );
  }
}
