import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** SsComponents */
import { SsBusyModule } from '@blocks/busy/ss-busy.module';
import { SsSelectFilterPipe } from '@blocks/select/ss-select-filter.pipe';
import { SsSelectTemplateDirective } from '@blocks/select/ss-select/ss-select-template.directive';
import { SsSelectComponent } from '@blocks/select/ss-select/ss-select.component';
import { SsValidateErrorModule } from '@blocks/validate-error/ss-validate-error.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SsValidateErrorModule,
    SsBusyModule,
  ],
  declarations: [
    SsSelectComponent,
    SsSelectFilterPipe,
    SsSelectTemplateDirective,
  ],
  exports: [SsSelectComponent],
})
export class SsSelectModule {}
