import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';

@Injectable()
export class RouterPrevService {
  prevSnapshot: ActivatedRouteSnapshot;
  prevUrl: string;

  constructor(private router: Router, private route: ActivatedRoute) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.prevSnapshot = this.route.snapshot;
        this.prevUrl = event.url;
      }
    });
  }
}
