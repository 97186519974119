import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { ActionMailTemplate } from '@app-units/models/action-mail-template/action-mail-template';
import { ActionMailOriginInterface } from '@app-units/models/action-mail/action-mail-origin';
import { ActionMessageOriginInterface } from '@app-units/models/action-message/action-message-origin';
import { ActionNote } from '@app-units/models/action-note/action-note';
import { ActionNoteOriginInterface } from '@app-units/models/action-note/action-note-origin';
import { Action } from '@app-units/models/action/action';
import { Observable } from 'rxjs';

// 宛先
export enum ACTOR_TYPE {
  END = 1,
  CLIENT,
}

// アクション種別
export enum ACTION_TYPE {
  MEMO = 1,
  TEL,
  EMAIL,
  SIMPLE_TEL,
  AUTO,
}

/**
 * 架電記録 post data
 */
export interface SimpleTelPostDataInterface {
  action_content: string;
  action_type: number;
  actor_type: number;
}

@Injectable()
export class ActionsHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(
    id: string,
    isClientPortal: boolean = false
  ): Observable<app.response<Action[]>> {
    return this.http
      .get(this.getUrl(isClientPortal, id))
      .pipe(map(JsonConverter.toAction));
  }

  saveItem(
    id: string,
    data: ActionMessageOriginInterface,
    isClientPortal: boolean = false
  ): Observable<app.response<Action[]>> {
    if (data && data.id) {
      return this.http
        .put(this.getUrl(isClientPortal, id, String(data.id)), data)
        .pipe(map(JsonConverter.toAction));
    }
    return this.http
      .post(this.getUrl(isClientPortal, id), data)
      .pipe(map(JsonConverter.toAction));
  }

  deleteItem(id: string, actionId: string, isClientPortal: boolean = false) {
    return this.http
      .delete(this.getUrl(isClientPortal, id, actionId))
      .pipe(map(JsonConverter.toAction));
  }

  fetchMailTemplate(
    tmpId: string
  ): Observable<app.response<ActionMailTemplate>> {
    return this.http
      .get(`/mail/${tmpId}`)
      .pipe(map(JsonConverter.toActionMailTemplate));
  }

  sendMail(
    orderId: string,
    data: ActionMailOriginInterface
  ): Observable<app.response<Action[]>> {
    return this.http
      .post(`/orders/${orderId}/action_mails`, data)
      .pipe(map(JsonConverter.toAction));
  }

  /**
   * エンド架電記録を取得する
   */
  fetchSimpleTel(id: string): Observable<app.response<Action[]>> {
    return this.http
      .get(this.getSimpleTelUrl(id))
      .pipe(map(JsonConverter.toActionSimpleTel));
  }

  /**
   * エンド架電記録を保存する
   */
  postSimpleTel(
    id: string,
    data: SimpleTelPostDataInterface
  ): Observable<app.response<any>> {
    return this.http
      .post(this.getSimpleTelUrl(id), data)
      .pipe(map(JsonConverter.toActionSimpleTel));
  }

  /**
   * クライアント架電記録を取得する
   */
  fetchSimpleClientTel(id: string): Observable<app.response<Action[]>> {
    return this.http
      .get(this.getSimpleClientTelUrl(id))
      .pipe(map(JsonConverter.toActionSimpleTel));
  }

  /**
   * クライアント架電記録を保存する
   */
  postSimpleClientTel(
    id: string,
    data: SimpleTelPostDataInterface
  ): Observable<app.response<any>> {
    return this.http
      .post(this.getSimpleClientTelUrl(id), data)
      .pipe(map(JsonConverter.toActionSimpleTel));
  }

  /**
   * その他メモを取得する
   */
  fetchActionNote(id: string): Observable<app.response<ActionNote>> {
    return this.http
      .get(`/orders/${id}/action_note`)
      .pipe(map(JsonConverter.toActionNote));
  }

  putActionNote(
    id: string,
    data: ActionNoteOriginInterface
  ): Observable<app.response<ActionNote>> {
    return this.http
      .put(`/orders/${id}/action_note`, data)
      .pipe(map(JsonConverter.toActionNote));
  }

  private getUrl(isClientPortal: boolean, id: string, actionId?: string) {
    if (actionId) {
      return isClientPortal
        ? `/portal/orders/${id}/actions/${actionId}`
        : `/orders/${id}/actions/${actionId}`;
    }
    return isClientPortal
      ? `/portal/orders/${id}/actions`
      : `/orders/${id}/actions`;
  }

  /**
   * エンド架電記録 API エンドポイント取得
   */
  private getSimpleTelUrl(id: string) {
    return `/orders/${id}/actions/simple_tel`;
  }

  /**
   * クライアント架電記録 API エンドポイント取得
   */
  private getSimpleClientTelUrl(id: string) {
    return `/orders/${id}/actions/simple_client_tel`;
  }
}
