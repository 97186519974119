import {
  OrderMetaInterface,
  OrderOriginInterface,
} from '@app-units/models/order/order-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class Order extends SsSelective implements OriginWrapper {
  id: string;
  end_name: string;
  is_prime_contractor: boolean;
  order_cd: string;
  req_datetime: string;
  order_status: string;
  order_status_name: string;
  user_for_calling_name: string;
  user_for_delivery_name: string;
  label: string;
  end_tel: string;
  end_tel_second: string;
  end_email: string;
  client_introduce_date: string;
  second_call_date: string;
  source_site_name: string;
  ad_keyword: string;
  end_first_name: string;
  end_last_name: string;
  source_site: string;
  next_call_timing: string;
  next_call_timing_name: string;
  next_call: string;
  action_content: string;
  modified: string;
  prefecture: string;
  prefecture_name: string;
  city: string;
  introduce_request: string;
  introduce_request_name: string;
  add_introduce_date: string;
  ss_meta: OrderMetaInterface;
  accepted_offer_count: string;
  inflow_source: string;
  client_name_1: string;
  client_name_2: string;
  client_name_3: string;
  client_name_4: string;
  client_name_1_transfered: 1 | null;
  client_name_2_transfered: 1 | null;
  client_name_3_transfered: 1 | null;
  client_name_4_transfered: 1 | null;

  private readonly origin: OrderOriginInterface;

  get posting_data(): OrderOriginInterface {
    return new OrderOrigin(
      parseInt(this.id),
      this.end_name,
      this.is_prime_contractor,
      this.order_cd,
      this.req_datetime,
      parseInt(this.order_status),
      this.order_status_name,
      this.user_for_calling_name,
      this.user_for_delivery_name,
      this.label,
      this.end_tel,
      this.end_tel_second,
      this.end_email,
      this.client_introduce_date,
      this.second_call_date,
      this.source_site_name,
      this.ad_keyword,
      this.end_first_name,
      this.end_last_name,
      parseInt(this.source_site),
      parseInt(this.next_call_timing),
      this.next_call_timing_name,
      this.next_call,
      this.action_content,
      this.modified,
      parseInt(this.prefecture),
      this.prefecture_name,
      this.city,
      parseInt(this.introduce_request),
      this.introduce_request_name,
      this.add_introduce_date,
      this.ss_meta,
      this.accepted_offer_count,
      this.inflow_source,
      this.client_name_1,
      this.client_name_2,
      this.client_name_3,
      this.client_name_4,
      this.client_name_1_transfered,
      this.client_name_2_transfered,
      this.client_name_3_transfered,
      this.client_name_4_transfered
    );
  }

  constructor(order?: OrderOriginInterface) {
    super();
    this.origin = this.createOrderInstance(order);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createOrderInstance(order: OrderOriginInterface): OrderOrigin {
    return order
      ? new OrderOrigin(
          order.id,
          order.end_name,
          order.is_prime_contractor,
          order.order_cd,
          order.req_datetime,
          order.order_status,
          order.order_status_name,
          order.user_for_calling_name,
          order.user_for_delivery_name,
          order.label,
          order.end_tel,
          order.end_tel_second,
          order.end_email,
          order.client_introduce_date,
          order.second_call_date,
          order.source_site_name,
          order.ad_keyword,
          order.end_first_name,
          order.end_last_name,
          order.source_site,
          order.next_call_timing,
          order.next_call_timing_name,
          order.next_call,
          order.action_content,
          order.modified,
          order.prefecture,
          order.prefecture_name,
          order.city,
          order.introduce_request,
          order.introduce_request_name,
          order.add_introduce_date,
          order.ss_meta,
          order.accepted_offer_count,
          order.inflow_source,
          order.client_name_1,
          order.client_name_2,
          order.client_name_3,
          order.client_name_4,
          order.client_name_1_transfered,
          order.client_name_2_transfered,
          order.client_name_3_transfered,
          order.client_name_4_transfered
        )
      : new OrderOrigin();
  }

  private initializeProperties(order: OrderOrigin) {
    this.id = order.id || order.id === 0 ? String(order.id) : null;
    this.end_name = order.end_name || '未入力';
    this.is_prime_contractor = order.is_prime_contractor || false;
    this.order_cd = order.order_cd || null;
    this.req_datetime = order.req_datetime || null;
    this.order_status =
      order.order_status || order.order_status === 0
        ? String(order.order_status)
        : null;
    this.order_status_name = order.order_status_name || null;
    this.user_for_calling_name = order.user_for_calling_name || null;
    this.user_for_delivery_name = order.user_for_delivery_name || null;
    this.label = order.label || null;
    this.end_tel = order.end_tel || null;
    this.end_tel_second = order.end_tel_second || null;
    this.end_email = order.end_email || null;
    this.client_introduce_date = order.client_introduce_date || null;
    this.second_call_date = order.second_call_date || null;
    this.source_site_name = order.source_site_name || null;
    this.ad_keyword = order.ad_keyword || null;
    this.end_first_name = order.end_first_name || null;
    this.end_last_name = order.end_last_name || null;
    this.source_site =
      order.source_site || order.source_site === 0
        ? String(order.source_site)
        : null;
    this.next_call_timing =
      order.next_call_timing || order.next_call_timing === 0
        ? String(order.next_call_timing)
        : null;
    this.next_call_timing_name = order.next_call_timing_name || null;
    this.next_call = order.next_call || null;
    this.action_content = order.action_content || null;
    this.modified = order.modified || null;
    this.prefecture =
      order.prefecture || order.prefecture === 0
        ? String(order.prefecture)
        : null;
    this.prefecture_name = order.prefecture_name || null;
    this.city = order.city || null;
    this.introduce_request =
      order.introduce_request || order.introduce_request === 0
        ? String(order.introduce_request)
        : null;
    this.introduce_request_name = !_.isNil(order.introduce_request_name)
      ? order.introduce_request_name
      : null;
    this.add_introduce_date = order.add_introduce_date || null;
    this.ss_meta = order.ss_meta || null;
    this.accepted_offer_count = !_.isNil(order.accepted_offer_count)
      ? String(order.accepted_offer_count)
      : null;
    this.inflow_source = order.inflow_source;
    this.client_name_1 = order.client_name_1 || null;
    this.client_name_2 = order.client_name_2 || null;
    this.client_name_3 = order.client_name_3 || null;
    this.client_name_4 = order.client_name_4 || null;
    this.client_name_1_transfered = order.client_name_1_transfered || null;
    this.client_name_2_transfered = order.client_name_2_transfered || null;
    this.client_name_3_transfered = order.client_name_3_transfered || null;
    this.client_name_4_transfered = order.client_name_4_transfered || null;
  }
}

class OrderOrigin implements OrderOriginInterface {
  id: number;
  end_name: string;
  is_prime_contractor: boolean;
  order_cd: string;
  req_datetime: string;
  order_status: number;
  order_status_name: string;
  user_for_calling_name: string;
  user_for_delivery_name: string;
  label: string;
  end_tel: string;
  end_tel_second: string;
  end_email: string;
  client_introduce_date: string;
  second_call_date: string;
  source_site_name: string;
  ad_keyword: string;
  end_first_name: string;
  end_last_name: string;
  source_site: number;
  next_call_timing: number;
  next_call_timing_name: string;
  next_call: string;
  action_content: string;
  modified: string;
  prefecture: number;
  prefecture_name: string;
  city: string;
  introduce_request: number;
  introduce_request_name: string;
  add_introduce_date: string;
  ss_meta: OrderMetaInterface;
  accepted_offer_count: string;
  inflow_source: string;
  client_name_1: string;
  client_name_2: string;
  client_name_3: string;
  client_name_4: string;
  client_name_1_transfered: 1 | null;
  client_name_2_transfered: 1 | null;
  client_name_3_transfered: 1 | null;
  client_name_4_transfered: 1 | null;

  constructor(
    id?: number,
    end_name?: string,
    is_prime_contractor?: boolean,
    order_cd?: string,
    req_datetime?: string,
    order_status?: number,
    order_status_name?: string,
    user_for_calling_name?: string,
    user_for_delivery_name?: string,
    label?: string,
    end_tel?: string,
    end_tel_second?: string,
    end_email?: string,
    client_introduce_date?: string,
    second_call_date?: string,
    source_site_name?: string,
    ad_keyword?: string,
    end_first_name?: string,
    end_last_name?: string,
    source_site?: number,
    next_call_timing?: number,
    next_call_timing_name?: string,
    next_call?: string,
    action_content?: string,
    modified?: string,
    prefecture?: number,
    prefecture_name?: string,
    city?: string,
    introduce_request?: number,
    introduce_request_name?: string,
    add_introduce_date?: string,
    ss_meta?: OrderMetaInterface,
    accepted_offer_count?: string,
    inflow_source?: string,
    client_name_1?: string,
    client_name_2?: string,
    client_name_3?: string,
    client_name_4?: string,
    client_name_1_transfered?: 1 | null,
    client_name_2_transfered?: 1 | null,
    client_name_3_transfered?: 1 | null,
    client_name_4_transfered?: 1 | null
  ) {
    this.id = id || id === 0 ? id : null;
    this.end_name = end_name || null;
    this.is_prime_contractor = is_prime_contractor || false;
    this.order_cd = order_cd || null;
    this.req_datetime = req_datetime || null;
    this.order_status =
      order_status || order_status === 0 ? order_status : null;
    this.order_status_name = order_status_name || null;
    this.user_for_calling_name = user_for_calling_name || null;
    this.user_for_delivery_name = user_for_delivery_name || null;
    this.label = label || null;
    this.end_tel = end_tel || null;
    this.end_tel_second = end_tel_second || null;
    this.end_email = end_email || null;
    this.client_introduce_date = client_introduce_date || null;
    this.second_call_date = second_call_date || null;
    this.source_site_name = source_site_name || null;
    this.ad_keyword = ad_keyword || null;
    this.end_first_name = end_first_name || null;
    this.end_last_name = end_last_name || null;
    this.source_site = source_site || source_site === 0 ? source_site : null;
    this.next_call_timing =
      next_call_timing || next_call_timing === 0 ? next_call_timing : null;
    this.next_call_timing_name = next_call_timing_name || null;
    this.next_call = next_call || null;
    this.action_content = action_content || null;
    this.modified = modified || null;
    this.prefecture = prefecture || prefecture === 0 ? prefecture : null;
    this.prefecture_name = prefecture_name || null;
    this.city = city || null;
    this.introduce_request =
      introduce_request || introduce_request === 0 ? introduce_request : null;
    this.introduce_request_name = !_.isNil(introduce_request_name)
      ? introduce_request_name
      : null;
    this.add_introduce_date = add_introduce_date || null;
    this.ss_meta = ss_meta ? ss_meta : null;
    this.accepted_offer_count = !_.isNil(accepted_offer_count)
      ? accepted_offer_count
      : null;
    this.inflow_source = inflow_source;
    this.client_name_1 = client_name_1;
    this.client_name_2 = client_name_2;
    this.client_name_3 = client_name_3;
    this.client_name_4 = client_name_4;
    this.client_name_1_transfered = client_name_1_transfered || null;
    this.client_name_2_transfered = client_name_2_transfered || null;
    this.client_name_3_transfered = client_name_3_transfered || null;
    this.client_name_4_transfered = client_name_4_transfered || null;
  }
}
