import { map, catchError } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { PortalOfferedOrder } from '@app-units/models/portal-offered-order/portal-offered-order';
import { SsUrlSearchParamsService } from '@blocks/utils/ss-url-search-params/ss-url-search-params.service';
import { ISsConstant, SS_CONSTANT } from '@ss-core/constants/ss.constant';
import { Observable, of } from 'rxjs';

export class ClientPortalOfferedOrdersSearchParams {
  /* ページ数 */
  page?: string = null;
  /** ソート */
  order?: string = null;
  /** 表示件数 */
  limit?: string = null;
  /* 申込状況 */
  _client_reactions?: number[] = null;
  /* 案内日（開始）*/
  offered_date_start?: string = null;
  /* 案内日（終了）*/
  offered_date_end?: string = null;
  /* コード */
  order_cd?: string = null;
  /** 都道府県 */
  prefecture?: string = null;
  /** 市区町村 */
  city?: string = null;
  /** ステータス */
  status?: string = null;
}

@Injectable()
export class ClientPortalOfferedOrdersHttpService {
  constructor(
    private http: HttpClient,
    private ssUrlSearchParams: SsUrlSearchParamsService,
    @Inject(SS_CONSTANT) private SS_CONSTANT: ISsConstant
  ) {}

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * - https://v5.angular.io/tutorial/toh-pt6#final-code-review
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: 適切なエラーの投げ場所を見つけたら、差し替える
      console.error(error); // log to console instead
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  fetchItems(
    search: ClientPortalOfferedOrdersSearchParams
  ): Observable<app.response<PortalOfferedOrder[]>> {
    const searchParams = this.ssUrlSearchParams.parseParams(search);
    return this.http
      .get(this.getUrl(), { params: searchParams })
      .pipe(map(JsonConverter.toPortalOfferedOrder));
  }

  acceptOfferedOrder(offeredOrderId: string): Observable<app.response<any>> {
    const url = this.getUrl(offeredOrderId) + '/accept';
    return this.http
      .put<app.response<any>>(url, {})
      .pipe(
        catchError(this.handleError<app.response<any>>('acceptOfferedOrder'))
      );
  }

  refuseOfferedOrder(offeredOrderId: string): Observable<app.response<any>> {
    const url = this.getUrl(offeredOrderId) + '/refuse';
    return this.http
      .put<app.response<any>>(url, {})
      .pipe(
        catchError(this.handleError<app.response<any>>('refuseOfferedOrder'))
      );
  }

  resetOfferedOrder(offeredOrderId: string): Observable<app.response<any>> {
    return this.http
      .put<app.response<any>>(this.getUrl(offeredOrderId) + '/reset', {})
      .pipe(
        catchError(this.handleError<app.response<any>>('resetOfferedOrder'))
      );
  }

  getClientReaction(
    offeredOrderId: string
  ): Observable<app.response<PortalOfferedOrder>> {
    return this.http
      .get(this.getUrl(offeredOrderId) + '/client_reaction')
      .pipe(map(JsonConverter.toPortalOfferedOrder));
  }

  fetchItemPrevNext(
    offeredOrderId: string,
    search: ClientPortalOfferedOrdersSearchParams
  ): Observable<app.response<{ next_id: number; prev_id: number }>> {
    const url = this.getUrl(offeredOrderId) + '/prev_next';
    const params = this.ssUrlSearchParams.parseParams(search);
    return this.http
      .get<app.response<any>>(url, { params: params })
      .pipe(
        catchError(this.handleError<app.response<any>>('fetchItemPrevNext'))
      );
  }

  private getUrl(offeredOrderId?: string): string {
    if (offeredOrderId) {
      return `/portal/offered_orders/${offeredOrderId}`;
    }
    return `/portal/offered_orders`;
  }
}
