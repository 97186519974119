import { Injectable } from '@angular/core';
import { ArticleConstructionInfo } from '@app-units/models/article-construction-info/article-construction-info';
import { Subject } from 'rxjs';

/**
 * @classdesc Module間のデータのやりとりで使用するサービス
 * @export
 * @class NotificationCenterService
 */
@Injectable()
export class NotificationCenterService {
  /** ログアウトが成功した際のイベント */
  logoutSucceed$: Subject<void>;
  /** オーダ詳細 > エンド情報 の編集処理が終了した際のイベント(流す値はレスポンスのresult) */
  orderEndInfoUpdated$: Subject<boolean>;
  /** オーダ詳細 > 物件・工事情報 の編集処理が終了した際のイベント(流す値はレスポンスのresult)  */
  orderArticleConstructionUpdated$: Subject<boolean>;
  /** オーダ詳細 > 契約情報 の編集処理が終了した際のイベント(流す値はレスポンスのresult)  */
  orderContractInfoUpdated$: Subject<boolean>;
  /** オーダ詳細 > クライアント情報 の案件メール送信が完了した際のイベント  */
  orderSendOfferMailsSuccess$: Subject<void>;
  /** オーダ詳細 > オーダー転送済クライアント情報 の契約登録/解除の処理が完了した際のイベント  */
  orderClientInfoContractUpdateSuccess$: Subject<void>;
  /** オーダー詳細 > アクションの再取得の依頼 */
  fetchOrderActionsInfo$: Subject<void>;
  /** オーダー詳細 > オーダー転送済クライアント情報の再取得の依頼 */
  fetchTransferredOrdersInfo$: Subject<void>;
  /** オーダー詳細 > クライアント情報の再取得の依頼 */
  fetchOrderClientInfos$: Subject<void>;
  /** クライアント詳細 > アクションの再取得の依頼 */
  fetchClientActionsInfo$: Subject<void>;
  /** クライアントポータル オーダー詳細 > アクションの再取得の依頼 */
  fetchClientPortalActionsInfo$: Subject<void>;
  /** オーダー詳細 > 施工後アンケート（口コミ投稿）メール送信 */
  reviewMailsUpdated$: Subject<boolean>;
  /** オーダー詳細 > 施工後アンケート（口コミ投稿）メール送信の再取得の依頼 */
  fetchReviewMailsInfo$: Subject<void>;

  constructor() {
    this.logoutSucceed$ = new Subject<void>();
    this.orderEndInfoUpdated$ = new Subject<null>();
    this.orderArticleConstructionUpdated$ = new Subject<null>();
    this.orderContractInfoUpdated$ = new Subject<null>();
    this.orderSendOfferMailsSuccess$ = new Subject<null>();
    this.orderClientInfoContractUpdateSuccess$ = new Subject<null>();
    this.fetchOrderActionsInfo$ = new Subject<null>();
    this.fetchTransferredOrdersInfo$ = new Subject<null>();
    this.fetchOrderClientInfos$ = new Subject<null>();
    this.fetchClientActionsInfo$ = new Subject<null>();
    this.fetchClientPortalActionsInfo$ = new Subject<null>();
    this.reviewMailsUpdated$ = new Subject<null>();
    this.fetchReviewMailsInfo$ = new Subject<null>();
  }
}
