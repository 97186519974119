import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SsFreezeListComponent } from '@blocks/freeze-list/ss-freeze-list/ss-freeze-list.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SsFreezeListComponent],
  exports: [SsFreezeListComponent],
})
export class SsFreezeListModule {}
