import { ClientInfoButtonStateOriginInterface } from '@app-units/models/client-info-button-state/client-info-button-state-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class ClientInfoButtonState implements OriginWrapper {
  closable: boolean;
  offer_button: boolean;
  order_transfer_button: boolean;
  refuse_button: boolean;
  order_transfer_candidate_count: number;

  private readonly origin: ClientInfoButtonStateOriginInterface;

  get posting_data(): ClientInfoButtonStateOriginInterface {
    return new ClientInfoButtonStateOrigin(
      this.closable,
      this.offer_button,
      this.order_transfer_button,
      this.refuse_button,
      this.order_transfer_candidate_count
    );
  }

  constructor(buttonState?: ClientInfoButtonStateOriginInterface) {
    this.origin = this.createClientInfoInstance(buttonState);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createClientInfoInstance(
    buttonState: ClientInfoButtonStateOriginInterface
  ): ClientInfoButtonStateOrigin {
    return buttonState
      ? new ClientInfoButtonStateOrigin(
          buttonState.closable,
          buttonState.offer_button,
          buttonState.order_transfer_button,
          buttonState.refuse_button,
          buttonState.order_transfer_candidate_count
        )
      : new ClientInfoButtonStateOrigin();
  }

  private initializeProperties(buttonState: ClientInfoButtonStateOrigin) {
    this.closable = buttonState.closable;
    this.offer_button = buttonState.offer_button;
    this.order_transfer_button = buttonState.order_transfer_button;
    this.refuse_button = buttonState.refuse_button;
    this.order_transfer_candidate_count =
      buttonState.order_transfer_candidate_count;
  }
}

class ClientInfoButtonStateOrigin
  implements ClientInfoButtonStateOriginInterface
{
  closable: boolean;
  offer_button: boolean;
  order_transfer_button: boolean;
  refuse_button: boolean;
  order_transfer_candidate_count: number;

  constructor(
    closable?: boolean,
    offer_button?: boolean,
    order_transfer_button?: boolean,
    refuse_button?: boolean,
    order_transfer_candidate_count?: number
  ) {
    this.closable = _.isBoolean(closable) ? closable : null;
    this.offer_button = _.isBoolean(offer_button) ? offer_button : null;
    this.order_transfer_button = _.isBoolean(order_transfer_button)
      ? order_transfer_button
      : null;
    this.refuse_button = _.isBoolean(refuse_button) ? refuse_button : null;
    this.order_transfer_candidate_count = _.isNumber(
      order_transfer_candidate_count
    )
      ? order_transfer_candidate_count
      : null;
  }
}
