import { CompatiblePrefectureOriginInterface } from '@app-units/models/compatible-prefecture/compatible-prefecture-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

/**
 * @classdesc 対応エリア
 * @export
 * @class CompatiblePrefecture
 */
export class CompatiblePrefecture implements OriginWrapper {
  pref_cd: number;
  accept_city_flag: number;
  pref_name: string;
  accept_city_name: string;

  private readonly origin: CompatiblePrefectureOriginInterface;

  get posting_data(): CompatiblePrefectureOriginInterface {
    return new CompatiblePrefectureOrigin(
      this.pref_cd,
      this.accept_city_flag,
      this.pref_name,
      this.accept_city_name
    );
  }

  constructor(compatiblePrefecture?: CompatiblePrefectureOriginInterface) {
    this.origin = this.createClientInfoInstance(compatiblePrefecture);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createClientInfoInstance(
    compatiblePrefecture: CompatiblePrefectureOriginInterface
  ): CompatiblePrefectureOrigin {
    return compatiblePrefecture
      ? new CompatiblePrefectureOrigin(
          compatiblePrefecture.pref_cd,
          compatiblePrefecture.accept_city_flag,
          compatiblePrefecture.pref_name,
          compatiblePrefecture.accept_city_name
        )
      : new CompatiblePrefectureOrigin();
  }

  private initializeProperties(
    compatiblePrefecture: CompatiblePrefectureOrigin
  ) {
    this.pref_cd = compatiblePrefecture.pref_cd;
    this.accept_city_flag = compatiblePrefecture.accept_city_flag;
    this.pref_name = compatiblePrefecture.pref_name;
    this.accept_city_name = compatiblePrefecture.accept_city_name;
  }
}

class CompatiblePrefectureOrigin
  implements CompatiblePrefectureOriginInterface
{
  pref_cd: number;
  accept_city_flag: number;
  pref_name: string;
  accept_city_name: string;

  constructor(
    pref_cd?: number,
    accept_city_flag?: number,
    pref_name?: string,
    accept_city_name?: string
  ) {
    this.pref_cd = _.isNumber(pref_cd) ? pref_cd : null;
    this.accept_city_flag = _.isNumber(accept_city_flag)
      ? accept_city_flag
      : null;
    this.pref_name = pref_name ? pref_name : null;
    this.accept_city_name = accept_city_name ? accept_city_name : null;
  }
}
