import { SmsInfoOriginInterface } from '@app-units/models/sms-info/sms-info-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class SmsInfo implements OriginWrapper {
  private readonly origin: SmsInfoOriginInterface;

  id: string;
  sms_delivery_flag: string;
  sms_delivered_count: string;
  sms_delivery_flag_name: string;
  sms_delivered_pre: string;
  sms_delivery_next: string;

  get posting_data(): SmsInfoOriginInterface {
    return new SmsInfoOrigin(
      Number(this.id),
      Number(this.sms_delivery_flag),
      Number(this.sms_delivered_count),
      this.sms_delivery_flag_name,
      this.sms_delivered_pre,
      this.sms_delivery_next
    );
  }

  constructor(smsInfo?: SmsInfoOriginInterface) {
    this.origin = this.createSmsInfonstance(smsInfo);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createSmsInfonstance(smsInfo: SmsInfoOriginInterface): SmsInfoOrigin {
    return smsInfo
      ? new SmsInfoOrigin(
          smsInfo.id,
          smsInfo.sms_delivery_flag,
          smsInfo.sms_delivered_count,
          smsInfo.sms_delivery_flag_name,
          smsInfo.sms_delivered_pre,
          smsInfo.sms_delivery_next
        )
      : new SmsInfoOrigin();
  }

  private initializeProperties(smsInfo: SmsInfoOrigin) {
    this.id = _.isNumber(smsInfo.id) ? String(smsInfo.id) : null;
    this.sms_delivery_flag = _.isNumber(smsInfo.sms_delivery_flag)
      ? String(smsInfo.sms_delivery_flag)
      : null;
    this.sms_delivered_count = _.isNumber(smsInfo.sms_delivered_count)
      ? String(smsInfo.sms_delivered_count)
      : null;
    this.sms_delivery_flag_name = smsInfo.sms_delivery_flag_name;
    this.sms_delivered_pre = smsInfo.sms_delivered_pre;
    this.sms_delivery_next = smsInfo.sms_delivery_next;
  }
}

class SmsInfoOrigin implements SmsInfoOriginInterface {
  id: number;
  sms_delivery_flag: number;
  sms_delivered_count: number;
  sms_delivery_flag_name: string;
  sms_delivered_pre: string;
  sms_delivery_next: string;

  constructor(
    id?: number,
    sms_delivery_flag?: number,
    sms_delivered_count?: number,
    sms_delivery_flag_name?: string,
    sms_delivered_pre?: string,
    sms_delivery_next?: string
  ) {
    this.id = _.isNumber(id) ? id : null;
    this.sms_delivery_flag = _.isNumber(sms_delivery_flag)
      ? sms_delivery_flag
      : null;
    this.sms_delivered_count = _.isNumber(sms_delivered_count)
      ? sms_delivered_count
      : null;
    this.sms_delivery_flag_name = sms_delivery_flag_name
      ? sms_delivery_flag_name
      : null;
    this.sms_delivered_pre = sms_delivered_pre ? sms_delivered_pre : null;
    this.sms_delivery_next = sms_delivery_next ? sms_delivery_next : null;
  }
}
