import { Media } from '@app-units/models/media/media';
import { MediaOriginInterface } from '@app-units/models/media/media-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';
import { ContentOriginInterface } from './content-origin';

/**
 * @classdesc コンテンツ情報
 * @export
 * @class Content
 */
export class Content extends SsSelective implements OriginWrapper {
  private readonly origin: ContentOriginInterface;

  id: string;
  daihyousya: string;
  tantousya: string;
  assignee_upload_file_id: number;
  assignee_name: string;
  assignee_tel1: string;
  assignee_tel2: string;
  site_url: string;
  seturitubi: string;
  jigyounaiyou: string;
  media: Media;
  pr_head: string;
  pr_body: string;

  get posting_data(): ContentOriginInterface {
    return new ContentOrigin(
      parseInt(this.id),
      this.daihyousya,
      this.tantousya,
      this.assignee_upload_file_id,
      this.assignee_name,
      this.assignee_tel1,
      this.assignee_tel2,
      this.site_url,
      this.seturitubi,
      this.jigyounaiyou,
      this.media.posting_data,
      this.pr_head,
      this.pr_body
    );
  }

  constructor(content?: ContentOriginInterface) {
    super();
    this.origin = this.createContentInstance(content);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createContentInstance(
    content: ContentOriginInterface
  ): ContentOriginInterface {
    return content
      ? new ContentOrigin(
          content.id,
          content.daihyousya,
          content.tantousya,
          content.assignee_upload_file_id,
          content.assignee_name,
          content.assignee_tel1,
          content.assignee_tel2,
          content.site_url,
          content.seturitubi,
          content.jigyounaiyou,
          content.media,
          content.pr_head,
          content.pr_body
        )
      : new ContentOrigin();
  }

  private initializeProperties(content: ContentOriginInterface) {
    this.id = content.id ? String(content.id) : null;
    this.daihyousya = content.daihyousya;
    this.tantousya = content.tantousya;
    this.assignee_upload_file_id = content.assignee_upload_file_id;
    this.assignee_name = content.assignee_name;
    this.assignee_tel1 = content.assignee_tel1;
    this.assignee_tel2 = content.assignee_tel2;
    this.site_url = content.site_url;
    this.seturitubi = content.seturitubi;
    this.jigyounaiyou = content.jigyounaiyou;
    this.media = content.media ? new Media(content.media) : new Media();
    this.pr_head = content.pr_head;
    this.pr_body = content.pr_body;
  }
}

class ContentOrigin implements ContentOriginInterface {
  id: number;
  daihyousya: string;
  tantousya: string;
  assignee_upload_file_id: number;
  assignee_name: string;
  assignee_tel1: string;
  assignee_tel2: string;
  site_url: string;
  seturitubi: string;
  jigyounaiyou: string;
  media: MediaOriginInterface;
  pr_head: string;
  pr_body: string;

  constructor(
    id?: number,
    daihyousya?: string,
    tantousya?: string,
    assignee_upload_file_id?: number,
    assignee_name?: string,
    assignee_tel1?: string,
    assignee_tel2?: string,
    site_url?: string,
    seturitubi?: string,
    jigyounaiyou?: string,
    media?: MediaOriginInterface,
    pr_head?: string,
    pr_body?: string
  ) {
    this.id = id ? id : null;
    this.daihyousya = daihyousya ? daihyousya : null;
    this.tantousya = tantousya ? tantousya : null;
    this.assignee_upload_file_id = _.isNumber(assignee_upload_file_id)
      ? assignee_upload_file_id
      : null;
    this.assignee_name = assignee_name ? assignee_name : null;
    this.assignee_tel1 = assignee_tel1 ? assignee_tel1 : null;
    this.assignee_tel2 = assignee_tel2 ? assignee_tel2 : null;
    this.site_url = site_url ? site_url : null;
    this.seturitubi = seturitubi ? seturitubi : null;
    this.jigyounaiyou = jigyounaiyou ? jigyounaiyou : null;
    this.media = media ? media : null;
    this.pr_head = pr_head ? pr_head : null;
    this.pr_body = pr_body ? pr_body : null;
  }
}
