import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { ChargeDetailResult } from '@app-units/models/charge-detail-result/charge-detail-result';
import { ChargeDetailResultOriginInterface } from '@app-units/models/charge-detail-result/charge-detail-result-origin';
import { TransferredOrder } from '@app-units/models/transferred-order/transferred-order';
import { TransferredOrderOriginInterface } from '@app-units/models/transferred-order/transferred-order-origin';
import { SsUrlSearchParamsService } from '@blocks/utils/ss-url-search-params/ss-url-search-params.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class TransferredOrdersHttpService {
  private get resource(): string {
    return '/orders';
  }

  constructor(
    private http: HttpClient,
    private ssUrlSearchParams: SsUrlSearchParamsService
  ) {}

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * - https://v5.angular.io/tutorial/toh-pt6#final-code-review
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: 適切なエラーの投げ場所を見つけたら、差し替える
      console.error(error); // log to console instead
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /* オーダー転送済クライアント情報の一覧取得 */
  fetchItems(
    orderId: string,
    order?: string
  ): Observable<app.response<TransferredOrder[]>> {
    const search = order ? { order: order } : {};
    const params = this.ssUrlSearchParams.parseParams(search);
    return this.http
      .get(`${this.resource}/${orderId}/transfered_orders`, { params: params })
      .pipe(map(JsonConverter.toTransferredOrder));
  }

  /* オーダー転送済クライアント情報の編集 */
  saveItem(
    orderId: string,
    data: TransferredOrderOriginInterface
  ): Observable<app.response<TransferredOrder[]>> {
    return this.http
      .put(
        `${this.resource}/${orderId}/client_infos/${data.client_charge_result_id}`,
        data
      )
      .pipe(map(JsonConverter.toTransferredOrder));
  }

  /* オーダー転送済クライアント情報の契約登録/解除 */
  updateClientInfoContract(
    orderId: string,
    partnerId: string,
    contracted: boolean
  ): Observable<app.response<{ contracted: boolean }>> {
    const url = `${this.resource}/${orderId}/client_info_contract/${partnerId}`;
    return this.http
      .put<app.response<any>>(url, { contracted: contracted })
      .pipe(catchError(this.handleError<app.response<any>>('deleteItem')));
  }

  /* 課金調査 詳細記録の取得 */
  fetchChargeDetailResult(
    orderId: string
  ): Observable<app.response<ChargeDetailResult>> {
    return this.http
      .get(`${this.resource}/${orderId}/charge_detail_result`)
      .pipe(map(JsonConverter.toChargeDetailResult));
  }

  /* 課金調査 詳細記録の編集 */
  updateChargeDetailResult(
    orderId: string,
    data: ChargeDetailResultOriginInterface
  ): Observable<app.response<ChargeDetailResult>> {
    return this.http
      .put(`${this.resource}/${orderId}/charge_detail_result`, data)
      .pipe(map(JsonConverter.toChargeDetailResult));
  }
}
