import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { ContractInfo } from '@app-units/models/contract-info/contract-info';
import { ContractInfoOriginInterface } from '@app-units/models/contract-info/contract-info-origin';
import { EndInfo } from '@app-units/models/end-info/end-info';
import { EndInfoOriginInterface } from '@app-units/models/end-info/end-info-origin';
import { Observable } from 'rxjs';

@Injectable()
export class ContractInfoHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(id: string): Observable<app.response<ContractInfo>> {
    return this.http
      .get(this.getUrl(id))
      .pipe(map(JsonConverter.toContractInfo));
  }

  saveItem(
    item: ContractInfoOriginInterface
  ): Observable<app.response<ContractInfo>> {
    return this.http
      .put(this.getUrl(String(item.id)), item)
      .pipe(map(JsonConverter.toContractInfo));
  }

  private getUrl(id: string) {
    return `/orders/${id}/contract_info`;
  }
}
