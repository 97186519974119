import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SsListLimitComponent } from '@blocks/pagination/ss-list-limit/ss-list-limit.component';
import { SsPaginationInfoComponent } from '@blocks/pagination/ss-pagination-info/ss-pagination-info.component';
import { SsPaginationComponent } from '@blocks/pagination/ss-pagination/ss-pagination.component';
import { SsPaginationConfig } from '@blocks/pagination/ss-pagination/ss-pagination.config';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [CommonModule, FormsModule, BsDropdownModule],
  declarations: [
    SsPaginationComponent,
    SsPaginationInfoComponent,
    SsListLimitComponent,
  ],
  exports: [
    FormsModule,
    SsPaginationComponent,
    SsPaginationInfoComponent,
    SsListLimitComponent,
  ],
})
export class SsPaginationModule {
  static forRoot(): ModuleWithProviders<SsPaginationModule> {
    return {
      ngModule: SsPaginationModule,
      providers: [SsPaginationConfig],
    };
  }
}
