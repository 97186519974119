export class AddressNote {
  /** 郵便番号上3桁 */
  upperPostCode: string;
  /** 郵便番号下4桁 */
  lowerPostCode: string;
  /** 都道府県コード(JIS X 0401) */
  prefectureCode: number;
  /** 都道府県名 */
  prefecture: string;
  /** 市区町村 */
  locality: string;
  /** 番地名 */
  street: string;
  /** 番地 */
  streetNumber: string;
  /** ビル名（付属情報） */
  buildingName: string;

  /** 郵便番号7桁 */
  get postCode(): string {
    return this.upperPostCode + this.lowerPostCode;
  }

  /** 郵便番号7桁ハイフン付き */
  get postCodeWithHyphen(): string {
    return this.upperPostCode + '-' + this.lowerPostCode;
  }

  /** 全住所 */
  get fullAddress(): string {
    return this.prefecture + this.locality + this.streetAddressWithBuildingName;
  }

  /** 番地名以下住所 */
  get streetAddress(): string {
    return this.street + this.streetNumber;
  }

  /** 番地名以下住所ビル名付き */
  get streetAddressWithBuildingName(): string {
    return this.streetAddress + this.buildingName;
  }

  constructor(
    postCode1?: string,
    postCode2?: string,
    prefectureCode?: number,
    prefecture?: string,
    locality?: string,
    street?: string,
    streetNumber?: string,
    buildingName?: string
  ) {
    this.upperPostCode = postCode1 ? postCode1 : '';
    this.lowerPostCode = postCode2 ? postCode2 : '';
    this.prefectureCode = prefectureCode ? prefectureCode : null;
    this.prefecture = prefecture ? prefecture : '';
    this.locality = locality ? locality : '';
    this.street = street ? street : '';
    this.streetNumber = streetNumber ? streetNumber : '';
    this.buildingName = buildingName ? buildingName : '';
  }
}
