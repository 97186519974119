import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';

import { ISsConstant, SS_CONSTANT } from '@ss-core/constants';
import { AuthService } from '@ss-core/services';

/**
 * @classdesc ログインページComponent
 * @export
 * @class LoginComponent
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  /** プロジェクト名 */
  title: string;
  form: FormGroup;
  bodyClassName = 'l-login';
  // チラッと表示されないように初期値をtrueにしておく
  isPrivate: boolean = true;

  constructor(
    @Inject(SS_CONSTANT) SS_CONSTANT: ISsConstant,
    @Inject(DOCUMENT) document,
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.addBodyClass();

    // 定数からprojectのtitleを取得
    this.title = SS_CONSTANT.PROJECT_TITLE;

    // FormGroup作成
    this.form = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    /**
     * 管理画面かクライアントポータルか判定
     * - true: manage
     * - false: client portal
     * - /api/app_modeから取得
     */
    type Response = {
      data: { is_private: boolean };
    };
    this.http
      .get<Response>('/app_mode')
      .pipe(map((res) => res.data.is_private ?? true))
      .subscribe((res) => (this.isPrivate = res));
  }

  ngOnInit() {
    /**
     * ログイン状態ならページ遷移する
     * @todo this.authService.isAuthenticated() だと常にfalseだった;
     */
    this.authService.fetchUser().subscribe({
      next: (response) => {
        if (response.data.id) {
          this.router.navigate([''], { replaceUrl: true });
        }
      },
      error: () => {
        // ErrorでもSentryダイアログを起動しない
      },
    });
  }

  ngOnDestroy() {
    this.removeBodyClass();
  }

  /**
   * ログイン処理
   */
  onSubmit() {
    const { value, valid } = this.form;
    this.authService
      .login(value)
      .pipe(filter((res) => res.result))
      .subscribe((res) => location.reload());
  }

  /**
   * bodyにログインページ専用class名を追加
   */
  addBodyClass() {
    document.body.classList.add(this.bodyClassName);
  }

  /**
   * bodyからログインページ専用class名を削除
   */
  removeBodyClass() {
    document.body.className = document.body.className.replace(
      this.bodyClassName,
      ''
    );
  }
}
