import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { ReviewOrderClientInfo } from '@app-units/models/review/review-order-client-info';
import { Observable } from 'rxjs';

@Injectable()
export class ReviewOrderClientInfoHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(id: string): Observable<app.response<ReviewOrderClientInfo>> {
    return this.http
      .get(this.getUrl(id))
      .pipe(map(JsonConverter.toReviewOrderClientInfo));
  }

  saveItem(
    item: ReviewOrderClientInfo
  ): Observable<app.response<ReviewOrderClientInfo>> {
    return this.http
      .put(this.getUrl(String(item.id)), item)
      .pipe(map(JsonConverter.toReviewOrderClientInfo));
  }

  private getUrl(id: string) {
    return `/reviews/${id}/order_and_client_info`;
  }
}
