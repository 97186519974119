import { ContractOriginInterface } from '@app-units/models/contract/contract-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

/**
 * @classdesc
 * @export
 * @class Contract
 */
export class Contract extends SsSelective implements OriginWrapper {
  private readonly origin: ContractOriginInterface;

  id: string;
  contract_type: number;
  invisible_on_map: number;
  order_price: number;
  max_order_transfer: number;
  commission_rate: number;
  commission_rate_memo: string;

  get posting_data(): ContractOriginInterface {
    return new ContractOrigin(
      parseInt(this.id),
      this.contract_type,
      this.invisible_on_map,
      this.order_price,
      this.max_order_transfer,
      this.commission_rate,
      this.commission_rate_memo
    );
  }

  constructor(contract?: ContractOriginInterface) {
    super();
    this.origin = this.createContractInstance(contract);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createContractInstance(
    contract: ContractOriginInterface
  ): ContractOrigin {
    return contract
      ? new ContractOrigin(
          contract.id,
          contract.contract_type,
          contract.invisible_on_map,
          contract.order_price,
          contract.max_order_transfer,
          contract.commission_rate,
          contract.commission_rate_memo
        )
      : new ContractOrigin();
  }

  private initializeProperties(contract: ContractOrigin) {
    this.id = contract.id ? String(contract.id) : null;
    this.contract_type = contract.contract_type;
    this.invisible_on_map = contract.invisible_on_map;
    this.order_price = contract.order_price;
    this.max_order_transfer = contract.max_order_transfer;
    this.commission_rate = contract.commission_rate;
    this.commission_rate_memo = contract.commission_rate_memo;
  }
}

class ContractOrigin implements ContractOriginInterface {
  id: number;
  contract_type: number;
  invisible_on_map: number;
  order_price: number;
  max_order_transfer: number;
  commission_rate: number;
  commission_rate_memo: string;

  constructor(
    id?: number,
    contract_type?: number,
    invisible_on_map?: number,
    order_price?: number,
    max_order_transfer?: number,
    commission_rate?: number,
    commission_rate_memo?: string
  ) {
    this.id = id ? id : null;
    this.contract_type = _.isNumber(contract_type) ? contract_type : null;
    this.invisible_on_map = _.isNumber(invisible_on_map)
      ? invisible_on_map
      : null;
    this.order_price = _.isNumber(order_price) ? order_price : null;
    this.max_order_transfer = _.isNumber(max_order_transfer)
      ? max_order_transfer
      : null;
    this.commission_rate = _.isNumber(commission_rate) ? commission_rate : null;
    this.commission_rate_memo = commission_rate_memo
      ? commission_rate_memo
      : null;
  }
}
