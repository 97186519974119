/**
 * ReviewモデルとなるClass
 * - APIの戻り値をプロパティにセットする
 * - フィールドは必ず存在するものとする（Optionalにはならない）
 * - 既定メンバでないプロパティは生成しない
 * - Non-null
 *
 * @example const data = Review.initData(json.data)
 * @example {{ review.order_id }}
 */
export class Review {
  /** @todo SsSelectiveではid:stringだった。stringである意味があるか？ */
  id: number;
  order_id: number;
  /** 投稿日 */
  posted_datetime: string;
  /** クライアント */
  client_id: number;
  client_name: string;
  /** エンド名 */
  reviewer_name: string;
  reviewer_era_id: number;
  reviewer_era_name: string;
  reviewer_gender_id: number;
  reviewer_gender_name: string;
  /** */
  construction_count: number;
  construction_count_name: number;
  construction_type_ids: number[];
  construction_start_date: string;
  construction_period: string;
  construction_price: string;
  /** */
  general_evalution_id: number;
  general_evalution_comment: string | null;
  /** */
  staff_evalution_id: number;
  staff_evalution_comment: string | null;
  /** */
  vision_evalution_id: number;
  vision_evalution_comment: string | null;
  /** */
  suggestion_evalution_id: number;
  suggestion_evalution_comment: string | null;
  /** */
  satisfaction_evalution_id: number;
  satisfaction_evalution_comment: string | null;
  /** */
  price_evalution_id: number;
  price_evalution_comment: string | null;
  /** */
  after_service_evalution_id: number;
  after_service_evalution_comment: string | null;
  /** */
  before_image_url: string;
  after_image_url: string;
  /** ギフト券 */
  reward_send_date: string | null;
  reward_price_id: number | null;
  reward_price_name: string | null;
  /** */
  management_memo: string | null;
  /** 承認設定 */
  approval_status: number;
  approval_status_name: string;
  /** 表示設定 */
  show_on_public_client_page: number;
  show_on_public_client_page_name: string;
  /** ラベル */
  label: string;

  constructor(data) {
    this.id = data.id;
    this.order_id = data.order_id;
    this.posted_datetime = data.posted_datetime;
    this.client_id = data.client_id;
    this.client_name = data.client_name;
    this.reviewer_name = data.reviewer_name;
    this.reviewer_era_id = data.reviewer_era_id;
    this.reviewer_era_name = data.reviewer_era_name;
    this.reviewer_gender_id = data.reviewer_gender_id;
    this.reviewer_gender_name = data.reviewer_gender_name;
    this.construction_count = data.construction_count;
    this.construction_count_name = data.construction_count_name;
    this.construction_type_ids = data.construction_type_ids;
    this.construction_start_date = data.construction_start_date;
    this.construction_period = data.construction_period;
    this.construction_price = data.construction_price;
    this.general_evalution_id = data.general_evalution_id;
    this.general_evalution_comment = data.general_evalution_comment;
    this.staff_evalution_id = data.staff_evalution_id;
    this.staff_evalution_comment = data.staff_evalution_comment;
    this.vision_evalution_id = data.vision_evalution_id;
    this.vision_evalution_comment = data.vision_evalution_comment;
    this.suggestion_evalution_id = data.suggestion_evalution_id;
    this.suggestion_evalution_comment = data.suggestion_evalution_comment;
    this.satisfaction_evalution_id = data.satisfaction_evalution_id;
    this.satisfaction_evalution_comment = data.satisfaction_evalution_comment;
    this.price_evalution_id = data.price_evalution_id;
    this.price_evalution_comment = data.price_evalution_comment;
    this.after_service_evalution_id = data.after_service_evalution_id;
    this.after_service_evalution_comment = data.after_service_evalution_comment;
    this.before_image_url = data.before_image_url;
    this.after_image_url = data.after_image_url;
    this.reward_send_date = data.reward_send_date;
    this.reward_price_id = data.reward_price_id;
    this.reward_price_name = data.reward_price_name;
    this.management_memo = data.management_memo;
    /** 承認設定 */
    this.approval_status = data.approval_status;
    this.approval_status_name = data.approval_status_name;
    /** 表示設定 */
    this.show_on_public_client_page = data.show_on_public_client_page;
    this.show_on_public_client_page_name = data.show_on_public_client_page_name;
    this.label = data.label;
  }

  /**
   * nullを初期値に変換して、コンストラクタに渡す
   */
  static initData(data: Review) {
    let procData = {} as any;
    Object.entries(data).forEach(([key, val]) => {
      switch (key) {
        // number | nullのプロパティ
        case 'reward_price_id':
          procData[`${key}`] = val ?? 0;
          return;

        // Nullを返すメンバの大部分は string | null
        default:
          procData[`${key}`] = val ?? '';
          return;
      }
    });

    return new Review(procData as Review);
  }
}
