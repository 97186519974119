import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClient,
  HttpHandler,
  HttpClientModule,
} from '@angular/common/http';
import { SsToastrModule } from '@blocks/toastr/ss-toastr.module';
import { SsUtilsModule } from '@blocks/utils/ss-utils.module';
import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';

// services
import { RoleGuard } from '@ss-core/services/role-guard/role-guard';
import { SsRouterService } from '@ss-core/services/ss-router/ss-router.service';
import { AuthGuard, AuthService } from './services';

// constants
import { DeletedItemGuard } from '@ss-core/services/deleted-item/deleted-item-guard';
import { DeletedItemService } from '@ss-core/services/deleted-item/deleted-item.service';
import { ISsConstant, SS_CONSTANT, SsConstant } from './constants';

// CORE_PROVIDERS
const CORE_PROVIDERS = [
  // アプリ内の定数
  {
    provide: SS_CONSTANT,
    useValue: SsConstant,
  },
  // 認証系サービス
  AuthService,
  // ページ遷移でのアクセス制御
  AuthGuard,
  // ページ遷移での権限制御
  RoleGuard,
  // 削除済みItem確認系サービス
  DeletedItemService,
  // ページ遷移での削除済み詳細制御
  DeletedItemGuard,
  // Cokkie管理サービス
  CookieService,
  // Routing管理サービス
  SsRouterService,
];

/**
 * @classdesc AppModuleとPublicAppModuleどちらでも使いたいproviderやModuleを入れるmodule
 * @desc
 * ・AppModuleとPublicAppModuleでのみimport
 * ・その他のmoduleではimportしてはいけない
 * @export
 * @class SsCoreModule
 */
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SsToastrModule,
    SsUtilsModule.forRoot(),
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SsToastrModule,
    SsUtilsModule,
  ],
})
export class SsCoreModule {
  static forRoot(): ModuleWithProviders<SsCoreModule> {
    return {
      ngModule: SsCoreModule,
      providers: [...CORE_PROVIDERS],
    };
  }
}
