import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from '../../../../../../node_modules/rxjs';
import { HttpClient } from '@angular/common/http';
import { Progress } from '@app-units/models/progress/progress';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { ProgressOriginInterface } from '@app-units/models/progress/progress-origin';

@Injectable()
export class ProgressHttpService {
  constructor(private http: HttpClient) {}

  fetchItem(id: string): Observable<app.response<Progress>> {
    return this.http.get(this.getUrl(id)).pipe(map(JsonConverter.toProgress));
  }

  saveItem(item: ProgressOriginInterface): Observable<app.response<Progress>> {
    return this.http
      .put(this.getUrl(String(item.id)), item)
      .pipe(map(JsonConverter.toProgress));
  }

  private getUrl(id: string) {
    return `/orders/${id}/progress`;
  }
}
