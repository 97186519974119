import { ContractInfoOriginInterface } from '@app-units/models/contract-info/contract-info-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

/**
 * @classdesc 契約情報
 * @export
 * @class ContractInfo
 */
export class ContractInfo extends SsSelective implements OriginWrapper {
  private readonly origin: ContractInfoOriginInterface;

  id: string;
  detection_route: number;
  detection_route_name: string;
  stop_haihaimail: number;
  stop_haihaimail_name: string;
  contract_date: string;
  confirm_contract_date: string;
  contract_money: number;
  commission_rate: number;
  next_demand_date: string;
  auto_send_nextdemand: number;
  complete_date: string;
  invoiced_date: string;
  deposit_date: string;
  client_name: string;

  get posting_data(): ContractInfoOriginInterface {
    return new ContractInfoOrigin(
      parseInt(this.id),
      this.detection_route,
      this.detection_route_name,
      this.stop_haihaimail,
      this.stop_haihaimail_name,
      this.contract_date,
      this.confirm_contract_date,
      this.contract_money,
      this.commission_rate,
      this.next_demand_date,
      this.auto_send_nextdemand,
      this.complete_date,
      this.invoiced_date,
      this.deposit_date,
      this.client_name
    );
  }

  constructor(contractInfo?: ContractInfoOriginInterface) {
    super();
    this.origin = this.createContractInfoInstance(contractInfo);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createContractInfoInstance(
    contractInfo: ContractInfoOriginInterface
  ): ContractInfoOrigin {
    return contractInfo
      ? new ContractInfoOrigin(
          contractInfo.id,
          contractInfo.detection_route,
          contractInfo.detection_route_name,
          contractInfo.stop_haihaimail,
          contractInfo.stop_haihaimail_name,
          contractInfo.contract_date,
          contractInfo.confirm_contract_date,
          contractInfo.contract_money,
          contractInfo.commission_rate,
          contractInfo.next_demand_date,
          contractInfo.auto_send_nextdemand,
          contractInfo.complete_date,
          contractInfo.invoiced_date,
          contractInfo.deposit_date,
          contractInfo.client_name
        )
      : new ContractInfoOrigin();
  }

  private initializeProperties(contractInfo: ContractInfoOrigin) {
    this.id = contractInfo.id ? String(contractInfo.id) : null;
    this.detection_route = _.isNumber(contractInfo.detection_route)
      ? contractInfo.detection_route
      : null;
    this.detection_route_name = contractInfo.detection_route_name
      ? contractInfo.detection_route_name
      : null;
    this.stop_haihaimail = _.isNumber(contractInfo.stop_haihaimail)
      ? contractInfo.stop_haihaimail
      : null;
    this.stop_haihaimail_name = contractInfo.stop_haihaimail_name
      ? contractInfo.stop_haihaimail_name
      : null;
    this.contract_date = contractInfo.contract_date
      ? contractInfo.contract_date
      : null;
    this.confirm_contract_date = contractInfo.confirm_contract_date
      ? contractInfo.confirm_contract_date
      : null;
    this.contract_money = _.isNumber(contractInfo.contract_money)
      ? contractInfo.contract_money
      : null;
    this.commission_rate = _.isNumber(contractInfo.commission_rate)
      ? contractInfo.commission_rate
      : null;
    this.next_demand_date = contractInfo.next_demand_date
      ? contractInfo.next_demand_date
      : null;
    this.auto_send_nextdemand = _.isNumber(contractInfo.auto_send_nextdemand)
      ? contractInfo.auto_send_nextdemand
      : null;
    this.complete_date = contractInfo.complete_date
      ? contractInfo.complete_date
      : null;
    this.invoiced_date = contractInfo.invoiced_date
      ? contractInfo.invoiced_date
      : null;
    this.deposit_date = contractInfo.deposit_date
      ? contractInfo.deposit_date
      : null;
    this.client_name = contractInfo.client_name
      ? contractInfo.client_name
      : null;
  }
}

class ContractInfoOrigin implements ContractInfoOriginInterface {
  id: number;
  detection_route: number;
  detection_route_name: string;
  stop_haihaimail: number;
  stop_haihaimail_name: string;
  contract_date: string;
  confirm_contract_date: string;
  contract_money: number;
  commission_rate: number;
  next_demand_date: string;
  auto_send_nextdemand: number;
  complete_date: string;
  invoiced_date: string;
  deposit_date: string;
  client_name: string;

  constructor(
    id?: number,
    detection_route?: number,
    detection_route_name?: string,
    stop_haihaimail?: number,
    stop_haihaimail_name?: string,
    contract_date?: string,
    confirm_contract_date?: string,
    contract_money?: number,
    commission_rate?: number,
    next_demand_date?: string,
    auto_send_nextdemand?: number,
    complete_date?: string,
    invoiced_date?: string,
    deposit_date?: string,
    client_name?: string
  ) {
    this.id = id ? id : null;
    this.detection_route = _.isNumber(detection_route) ? detection_route : null;
    this.detection_route_name = detection_route_name
      ? detection_route_name
      : null;
    this.stop_haihaimail = _.isNumber(stop_haihaimail) ? stop_haihaimail : null;
    this.stop_haihaimail_name = stop_haihaimail_name
      ? stop_haihaimail_name
      : null;
    this.contract_date = contract_date ? contract_date : null;
    this.confirm_contract_date = confirm_contract_date
      ? confirm_contract_date
      : null;
    this.contract_money = _.isNumber(contract_money) ? contract_money : null;
    this.commission_rate = _.isNumber(commission_rate) ? commission_rate : null;
    this.next_demand_date = next_demand_date ? next_demand_date : null;
    this.auto_send_nextdemand = _.isNumber(auto_send_nextdemand)
      ? auto_send_nextdemand
      : null;
    this.complete_date = complete_date ? complete_date : null;
    this.invoiced_date = invoiced_date ? invoiced_date : null;
    this.deposit_date = deposit_date ? deposit_date : null;
    this.client_name = client_name ? client_name : null;
  }
}
