import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { ClientInfoButtonState } from '@app-units/models/client-info-button-state/client-info-button-state';
import { ClientInfo } from '@app-units/models/client-info/client-info';
import { SsUrlSearchParamsService } from '@blocks/utils/ss-url-search-params/ss-url-search-params.service';
import { Observable, of } from 'rxjs';

export class ClientInfoSearchParams {
  /* ページ数 */
  page?: string = null;
  /* ソート */
  order?: string = null;
  /* 表示件数 */
  limit?: string = null;
  /* クライアント名 */
  partner_name?: string = null;
  /* 紹介日（開始）*/
  introduce_date_start?: string = null;
  /* 紹介日（終了）*/
  introduce_date_end?: string = null;
  /* 申込状況 */
  _client_reactions?: string[] = null;
  /* 紹介種別 */
  _introduction_types?: string[] = null;
  /* オーダー転送 */
  _order_transfer_statuses?: string[] = null;
  /* 成約率（開始）*/
  contract_rate_start?: string = null;
  /* 成約率（終了）*/
  contract_rate_end?: string = null;
  /* 最終案件紹介日（開始）*/
  last_introduce_date_start?: string = null;
  /* 最終案件紹介日（終了）*/
  last_introduce_date_end?: string = null;
  /* オーダー転送候補 */
  is_order_transfer_candidate?: boolean = null;
}

@Injectable()
export class ClientInfoHttpService {
  constructor(
    private http: HttpClient,
    private ssUrlSearchParams: SsUrlSearchParamsService
  ) {}

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * - https://v5.angular.io/tutorial/toh-pt6#final-code-review
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: 適切なエラーの投げ場所を見つけたら、差し替える
      console.error(error); // log to console instead
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  fetchItems(
    orderId: string,
    search: ClientInfoSearchParams
  ): Observable<app.response<ClientInfo[]>> {
    const params = this.ssUrlSearchParams.parseParams(search);
    return this.http
      .get(this.getUrl(orderId), { params: params })
      .pipe(map(JsonConverter.toClientInfo));
  }

  fetchTransferredOrderItems(
    orderId: string,
    search: ClientInfoSearchParams
  ): Observable<app.response<ClientInfo[]>> {
    const params = this.ssUrlSearchParams.parseParams(search);
    return this.http
      .get(`/orders/${orderId}/transfered_orders`, { params: params })
      .pipe(map(JsonConverter.toClientInfo));
  }

  fetchMinimunItems(
    orderId: string,
    search: ClientInfoSearchParams
  ): Observable<app.response<ClientInfo[]>> {
    /** 30件取得 */
    const searchParams: ClientInfoSearchParams = Object.assign({}, search, {
      limit: '30',
    });
    const params = this.ssUrlSearchParams.parseParams(searchParams);
    return this.http
      .get(this.getUrl(orderId), { params: params })
      .pipe(map(JsonConverter.toClientInfo));
  }

  addItems(
    orderId: string,
    data: { client_id: string[] }
  ): Observable<app.response<any>> {
    const url = this.getUrl(orderId);
    return this.http
      .post<app.response<any>>(url, data)
      .pipe(catchError(this.handleError<app.response<any>>('addItem')));
  }

  updateTransferCandidate(
    orderId: string,
    clientId: string,
    isOrderTransferCandidate: boolean
  ): Observable<app.response<ClientInfo>> {
    const postData = {
      client_id: clientId,
      is_order_transfer_candidate: isOrderTransferCandidate,
    };
    return this.http
      .put(this.getUrl(orderId) + '/order_transfer_candidate', postData)
      .pipe(map(JsonConverter.toClientInfo));
  }

  updateClientReaction(
    orderId: string,
    clientId: string,
    clientReaction: string
  ): Observable<app.response<ClientInfo>> {
    const postData = { client_id: clientId, client_reaction: clientReaction };
    return this.http
      .put(this.getUrl(orderId) + '/client_reaction', postData)
      .pipe(map(JsonConverter.toClientInfo));
  }

  updateIntroductionType(
    orderId: string,
    clientId: string,
    introductionType: string
  ): Observable<app.response<ClientInfo>> {
    const postData = {
      client_id: clientId,
      introduction_type: introductionType,
    };
    return this.http
      .put(this.getUrl(orderId) + '/introduction_type', postData)
      .pipe(map(JsonConverter.toClientInfo));
  }

  updateOrderTransferStatus(
    orderId: string,
    clientId: string,
    orderTransferStatus: string
  ): Observable<app.response<ClientInfo>> {
    const postData = {
      client_id: clientId,
      order_transfer_status: orderTransferStatus,
    };
    return this.http
      .put(this.getUrl(orderId) + '/order_transfer_status', postData)
      .pipe(map(JsonConverter.toClientInfo));
  }

  updateOrderPrice(
    orderId: string,
    clientId: string,
    orderPrice: string
  ): Observable<app.response<ClientInfo>> {
    const data = { client_id: clientId, order_price: orderPrice };
    return this.http
      .put(this.getUrl(orderId) + '/order_price', data)
      .pipe(map(JsonConverter.toClientInfo));
  }

  fetchButtonState(
    orderId: string
  ): Observable<app.response<ClientInfoButtonState>> {
    return this.http
      .get(this.getUrl(orderId) + '/button_state')
      .pipe(map(JsonConverter.toClientInfoButtonState));
  }

  orderTransfer(
    orderId: string,
    data: { add_flag: string }
  ): Observable<app.response<any>> {
    const url = `/orders/${orderId}/order_transfer`;
    return this.http
      .post<app.response<any>>(url, data)
      .pipe(catchError(this.handleError<app.response<any>>('orderTransfer')));
  }

  private getUrl(id: string) {
    return `/orders/${id}/client_infos`;
  }
}
