import { Component, Inject, OnDestroy } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { IRoutePaths, ROUTES_CONSTANT } from '@app-core/constants';
import { AuthService } from '@ss-core/services';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { jaLocale } from 'ngx-bootstrap/locale';
import { SubscriptionLike as ISubscription } from 'rxjs';

/**
 * @classdesc トップレベルcomponent
 * @export
 * @class AppComponent
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  isAuthenticated: boolean;
  isHiddenHeaderMenu: boolean = false;

  private subscriptions: ISubscription[] = [];

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(ROUTES_CONSTANT) private ROUTES_CONSTANT: IRoutePaths
  ) {
    // ngx-bootstrapをローカライズ
    defineLocale('ja', jaLocale);

    // ログイン認証完了イベントを購読
    authService.fetchedAuthUser.subscribe(this.onChangeAuth.bind(this));

    this.subscriptions.push(
      this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationStart) {
          this.isHiddenHeaderMenu =
            evt.url.indexOf(this.ROUTES_CONSTANT.ORDER_CLIENT_ADD.path) !== -1;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }

  /**
   * ログイン認証が変更された時の処理
   */
  onChangeAuth() {
    this.isAuthenticated = this.authService.isAuthenticated();
  }

  /**
   * router-outletにComponentを差し込む時に呼ばれる
   */
  onActivate() {
    window.scrollTo(0, 0);
  }
}
