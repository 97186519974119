import { Injectable } from '@angular/core';

@Injectable()
export class SsPaginationConfig {
  public main = {
    firstText: '最初',
    previousText: '<i class="icomoon-arrow-left"></i>',
    nextText: '<i class="icomoon-arrow-right"></i>',
    lastText: '最後',
  };
}
