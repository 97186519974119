import { ClientOriginInterface } from '@app-units/models/client/client-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

/**
 * @classdesc クライアント情報
 * @export
 * @class Client
 */
export class Client extends SsSelective implements OriginWrapper {
  id: string;
  name: string;
  address: string;
  tel: string;
  email: string;
  intro_count: string;
  normal_count: string;
  advance_count: string;
  exchange_count: string;
  combi_count: string;
  order_price: string;
  contract_count: string;
  cancel_count: string;
  contract_rate: number;
  auto_rank: string;
  max_order_transfer: string;
  commission_rate: number;
  attention_type: string;
  attention_type_name: string;
  memo_about_partner: string;
  prefecture: string;
  tantousya: string;
  distance: string;
  contract_type: string;
  contract_type_name: string;
  last_offered_date: string;
  memo: string;
  client_memo_id: string;
  partner_cd: string;
  delivery_status_name: string;
  assignee_tel1: string;
  assignee_tel2: string;
  /** クリップボードへコピー完了した旨のtooltipの表示状態 (for Client) */
  isShowedCopiedMessage: boolean;

  private readonly origin: ClientOriginInterface;

  get posting_data(): ClientOriginInterface {
    return new ClientOrigin(
      this.id,
      this.name,
      this.address,
      this.tel,
      this.email,
      this.intro_count,
      this.normal_count,
      this.advance_count,
      this.exchange_count,
      this.combi_count,
      this.order_price,
      this.contract_count,
      this.cancel_count,
      this.contract_rate,
      this.auto_rank,
      this.max_order_transfer,
      this.commission_rate,
      Number(this.attention_type),
      this.attention_type_name,
      this.memo_about_partner,
      this.prefecture,
      this.tantousya,
      this.distance,
      Number(this.contract_type),
      this.contract_type_name,
      this.last_offered_date,
      this.memo,
      this.client_memo_id,
      this.partner_cd,
      this.delivery_status_name,
      this.assignee_tel1,
      this.assignee_tel2
    );
  }

  constructor(client?: ClientOriginInterface) {
    super();
    this.origin = this.createClientInstance(client);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createClientInstance(client: ClientOriginInterface): ClientOrigin {
    return client
      ? new ClientOrigin(
          client.id,
          client.name,
          client.address,
          client.tel,
          client.email,
          client.intro_count,
          client.normal_count,
          client.advance_count,
          client.exchange_count,
          client.combi_count,
          client.order_price,
          client.contract_count,
          client.cancel_count,
          client.contract_rate,
          client.auto_rank,
          client.max_order_transfer,
          client.commission_rate,
          client.attention_type,
          client.attention_type_name,
          client.memo_about_partner,
          client.prefecture,
          client.tantousya,
          client.distance,
          client.contract_type,
          client.contract_type_name,
          client.last_offered_date,
          client.memo,
          client.client_memo_id,
          client.partner_cd,
          client.delivery_status_name,
          client.assignee_tel1,
          client.assignee_tel2
        )
      : new ClientOrigin();
  }

  private initializeProperties(client: ClientOrigin) {
    this.id = client.id ? String(client.id) : null;
    this.name = client.name;
    this.address = client.address;
    this.tel = client.tel;
    this.email = client.email;
    this.intro_count = client.intro_count ? client.intro_count : '0';
    this.normal_count = client.normal_count ? client.normal_count : '0';
    this.advance_count = client.advance_count ? client.advance_count : '0';
    this.exchange_count = client.exchange_count ? client.exchange_count : '0';
    this.combi_count = client.combi_count ? client.combi_count : '0';
    this.order_price = client.order_price ? client.order_price : '0';
    this.contract_count = client.contract_count ? client.contract_count : '0';
    this.cancel_count = client.cancel_count ? client.cancel_count : '0';
    this.contract_rate = client.contract_rate ? client.contract_rate : 0.0;
    this.auto_rank = client.auto_rank;
    this.max_order_transfer = client.max_order_transfer
      ? client.max_order_transfer
      : '0';
    this.commission_rate = client.commission_rate
      ? client.commission_rate
      : 0.0;
    this.attention_type = _.isNumber(client.attention_type)
      ? String(client.attention_type)
      : null;
    this.attention_type_name = client.attention_type_name;
    this.memo_about_partner = client.memo_about_partner;
    this.prefecture = client.prefecture;
    this.tantousya = client.tantousya;
    this.distance = client.distance;
    this.contract_type = _.isNumber(client.contract_type)
      ? String(client.contract_type)
      : null;
    this.contract_type_name = client.contract_type_name;
    this.last_offered_date = client.last_offered_date;
    this.memo = client.memo;
    this.client_memo_id = client.client_memo_id;
    this.partner_cd = client.partner_cd;
    this.delivery_status_name = client.delivery_status_name;
    this.assignee_tel1 = client.assignee_tel1;
    this.assignee_tel2 = client.assignee_tel2;
  }
}

/**
 * @classdesc APIに合わせたクライアント情報クラス
 * @description typescriptにprivate classの概念がないため、
 * 同一ファイル内に２つのクラスを実装。
 * @class ClientOriginInterface
 */
class ClientOrigin implements ClientOriginInterface {
  id: string;
  name: string;
  address: string;
  tel: string;
  email: string;
  intro_count: string;
  normal_count: string;
  advance_count: string;
  exchange_count: string;
  combi_count: string;
  order_price: string;
  contract_count: string;
  cancel_count: string;
  contract_rate: number;
  auto_rank: string;
  max_order_transfer: string;
  commission_rate: number;
  attention_type: number;
  attention_type_name: string;
  memo_about_partner: string;
  prefecture: string;
  tantousya: string;
  distance: string;
  contract_type: number;
  contract_type_name: string;
  last_offered_date: string;
  memo: string;
  client_memo_id: string;
  partner_cd: string;
  delivery_status_name: string;
  assignee_tel1: string;
  assignee_tel2: string;

  constructor(
    id?: string,
    name?: string,
    address?: string,
    tel?: string,
    email?: string,
    intro_count?: string,
    normal_count?: string,
    advance_count?: string,
    exchange_count?: string,
    combi_count?: string,
    order_price?: string,
    contract_count?: string,
    cancel_count?: string,
    contract_rate?: number,
    auto_rank?: string,
    max_order_transfer?: string,
    commission_rate?: number,
    attention_type?: number,
    attention_type_name?: string,
    memo_about_partner?: string,
    prefecture?: string,
    tantousya?: string,
    distance?: string,
    contract_type?: number,
    contract_type_name?: string,
    last_offered_date?: string,
    memo?: string,
    client_memo_id?: string,
    partner_cd?: string,
    delivery_status_name?: string,
    assignee_tel1?: string,
    assignee_tel2?: string
  ) {
    this.id = id ? id : null;
    this.name = name ? name : null;
    this.address = address ? address : null;
    this.tel = tel ? tel : null;
    this.email = email ? email : null;
    this.intro_count = intro_count ? intro_count : null;
    this.normal_count = normal_count ? normal_count : null;
    this.advance_count = advance_count ? advance_count : null;
    this.exchange_count = exchange_count ? exchange_count : null;
    this.combi_count = combi_count ? combi_count : null;
    this.order_price = order_price ? order_price : null;
    this.contract_count = contract_count ? contract_count : null;
    this.cancel_count = cancel_count ? cancel_count : null;
    this.contract_rate = contract_rate ? contract_rate : null;
    this.auto_rank = auto_rank ? auto_rank : null;
    this.max_order_transfer = max_order_transfer ? max_order_transfer : null;
    this.commission_rate = commission_rate ? commission_rate : null;
    this.attention_type = _.isNumber(attention_type) ? attention_type : null;
    this.attention_type_name = attention_type_name ? attention_type_name : null;
    this.memo_about_partner = memo_about_partner ? memo_about_partner : null;
    this.prefecture = prefecture ? prefecture : null;
    this.tantousya = tantousya ? tantousya : null;
    this.distance = distance ? distance : null;
    this.contract_type = _.isNumber(contract_type) ? contract_type : null;
    this.contract_type_name = contract_type_name ? contract_type_name : null;
    this.last_offered_date = last_offered_date ? last_offered_date : null;
    this.memo = memo ? memo : null;
    this.client_memo_id = client_memo_id ? client_memo_id : null;
    this.partner_cd = partner_cd ? partner_cd : null;
    this.delivery_status_name = delivery_status_name
      ? delivery_status_name
      : null;
    this.assignee_tel1 = assignee_tel1 ? assignee_tel1 : null;
    this.assignee_tel2 = assignee_tel2 ? assignee_tel2 : null;
  }
}
