import { ClientBasicInfoOriginInterface } from '@app-units/models/client-basic-info/client-basic-info-origin';
import { Media } from '@app-units/models/media/media';
import { MediaOriginInterface } from '@app-units/models/media/media-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

/**
 * @classdesc クライアント基本情報
 * @export
 * @class ClientBasicInfo
 */
export class ClientBasicInfo implements OriginWrapper {
  partner_id: string;
  partner_cd: string;
  sotoyell_id: string;
  partner_name: string;
  address: string;
  logo_upload_file_id: number;
  attention_type: number;
  attention_type_name: string;
  attention_reason: string;
  accept_offer_mail: number;
  show_page: number;
  memo_about_partner: string;
  media: Media;
  mails_csv_to_notify_order: string;
  delivery_status: number;
  delivery_status_name: string;
  again_delivery_date: string;
  zip: string;
  tel: string;
  fax: string;

  private readonly origin: ClientBasicInfoOriginInterface;

  get posting_data(): ClientBasicInfoOriginInterface {
    return new ClientBasicInfoOrigin(
      parseInt(this.partner_id),
      this.partner_cd,
      this.sotoyell_id,
      this.partner_name,
      this.address,
      this.logo_upload_file_id,
      this.attention_type,
      this.attention_type_name,
      this.attention_reason,
      this.accept_offer_mail,
      this.show_page,
      this.memo_about_partner,
      this.media.posting_data,
      this.mails_csv_to_notify_order,
      this.delivery_status,
      this.delivery_status_name,
      this.again_delivery_date,
      this.zip,
      this.tel,
      this.fax
    );
  }

  constructor(clientBasicInfo?: ClientBasicInfoOriginInterface) {
    this.origin = this.createClientBasicInfoInstance(clientBasicInfo);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createClientBasicInfoInstance(
    clientBasicInfo: ClientBasicInfoOriginInterface
  ): ClientBasicInfoOrigin {
    return clientBasicInfo
      ? new ClientBasicInfoOrigin(
          clientBasicInfo.partner_id,
          clientBasicInfo.partner_cd,
          clientBasicInfo.sotoyell_id,
          clientBasicInfo.partner_name,
          clientBasicInfo.address,
          clientBasicInfo.logo_upload_file_id,
          clientBasicInfo.attention_type,
          clientBasicInfo.attention_type_name,
          clientBasicInfo.attention_reason,
          clientBasicInfo.accept_offer_mail,
          clientBasicInfo.show_page,
          clientBasicInfo.memo_about_partner,
          clientBasicInfo.media,
          clientBasicInfo.mails_csv_to_notify_order,
          clientBasicInfo.delivery_status,
          clientBasicInfo.delivery_status_name,
          clientBasicInfo.again_delivery_date,
          clientBasicInfo.zip,
          clientBasicInfo.tel,
          clientBasicInfo.fax
        )
      : new ClientBasicInfoOrigin();
  }

  private initializeProperties(clientBasicInfo: ClientBasicInfoOrigin) {
    this.partner_id = clientBasicInfo.partner_id
      ? String(clientBasicInfo.partner_id)
      : null;
    this.partner_cd = clientBasicInfo.partner_cd;
    this.sotoyell_id = clientBasicInfo.sotoyell_id;
    this.partner_name = clientBasicInfo.partner_name;
    this.address = clientBasicInfo.address;
    this.logo_upload_file_id = clientBasicInfo.logo_upload_file_id;
    this.attention_type = clientBasicInfo.attention_type;
    this.attention_type_name = clientBasicInfo.attention_type_name;
    this.attention_reason = clientBasicInfo.attention_reason;
    this.accept_offer_mail = clientBasicInfo.accept_offer_mail;
    this.show_page = clientBasicInfo.show_page;
    this.memo_about_partner = clientBasicInfo.memo_about_partner;
    this.media = clientBasicInfo.media
      ? new Media(clientBasicInfo.media)
      : new Media();
    this.mails_csv_to_notify_order = clientBasicInfo.mails_csv_to_notify_order;
    this.delivery_status = clientBasicInfo.delivery_status;
    this.delivery_status_name = clientBasicInfo.delivery_status_name;
    this.again_delivery_date = clientBasicInfo.again_delivery_date;
    this.zip = clientBasicInfo.zip;
    this.tel = clientBasicInfo.tel;
    this.fax = clientBasicInfo.fax;
  }
}

/**
 * @classdesc APIに合わせたクライアント基本情報クラス
 * @description typescriptにprivate classの概念がないため、
 * 同一ファイル内に２つのクラスを実装。
 * @class ClientBasicInfoOriginInterface
 */
class ClientBasicInfoOrigin implements ClientBasicInfoOriginInterface {
  partner_id: number;
  partner_cd: string;
  sotoyell_id: string;
  partner_name: string;
  address: string;
  logo_upload_file_id: number;
  attention_type: number;
  attention_type_name: string;
  attention_reason: string;
  accept_offer_mail: number;
  show_page: number;
  memo_about_partner: string;
  media: MediaOriginInterface;
  mails_csv_to_notify_order: string;
  delivery_status: number;
  delivery_status_name: string;
  again_delivery_date: string;
  zip: string;
  tel: string;
  fax: string;

  constructor(
    partner_id?: number,
    partner_cd?: string,
    sotoyell_id?: string,
    partner_name?: string,
    address?: string,
    logo_upload_file_id?: number,
    attention_type?: number,
    attention_type_name?: string,
    attention_reason?: string,
    accept_offer_mail?: number,
    show_page?: number,
    memo_about_partner?: string,
    media?: MediaOriginInterface,
    mails_csv_to_notify_order?: string,
    delivery_status?: number,
    delivery_status_name?: string,
    again_delivery_date?: string,
    zip?: string,
    tel?: string,
    fax?: string
  ) {
    this.partner_id = partner_id ? partner_id : null;
    this.partner_cd = partner_cd ? partner_cd : null;
    this.sotoyell_id = sotoyell_id ? sotoyell_id : null;
    this.partner_name = partner_name ? partner_name : null;
    this.address = address ? address : null;
    this.logo_upload_file_id = _.isNumber(logo_upload_file_id)
      ? logo_upload_file_id
      : null;
    this.attention_type = _.isNumber(attention_type) ? attention_type : null;
    this.attention_type_name = attention_type_name ? attention_type_name : null;
    this.attention_reason = attention_reason ? attention_reason : null;
    this.accept_offer_mail = _.isNumber(attention_type)
      ? accept_offer_mail
      : null;
    this.show_page = _.isNumber(attention_type) ? show_page : null;
    this.memo_about_partner = memo_about_partner ? memo_about_partner : null;
    this.media = media ? media : null;
    this.mails_csv_to_notify_order = mails_csv_to_notify_order
      ? mails_csv_to_notify_order
      : null;
    this.delivery_status = !_.isNil(delivery_status) ? delivery_status : null;
    this.delivery_status_name = delivery_status_name
      ? delivery_status_name
      : null;
    this.again_delivery_date = again_delivery_date ? again_delivery_date : null;
    this.zip = zip ? zip : null;
    this.tel = tel ? tel : null;
    this.fax = fax ? fax : null;
  }
}
