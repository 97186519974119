import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { Review } from '@app-units/models/review/review';
import { SsUrlSearchParamsService } from '@blocks/utils/ss-url-search-params/ss-url-search-params.service';
import { ISsConstant, SS_CONSTANT } from '@ss-core/constants/ss.constant';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/* 初期値がないとフィールドが生まれない */
export class ReviewsSearchParams {
  /** ページ数 */
  page?: string = null;
  /** ソート */
  order?: string = null;
  /** 表示件数 */
  limit?: string = null;
  /** id（開始）*/
  id_start?: string = null;
  /** id（終了）*/
  id_end?: string = null;
  /** エンド名 */
  reviewer_name?: string = null;
  /** クライアント名 */
  client_name?: string = null;
  /** オーダーID */
  order_id_start?: string = null;
  order_id_end?: string = null;
  /** 投稿日 */
  posted_datetime_start?: string = null;
  posted_datetime_end?: string = null;
  /** ギフト券送付日 */
  reward_send_date_start?: string = null;
  reward_send_date_end?: string = null;
  /** 承認設定 */
  _approval_status?: string[] = null;
  /** 表示設定 */
  _show_on_public_client_page?: string[] = null;
  /** ラベル */
  label?: string = null;
}

@Injectable()
export class ReviewsHttpService {
  private get resource(): string {
    return '/reviews';
  }

  constructor(
    private http: HttpClient,
    private ssUrlSearchParams: SsUrlSearchParamsService,
    @Inject(SS_CONSTANT) private SS_CONSTANT: ISsConstant
  ) {}

  fetchItems(search: ReviewsSearchParams): Observable<app.response<Review[]>> {
    const params = this.ssUrlSearchParams.parseParams(search);
    return this.http
      .get<Review[]>(this.resource, { params: params })
      .pipe(map(JsonConverter.toReview));
  }

  saveItem(review: Review): Observable<app.response<Review>> {
    return this.http
      .post(this.resource, review)
      .pipe(map(JsonConverter.toReview));
  }

  deleteItem(reviewId: string): Observable<app.response<any>> {
    const url = `${this.resource}/${reviewId}`;
    return this.http.delete<app.response<any>>(url);
  }

  /* 編集権限の取得 */
  confirmAuth(
    reviewId: string
  ): Observable<app.response<{ editable: boolean }>> {
    const url = `${this.resource}/${reviewId}/auth`;
    return this.http.get<app.response<any>>(url);
  }

  exportCsvURL(paramsString: string): string {
    return (
      this.SS_CONSTANT.API_BASE_URL + this.resource + '/csv?' + paramsString
    );
  }

  /**
  import { OrderLastEditTimestamp } from '@app-units/models/order-last-edit-timestamp/order-last-edit-timestamp';
  fetchItemEditTimestamp(
    orderId: string
  ): Observable<app.response<OrderLastEditTimestamp>> {
    const url = `${this.resource}/${orderId}/edit_timestamp`;
    return this.http.get(url).pipe(map(JsonConverter.toOrderLastEditTimestamp));
  }
  */

  fetchItemPrevNext(
    reviewId: string,
    search: ReviewsSearchParams
  ): Observable<app.response<{ next_id: number; prev_id: number }>> {
    const url = `${this.resource}/prev_next/${reviewId}`;
    const params = this.ssUrlSearchParams.parseParams(search);
    return this.http.get<app.response<any>>(url, { params: params });
  }
}
