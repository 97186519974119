/**
 * ReviewモデルとなるClass
 * - APIの戻り値をプロパティにセットする
 * - フィールドは必ず存在するものとする（Optionalにはならない）
 * - 既定メンバでないプロパティは生成しない
 * - Non-null
 */
export class ReviewManageInfo {
  /** @todo SsSelectiveではid:stringだった。stringである意味があるか？ */
  id: number = 0;
  /** 投稿日時 */
  posted_datetime: string = '';
  /** 施工後アンケート（口コミ）承認設定 */
  approval_status: number = 0;
  approval_status_name: string = '';
  /** 施工店ページへの表示設定 */
  show_on_public_client_page: number = 0;
  show_on_public_client_page_name: string = '';
  /** 謝礼送付日 */
  reward_send_date: string = '';
  /** 謝礼金額 */
  reward_price_id: number = 0;
  reward_price_name: string = '';
  /** 契約金額（税込） */
  contract_price: number = 0;
  /** 管理メモ */
  management_memo: string = '';
  /** ラベル */
  label: string = '';

  /**
   * @example fb.groupFromModel(new ReviewManageInfo());
   */
  constructor(data?) {
    if (data) {
      this.id = data.id;
      this.posted_datetime = data.posted_datetime;
      this.approval_status = data.approval_status;
      this.approval_status_name = data.approval_status_name;
      this.show_on_public_client_page = data.show_on_public_client_page;
      this.show_on_public_client_page_name =
        data.show_on_public_client_page_name;
      this.reward_send_date = data.reward_send_date;
      this.reward_price_id = data.reward_price_id;
      this.reward_price_name = data.reward_price_name;
      this.contract_price = data.contract_price;
      this.management_memo = data.management_memo;
      this.label = data.label;
    }
  }

  /**
   * nullを初期値に変換して、コンストラクタに渡す
   * @example const data = ReviewManageInfo.initData(json.data)
   */
  static initData(data: ReviewManageInfo) {
    let procData = {} as any;
    Object.entries(data).forEach(([key, val]) => {
      switch (key) {
        // number | nullのプロパティ
        case 'reward_price_id':
          procData[`${key}`] = val ?? 0;
          return;

        // Nullを返すメンバの大部分は string | null
        default:
          procData[`${key}`] = val ?? '';
          return;
      }
    });

    return new ReviewManageInfo(procData as ReviewManageInfo);
  }
}
