import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { SsFormBuilder } from '@blocks/utils/ss-form-builder/ss-form-builder';
import { SsQueryParamsService } from '@blocks/utils/ss-query-params/ss-query-params.service';
import { SsRemoteValidatorService } from '@blocks/utils/ss-remote-validator/ss-remote-validator.service';
import { SsSearchService } from '@blocks/utils/ss-search/ss-search.service';
import { SsUrlSearchParamsService } from '@blocks/utils/ss-url-search-params/ss-url-search-params.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
})
export class SsUtilsModule {
  static forRoot(): ModuleWithProviders<SsUtilsModule> {
    return {
      ngModule: SsUtilsModule,
      providers: [
        SsQueryParamsService,
        SsSearchService,
        SsUrlSearchParamsService,
        SsRemoteValidatorService,
        SsFormBuilder,
      ],
    };
  }
}
