import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SsConfirmDialogComponent } from '@blocks/dialog/ss-confirm-dialog/ss-confirm-dialog.component';
import { SsConfirmDirective } from '@blocks/dialog/ss-confirm/ss-confirm.directive';
import { SsDialogContainerComponent } from '@blocks/dialog/ss-dialog-container/ss-dialog-container.component';
import { SsDialogService } from '@blocks/dialog/ss-dialog.service';
import { SsDialogComponent } from '@blocks/dialog/ss-dialog/ss-dialog.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    SsDialogComponent,
    SsDialogContainerComponent,
    SsConfirmDirective,
    SsConfirmDialogComponent,
  ],
  exports: [SsConfirmDirective],
  entryComponents: [
    SsDialogComponent,
    SsDialogContainerComponent,
    SsConfirmDialogComponent,
  ],
})
export class SsDialogModule {
  static forRoot(): ModuleWithProviders<SsDialogModule> {
    return {
      ngModule: SsDialogModule,
      providers: [SsDialogService],
    };
  }
}
