import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { SmsInfo } from '@app-units/models/sms-info/sms-info';
import { SmsInfoOriginInterface } from '@app-units/models/sms-info/sms-info-origin';
import { Observable } from 'rxjs';

@Injectable()
export class SmsInfoHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(id: string): Observable<app.response<SmsInfo>> {
    return this.http.get(this.getUrl(id)).pipe(map(JsonConverter.toSmsInfo));
  }

  saveItem(item: SmsInfoOriginInterface): Observable<app.response<SmsInfo>> {
    return this.http
      .put(this.getUrl(String(item.id)), item)
      .pipe(map(JsonConverter.toSmsInfo));
  }

  private getUrl(id: string) {
    return `/orders/${id}/sms`;
  }
}
