import { ProgressOriginInterface } from '@app-units/models/progress/progress-origin';
import { OriginWrapper, SsType } from '@ss-core/models';
import * as dayjs from 'dayjs';

export class Progress implements OriginWrapper {
  private readonly origin: ProgressOriginInterface;

  id: string;
  user_for_calling: string;
  user_for_calling_name: string;
  user_for_delivery: string;
  user_for_delivery_name: string;
  order_status: string;
  order_trailing_status: string;
  req_datetime: string;
  end_last_name: string;
  end_first_name: string;
  end_last_name_kana: string;
  end_first_name_kana: string;
  end_tel: string;
  end_email: string;
  client_introduce_date: string;
  source_site_name: string;
  label: string;
  order_cd: string;
  next_call_date: string;
  delivery_again: string;
  next_call_timing: string;
  delivery_complete_date: string;
  next_call_datetime: string;
  next_call_time_: string;
  inflow_source: string;
  ad_keyword: string;
  guidebook_sender_user_id: string;
  guidebook_sent_date: string;
  second_call_date: string;

  get posting_data(): ProgressOriginInterface {
    return new ProgressOrigin(
      parseInt(this.id),
      parseInt(this.user_for_calling),
      this.user_for_calling_name,
      parseInt(this.user_for_delivery),
      this.user_for_delivery_name,
      parseInt(this.order_status),
      parseInt(this.order_trailing_status),
      this.req_datetime,
      this.end_last_name,
      this.end_first_name,
      this.end_last_name_kana,
      this.end_first_name_kana,
      this.end_tel,
      this.end_email,
      this.client_introduce_date,
      this.source_site_name,
      this.label,
      this.order_cd,
      this.next_call_date,
      parseInt(this.delivery_again),
      parseInt(this.next_call_timing),
      this.delivery_complete_date,
      this.next_call_datetime,
      this.inflow_source,
      this.ad_keyword,
      parseInt(this.guidebook_sender_user_id),
      this.guidebook_sent_date,
      this.second_call_date
    );
  }

  constructor(profile?: ProgressOriginInterface) {
    this.origin = this.createProgressInstance(profile);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createProgressInstance(progress: ProgressOrigin): ProgressOrigin {
    return progress
      ? new ProgressOrigin(
          progress.id,
          progress.user_for_calling,
          progress.user_for_calling_name,
          progress.user_for_delivery,
          progress.user_for_delivery_name,
          progress.order_status,
          progress.order_trailing_status,
          progress.req_datetime,
          progress.end_last_name,
          progress.end_first_name,
          progress.end_last_name_kana,
          progress.end_first_name_kana,
          progress.end_tel,
          progress.end_email,
          progress.client_introduce_date,
          progress.source_site_name,
          progress.label,
          progress.order_cd,
          progress.next_call_date,
          progress.delivery_again,
          progress.next_call_timing,
          progress.delivery_complete_date,
          progress.next_call_datetime,
          progress.inflow_source,
          progress.ad_keyword,
          progress.guidebook_sender_user_id,
          progress.guidebook_sent_date,
          progress.second_call_date
        )
      : new ProgressOrigin();
  }

  private initializeProperties(progress: ProgressOrigin) {
    this.id = progress.id || progress.id === 0 ? String(progress.id) : null;
    this.user_for_calling =
      progress.user_for_calling || progress.user_for_calling === 0
        ? String(progress.user_for_calling)
        : null;
    this.user_for_calling_name = progress.user_for_calling_name;
    this.user_for_delivery =
      progress.user_for_delivery || progress.user_for_delivery === 0
        ? String(progress.user_for_delivery)
        : null;
    this.user_for_delivery_name = progress.user_for_delivery_name;
    this.order_status =
      progress.order_status || progress.order_status === 0
        ? String(progress.order_status)
        : null;
    this.order_trailing_status =
      progress.order_trailing_status || progress.order_trailing_status === 0
        ? String(progress.order_trailing_status)
        : null;
    this.req_datetime = progress.req_datetime;
    this.end_last_name = progress.end_last_name;
    this.end_first_name = progress.end_first_name;
    this.end_last_name_kana = progress.end_last_name_kana;
    this.end_first_name_kana = progress.end_first_name_kana;
    this.end_tel = progress.end_tel;
    this.end_email = progress.end_email;
    this.client_introduce_date = progress.client_introduce_date;
    this.source_site_name = progress.source_site_name;
    this.label = progress.label;
    this.order_cd = progress.order_cd;
    this.next_call_date = progress.next_call_date;
    this.delivery_again =
      progress.delivery_again || progress.delivery_again === 0
        ? String(progress.delivery_again)
        : null;
    this.next_call_timing =
      progress.next_call_timing || progress.next_call_timing === 0
        ? String(progress.next_call_timing)
        : null;
    this.delivery_complete_date = progress.delivery_complete_date;
    /* 次にかけるタイミングの「日時指定」（クライアント用作成） */
    this.next_call_time_ = progress.next_call_datetime
      ? dayjs(progress.next_call_datetime, 'YYYY/MM/DD HH:mm').format()
      : null;
    this.next_call_datetime = progress.next_call_datetime
      ? dayjs(progress.next_call_datetime, 'YYYY/MM/DD HH:mm').format(
          'YYYY/MM/DD'
        )
      : null;
    this.inflow_source = progress.inflow_source;
    this.ad_keyword = progress.ad_keyword;
    this.guidebook_sender_user_id =
      progress.guidebook_sender_user_id ||
      progress.guidebook_sender_user_id === 0
        ? String(progress.guidebook_sender_user_id)
        : null;
    this.guidebook_sent_date = progress.guidebook_sent_date;
    this.second_call_date = progress.second_call_date;
  }
}

class ProgressOrigin implements ProgressOriginInterface {
  id: number;
  user_for_calling: number;
  user_for_calling_name: string;
  user_for_delivery: number;
  user_for_delivery_name: string;
  order_status: number;
  order_trailing_status: number;
  req_datetime: string;
  end_last_name: string;
  end_first_name: string;
  end_last_name_kana: string;
  end_first_name_kana: string;
  end_tel: string;
  end_email: string;
  client_introduce_date: string;
  source_site_name: string;
  label: string;
  order_cd: string;
  next_call_date: string;
  delivery_again: number;
  next_call_timing: number;
  delivery_complete_date: string;
  next_call_datetime: string;
  inflow_source: string;
  ad_keyword: string;
  guidebook_sender_user_id: number;
  guidebook_sent_date: string;
  second_call_date: string;

  constructor(
    id?: number,
    user_for_calling?: number,
    user_for_calling_name?: string,
    user_for_delivery?: number,
    user_for_delivery_name?: string,
    order_status?: number,
    order_trailing_status?: number,
    req_datetime?: string,
    end_last_name?: string,
    end_first_name?: string,
    end_last_name_kana?: string,
    end_first_name_kana?: string,
    end_tel?: string,
    end_email?: string,
    client_introduce_date?: string,
    source_site_name?: string,
    label?: string,
    order_cd?: string,
    next_call_date?: string,
    delivery_again?: number,
    next_call_timing?: number,
    delivery_complete_date?: string,
    next_call_datetime?: string,
    inflow_source?: string,
    ad_keyword?: string,
    guidebook_sender_user_id?: number,
    guidebook_sent_date?: string,
    second_call_date?: string
  ) {
    this.id = id || id === 0 ? id : null;
    this.user_for_calling =
      user_for_calling || user_for_calling === 0 ? user_for_calling : null;
    this.user_for_calling_name = user_for_calling_name
      ? user_for_calling_name
      : null;
    this.user_for_delivery =
      user_for_delivery || user_for_delivery === 0 ? user_for_delivery : null;
    this.user_for_delivery_name = user_for_delivery_name
      ? user_for_delivery_name
      : null;
    this.order_status =
      order_status || order_status === 0 ? order_status : null;
    this.order_trailing_status =
      order_trailing_status || order_trailing_status === 0
        ? order_trailing_status
        : null;
    this.req_datetime = req_datetime ? req_datetime : null;
    this.end_last_name = end_last_name ? end_last_name : null;
    this.end_first_name = end_first_name ? end_first_name : null;
    this.end_last_name_kana = end_last_name_kana ? end_last_name_kana : null;
    this.end_first_name_kana = end_first_name_kana ? end_first_name_kana : null;
    this.end_tel = end_tel ? end_tel : null;
    this.end_email = end_email ? end_email : null;
    this.client_introduce_date = client_introduce_date
      ? client_introduce_date
      : null;
    this.source_site_name = source_site_name ? source_site_name : null;
    this.label = label ? label : null;
    this.order_cd = order_cd ? order_cd : null;
    this.next_call_date = next_call_date ? next_call_date : null;
    this.delivery_again =
      delivery_again || delivery_again === 0 ? delivery_again : null;
    this.next_call_timing =
      next_call_timing || next_call_timing === 0 ? next_call_timing : null;
    this.delivery_complete_date = delivery_complete_date
      ? delivery_complete_date
      : null;
    this.next_call_datetime = next_call_datetime ? next_call_datetime : null;
    this.inflow_source = inflow_source ? inflow_source : null;
    this.ad_keyword = ad_keyword ? ad_keyword : null;
    this.guidebook_sender_user_id =
      guidebook_sender_user_id || guidebook_sender_user_id === 0
        ? guidebook_sender_user_id
        : null;
    this.guidebook_sent_date = guidebook_sent_date ? guidebook_sent_date : null;
    this.second_call_date = second_call_date ? second_call_date : null;
  }
}
