import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { PrefectureConverter } from '@app-core/services/utility/prefecture-converter';
import { AddressNote } from '@app-units/models/address-note/address-note';
import * as _ from 'lodash';
import { Observable, Observer } from 'rxjs';

interface PostCode {
  /** 上3桁 */
  upper: string;
  /** 下4桁 */
  lower: string;
}

@Injectable()
export class AddressBookService {
  private addressBook: { [key: string]: { [key: string]: AddressNote } } = {};
  private addressBookError: ValidationErrors = {
    remote: ['郵便番号が見つかりません'],
  };

  constructor(private http: HttpClient) {}

  /**
   * @name searchAddress
   * @description 郵便番号から住所情報を検索
   * @param postCode 郵便番号上3桁の文字列か郵便番号7桁の文字列（ハイフン付きでも可）
   * @param lowerPostCode 郵便番号下4桁の文字列
   */
  searchAddress(
    postCode: string,
    lowerPostCode?: string
  ): Observable<AddressNote> {
    if (_.isNil(postCode)) {
      return Observable.create((observer: Observer<AddressNote>) =>
        observer.error(this.addressBookError)
      );
    }
    const postCodeFormatted = this.formatPostCode(postCode, lowerPostCode);
    const searchResult = this.searchFromAddressBook(postCodeFormatted);
    if (!_.isNil(searchResult)) {
      /** AddressBookから見つかった場合は検索結果を返す */
      return Observable.create((observer: Observer<AddressNote>) =>
        observer.next(searchResult)
      );
    }
    return this.searchFromGitHubData(postCodeFormatted);
  }

  private searchFromAddressBook(postCode: PostCode) {
    /** nullが返された場合はキャッシュ無し */
    return this.searchCore(postCode.lower, this.addressBook[postCode.upper]);
  }

  private searchFromGitHubData(postCode: PostCode): Observable<AddressNote> {
    return Observable.create((observer: Observer<AddressNote>) => {
      // TODO: Bugっぽい。optionsは空オブジェクトでいいかも？
      // https://github.com/angular/angular/issues/18586
      /** GitHubから上3桁.jsファイルを取得 */
      const url = `https://yubinbango.github.io/yubinbango-data/data/${postCode.upper}.js`;
      this.http.get(url, { responseType: 'text' }).subscribe(
        (res) => {
          const addressNotes = this.makeAddressNotes(res);
          this.addressBook[postCode.upper] = addressNotes;
          observer.next(this.searchCore(postCode.lower, addressNotes));
        },
        () => {
          observer.error(this.addressBookError);
        }
      );
    });
  }

  private searchCore(
    lowerPostCode: string,
    addressNotes: { [key: string]: AddressNote }
  ): AddressNote {
    if (_.isNil(addressNotes)) {
      return null;
    }
    const keys = Object.keys(addressNotes);
    if (keys.length < 1) {
      return null;
    }
    /** 上3桁の住所があったのに、下4桁でヒットしなかった場合は都道府県のデータが返るようにする */
    const referenceAddressNote = addressNotes[keys[0]];
    const addressNote = addressNotes[lowerPostCode];
    return _.isNil(addressNote)
      ? new AddressNote(
          referenceAddressNote.upperPostCode,
          '',
          referenceAddressNote.prefectureCode,
          referenceAddressNote.prefecture
        )
      : addressNote;
  }

  private makeAddressNotes(responseText: string): {
    [key: string]: AddressNote;
  } {
    const addressNotes: { [key: string]: AddressNote } = {};
    const addressDataTextArray = responseText
      .replace('$yubin({', '')
      .replace(']});\n', '')
      .split('],');
    _.forEach(addressDataTextArray, (addressDataText) => {
      const addressNote = this.makeAddressNote(
        addressDataText.replace(':[', ',').replace(/\"+/g, '').split(',')
      );
      addressNotes[addressNote.lowerPostCode] = addressNote;
    });
    return addressNotes;
  }

  private makeAddressNote(addressDataTexts: string[]): AddressNote {
    const postCode = addressDataTexts[0];
    const upperPostCode = String(postCode).substr(0, 3);
    const lowerPostCode = String(postCode).substr(3, 4);
    const prefectureCode = Number(addressDataTexts[1]);
    const prefecture = PrefectureConverter.toPrefecture(prefectureCode);
    const locality = addressDataTexts[2];
    const street = addressDataTexts[3];
    if (addressDataTexts.length > 4) {
      const otherInfo = addressDataTexts[4];
      /** その他情報にビル名などが含まれていないか検索 */
      const searchIndex = otherInfo.search(
        /[^-ー‐‑–—―−ｰー－―丁目番号0-9０-９]/g
      );
      const streetNumber =
        searchIndex >= 0 ? otherInfo.substr(0, searchIndex) : otherInfo;
      const buildingName =
        searchIndex >= 0
          ? otherInfo.substr(searchIndex, otherInfo.length - searchIndex)
          : '';
      return new AddressNote(
        upperPostCode,
        lowerPostCode,
        prefectureCode,
        prefecture,
        locality,
        street,
        streetNumber,
        buildingName
      );
    }
    return new AddressNote(
      upperPostCode,
      lowerPostCode,
      prefectureCode,
      prefecture,
      locality,
      street
    );
  }

  private formatPostCode(
    postCodeArg: string,
    lowerPostCodeArg: string
  ): PostCode {
    if (_.isNil(lowerPostCodeArg)) {
      /** 数字以外の文字を排除 */
      const postCodeArgReplaced = postCodeArg.replace(/[^0-9０-９]/g, '');
      const upperPostCode = postCodeArgReplaced.substr(0, 3);
      const lowerPostCode = postCodeArgReplaced.substr(3, 4);
      return {
        upper: this.replaceFullWidthAlphanumericToHalfWidth(upperPostCode),
        lower: this.replaceFullWidthAlphanumericToHalfWidth(lowerPostCode),
      };
    }
    return {
      upper: this.replaceFullWidthAlphanumericToHalfWidth(postCodeArg),
      lower: this.replaceFullWidthAlphanumericToHalfWidth(lowerPostCodeArg),
    };
  }

  private replaceFullWidthAlphanumericToHalfWidth(targetString: string) {
    return targetString.replace(/[０-９]/g, (stringReplaced) => {
      /** 文字コードで全角の65248個前が半角英数の文字コード */
      const offsetNumber = 65248;
      return String.fromCharCode(stringReplaced.charCodeAt(0) - offsetNumber);
    });
  }
}
