import { ArticleConstructionInfoOriginInterface } from '@app-units/models/article-construction-info/article-construction-info-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

export class ArticleConstructionInfo
  extends SsSelective
  implements OriginWrapper
{
  private readonly origin: ArticleConstructionInfoOriginInterface;

  id: string;
  zipcode: string;
  prefecture_name: string;
  prefecture: number;
  city: string;
  town: string;
  address: string;
  latitude: string;
  longitude: string;
  live_flag_name: string;
  live_flag: number;
  present_address: string;
  article_type_name: string;
  article_type: number;
  floor: number;
  article_age: string;
  estimated_from_type_ids: string[];
  order_estimated_from_type_ids: string[];
  order_estimated_from_type_names: string[];
  order_estimated_trigger_type_ids: string[];
  special_condition_ids: string[];
  repair_req: string[];
  floor_area_name: string;
  floor_area: number;
  motivational_level_type_id: number;
  order_require_subsidy_type_id: number;
  order_payment_method_type_id: number;
  near_situation_name: string;
  near_situation: number;
  repair_request_timing: string;
  introduce_request: number;
  introduce_request_name: string;
  estimate_meeting_type_id: number;
  have_estimates: number;
  attend_ng_reason_type_id: number;
  attend_flag_name: string;
  attend_flag: number;
  attend_condition: string;
  estimate_meeting_text: string;
  estimated_count: number;
  estimated_company: string;
  motivational_level_text: string;
  order_payment_method_text: string;
  impression: string;
  order_require_subsidy_text: string;
  contactable_time: string;
  add_memo: string;
  offer_order_memo: string;
  memo: string;
  calling_memo: string;
  floor_name: string;
  repair_request_name: string;

  get posting_data(): ArticleConstructionInfoOriginInterface {
    return new ArticleConstructionInfoOrigin(
      parseInt(this.id),
      this.zipcode,
      this.prefecture_name,
      this.prefecture,
      this.city,
      this.town,
      this.address,
      this.latitude,
      this.longitude,
      this.live_flag_name,
      this.live_flag,
      this.present_address,
      this.article_type_name,
      this.article_type,
      this.floor,
      this.article_age,
      this.estimated_from_type_ids,
      this.order_estimated_from_type_ids,
      this.order_estimated_from_type_names,
      this.order_estimated_trigger_type_ids,
      this.special_condition_ids,
      this.repair_req,
      this.floor_area_name,
      this.floor_area,
      this.motivational_level_type_id,
      this.order_require_subsidy_type_id,
      this.order_payment_method_type_id,
      this.near_situation_name,
      this.near_situation,
      this.repair_request_timing,
      this.introduce_request,
      this.introduce_request_name,
      this.estimate_meeting_type_id,
      this.have_estimates,
      this.attend_ng_reason_type_id,
      this.attend_flag_name,
      this.attend_flag,
      this.attend_condition,
      this.estimate_meeting_text,
      this.estimated_count,
      this.estimated_company,
      this.motivational_level_text,
      this.order_payment_method_text,
      this.impression,
      this.order_require_subsidy_text,
      this.contactable_time,
      this.add_memo,
      this.offer_order_memo,
      this.memo,
      this.calling_memo,
      this.floor_name,
      this.repair_request_name
    );
  }

  constructor(
    articleConstructionInfo?: ArticleConstructionInfoOriginInterface
  ) {
    super();
    this.origin = this.createArticleConstructionInfoInstance(
      articleConstructionInfo
    );
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createArticleConstructionInfoInstance(
    articleConstructionInfo: ArticleConstructionInfoOriginInterface
  ): ArticleConstructionInfoOrigin {
    return articleConstructionInfo
      ? new ArticleConstructionInfoOrigin(
          articleConstructionInfo.id,
          articleConstructionInfo.zipcode,
          articleConstructionInfo.prefecture_name,
          articleConstructionInfo.prefecture,
          articleConstructionInfo.city,
          articleConstructionInfo.town,
          articleConstructionInfo.address,
          articleConstructionInfo.latitude,
          articleConstructionInfo.longitude,
          articleConstructionInfo.live_flag_name,
          articleConstructionInfo.live_flag,
          articleConstructionInfo.present_address,
          articleConstructionInfo.article_type_name,
          articleConstructionInfo.article_type,
          articleConstructionInfo.floor,
          articleConstructionInfo.article_age,
          articleConstructionInfo.estimated_from_type_ids,
          articleConstructionInfo.order_estimated_from_type_ids,
          articleConstructionInfo.order_estimated_from_type_names,
          articleConstructionInfo.order_estimated_trigger_type_ids,
          articleConstructionInfo.special_condition_ids,
          articleConstructionInfo.repair_req,
          articleConstructionInfo.floor_area_name,
          articleConstructionInfo.floor_area,
          articleConstructionInfo.motivational_level_type_id,
          articleConstructionInfo.order_require_subsidy_type_id,
          articleConstructionInfo.order_payment_method_type_id,
          articleConstructionInfo.near_situation_name,
          articleConstructionInfo.near_situation,
          articleConstructionInfo.repair_request_timing,
          articleConstructionInfo.introduce_request,
          articleConstructionInfo.introduce_request_name,
          articleConstructionInfo.estimate_meeting_type_id,
          articleConstructionInfo.have_estimates,
          articleConstructionInfo.attend_ng_reason_type_id,
          articleConstructionInfo.attend_flag_name,
          articleConstructionInfo.attend_flag,
          articleConstructionInfo.attend_condition,
          articleConstructionInfo.estimate_meeting_text,
          articleConstructionInfo.estimated_count,
          articleConstructionInfo.estimated_company,
          articleConstructionInfo.motivational_level_text,
          articleConstructionInfo.order_payment_method_text,
          articleConstructionInfo.impression,
          articleConstructionInfo.order_require_subsidy_text,
          articleConstructionInfo.contactable_time,
          articleConstructionInfo.add_memo,
          articleConstructionInfo.offer_order_memo,
          articleConstructionInfo.memo,
          articleConstructionInfo.calling_memo,
          articleConstructionInfo.floor_name,
          articleConstructionInfo.repair_request_name
        )
      : new ArticleConstructionInfoOrigin();
  }

  private initializeProperties(
    articleConstructionInfo: ArticleConstructionInfoOrigin
  ) {
    this.id = articleConstructionInfo.id
      ? String(articleConstructionInfo.id)
      : null;
    this.zipcode = articleConstructionInfo.zipcode;
    this.prefecture_name = articleConstructionInfo.prefecture_name;
    this.prefecture = articleConstructionInfo.prefecture;
    this.city = articleConstructionInfo.city;
    this.town = articleConstructionInfo.town;
    this.address = articleConstructionInfo.address;
    this.latitude = articleConstructionInfo.latitude;
    this.longitude = articleConstructionInfo.longitude;
    this.live_flag_name = articleConstructionInfo.live_flag_name;
    this.live_flag = articleConstructionInfo.live_flag;
    this.present_address = articleConstructionInfo.present_address;
    this.article_type_name = articleConstructionInfo.article_type_name;
    this.article_type = articleConstructionInfo.article_type;
    this.floor = articleConstructionInfo.floor;
    this.article_age = articleConstructionInfo.article_age;
    this.estimated_from_type_ids =
      articleConstructionInfo.estimated_from_type_ids;
    this.order_estimated_from_type_ids =
      articleConstructionInfo.order_estimated_from_type_ids;
    this.order_estimated_from_type_names =
      articleConstructionInfo.order_estimated_from_type_names;
    this.order_estimated_trigger_type_ids =
      articleConstructionInfo.order_estimated_trigger_type_ids;
    this.special_condition_ids = articleConstructionInfo.special_condition_ids;
    this.repair_req = articleConstructionInfo.repair_req;
    this.floor_area_name = articleConstructionInfo.floor_area_name;
    this.floor_area = articleConstructionInfo.floor_area;
    this.motivational_level_type_id =
      articleConstructionInfo.motivational_level_type_id;
    this.order_require_subsidy_type_id =
      articleConstructionInfo.order_require_subsidy_type_id;
    this.order_payment_method_type_id =
      articleConstructionInfo.order_payment_method_type_id;
    this.near_situation_name = articleConstructionInfo.near_situation_name;
    this.near_situation = articleConstructionInfo.near_situation;
    this.repair_request_timing = articleConstructionInfo.repair_request_timing;
    this.introduce_request = articleConstructionInfo.introduce_request;
    this.introduce_request_name =
      articleConstructionInfo.introduce_request_name;
    this.estimate_meeting_type_id =
      articleConstructionInfo.estimate_meeting_type_id;
    this.have_estimates = articleConstructionInfo.have_estimates;
    this.attend_ng_reason_type_id =
      articleConstructionInfo.attend_ng_reason_type_id;
    this.attend_flag_name = articleConstructionInfo.attend_flag_name;
    this.attend_flag = articleConstructionInfo.attend_flag;
    this.attend_condition = articleConstructionInfo.attend_condition;
    this.estimate_meeting_text = articleConstructionInfo.estimate_meeting_text;
    this.estimated_count = articleConstructionInfo.estimated_count;
    this.estimated_company = articleConstructionInfo.estimated_company;
    this.motivational_level_text =
      articleConstructionInfo.motivational_level_text;
    this.order_payment_method_text =
      articleConstructionInfo.order_payment_method_text;
    this.impression = articleConstructionInfo.impression;
    this.order_require_subsidy_text =
      articleConstructionInfo.order_require_subsidy_text;
    this.contactable_time = articleConstructionInfo.contactable_time;
    this.add_memo = articleConstructionInfo.add_memo;
    this.offer_order_memo = articleConstructionInfo.offer_order_memo;
    this.memo = articleConstructionInfo.memo;
    this.calling_memo = articleConstructionInfo.calling_memo;
    this.floor_name = articleConstructionInfo.floor_name;
    this.repair_request_name = articleConstructionInfo.repair_request_name;
  }
}

class ArticleConstructionInfoOrigin
  implements ArticleConstructionInfoOriginInterface
{
  id: number;
  zipcode: string;
  prefecture_name: string;
  prefecture: number;
  city: string;
  town: string;
  address: string;
  latitude: string;
  longitude: string;
  live_flag_name: string;
  live_flag: number;
  present_address: string;
  article_type_name: string;
  article_type: number;
  floor: number;
  article_age: string;
  estimated_from_type_ids: string[];
  order_estimated_from_type_ids: string[];
  order_estimated_from_type_names: string[];
  order_estimated_trigger_type_ids: string[];
  special_condition_ids: string[];
  repair_req: string[];
  floor_area_name: string;
  floor_area: number;
  motivational_level_type_id: number;
  order_require_subsidy_type_id: number;
  order_payment_method_type_id: number;
  near_situation_name: string;
  near_situation: number;
  repair_request_timing: string;
  introduce_request: number;
  introduce_request_name: string;
  estimate_meeting_type_id: number;
  have_estimates: number;
  attend_ng_reason_type_id: number;
  attend_flag_name: string;
  attend_flag: number;
  attend_condition: string;
  estimate_meeting_text: string;
  estimated_count: number;
  estimated_company: string;
  motivational_level_text: string;
  order_payment_method_text: string;
  impression: string;
  order_require_subsidy_text: string;
  contactable_time: string;
  add_memo: string;
  offer_order_memo: string;
  memo: string;
  calling_memo: string;
  repair_request: string;
  floor_name: string;
  repair_request_name: string;

  constructor(
    id?: number,
    zipcode?: string,
    prefecture_name?: string,
    prefecture?: number,
    city?: string,
    town?: string,
    address?: string,
    latitude?: string,
    longitude?: string,
    live_flag_name?: string,
    live_flag?: number,
    present_address?: string,
    article_type_name?: string,
    article_type?: number,
    floor?: number,
    article_age?: string,
    estimated_from_type_ids?: string[],
    order_estimated_from_type_ids?: string[],
    order_estimated_from_type_names?: string[],
    order_estimated_trigger_type_ids?: string[],
    special_condition_ids?: string[],
    repair_req?: string[],
    floor_area_name?: string,
    floor_area?: number,
    motivational_level_type_id?: number,
    order_require_subsidy_type_id?: number,
    order_payment_method_type_id?: number,
    near_situation_name?: string,
    near_situation?: number,
    repair_request_timing?: string,
    introduce_request?: number,
    introduce_request_name?: string,
    estimate_meeting_type_id?: number,
    have_estimates?: number,
    attend_ng_reason_type_id?: number,
    attend_flag_name?: string,
    attend_flag?: number,
    attend_condition?: string,
    estimate_meeting_text?: string,
    estimated_count?: number,
    estimated_company?: string,
    motivational_level_text?: string,
    order_payment_method_text?: string,
    impression?: string,
    order_require_subsidy_text?: string,
    contactable_time?: string,
    add_memo?: string,
    offer_order_memo?: string,
    memo?: string,
    calling_memo?: string,
    floor_name?: string,
    repair_request_name?: string
  ) {
    this.id = id ? id : null;
    this.zipcode = zipcode ? zipcode : null;
    this.prefecture_name = prefecture_name ? prefecture_name : null;
    this.prefecture = prefecture ? prefecture : null;
    this.city = city ? city : null;
    this.town = town ? town : null;
    this.address = address ? address : null;
    this.latitude = latitude ? latitude : null;
    this.longitude = longitude ? longitude : null;
    this.live_flag_name = live_flag_name ? live_flag_name : null;
    this.live_flag = live_flag ? live_flag : null;
    this.present_address = present_address ? present_address : null;
    this.article_type_name = article_type_name ? article_type_name : null;
    this.article_type = article_type ? article_type : null;
    this.floor = floor ? floor : null;
    this.article_age = article_age ? article_age : null;
    this.estimated_from_type_ids = _.isArray(estimated_from_type_ids)
      ? estimated_from_type_ids
      : null;
    this.order_estimated_from_type_ids = _.isArray(
      order_estimated_from_type_ids
    )
      ? order_estimated_from_type_ids
      : null;
    this.order_estimated_from_type_names = _.isArray(
      order_estimated_from_type_names
    )
      ? order_estimated_from_type_names
      : null;
    this.order_estimated_trigger_type_ids = _.isArray(
      order_estimated_trigger_type_ids
    )
      ? order_estimated_trigger_type_ids
      : null;
    this.special_condition_ids = _.isArray(special_condition_ids)
      ? special_condition_ids
      : null;
    this.repair_req = _.isArray(repair_req) ? repair_req : null;
    this.floor_area_name = floor_area_name ? floor_area_name : null;
    this.floor_area = floor_area ? floor_area : null;
    this.motivational_level_type_id = motivational_level_type_id
      ? motivational_level_type_id
      : null;
    this.order_require_subsidy_type_id = order_require_subsidy_type_id
      ? order_require_subsidy_type_id
      : null;
    this.order_payment_method_type_id = order_payment_method_type_id
      ? order_payment_method_type_id
      : null;
    this.near_situation_name = near_situation_name ? near_situation_name : null;
    this.near_situation = near_situation ? near_situation : null;
    this.repair_request_timing = repair_request_timing
      ? repair_request_timing
      : null;
    this.introduce_request = introduce_request ? introduce_request : null;
    this.introduce_request_name = introduce_request_name
      ? introduce_request_name
      : null;
    this.estimate_meeting_type_id = estimate_meeting_type_id
      ? estimate_meeting_type_id
      : null;
    this.have_estimates = have_estimates ? have_estimates : null;
    this.attend_ng_reason_type_id = attend_ng_reason_type_id
      ? attend_ng_reason_type_id
      : null;
    this.attend_flag_name = attend_flag_name ? attend_flag_name : null;
    this.attend_flag = attend_flag ? attend_flag : null;
    this.attend_condition = attend_condition ? attend_condition : null;
    this.estimate_meeting_text = estimate_meeting_text
      ? estimate_meeting_text
      : null;
    this.estimated_count = estimated_count;
    this.estimated_company = estimated_company ? estimated_company : null;
    this.motivational_level_text = motivational_level_text
      ? motivational_level_text
      : null;
    this.order_payment_method_text = order_payment_method_text
      ? order_payment_method_text
      : null;
    this.impression = impression ? impression : null;
    this.order_require_subsidy_text = order_require_subsidy_text
      ? order_require_subsidy_text
      : null;
    this.contactable_time = contactable_time ? contactable_time : null;
    this.add_memo = add_memo ? add_memo : null;
    this.offer_order_memo = offer_order_memo ? offer_order_memo : null;
    this.memo = memo ? memo : null;
    this.calling_memo = calling_memo ? calling_memo : null;
    this.floor_name = floor_name ? floor_name : null;
    this.repair_request_name = repair_request_name ? repair_request_name : null;
  }
}
