import { map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { ClientPortalOrder } from '@app-units/models/client-portal-order/client-portal-order';
import { SsUrlSearchParamsService } from '@blocks/utils/ss-url-search-params/ss-url-search-params.service';
import { Observable } from 'rxjs';
import { ISsConstant, SS_CONSTANT } from '@ss-core/constants/ss.constant';

export class ClientPortalOrdersSearchParams {
  /* ページ数 */
  page?: string = null;
  /** ソート */
  order?: string = null;
  /** 表示件数 */
  limit?: string = null;
  /** ステータス */
  status?: string = null;
  /** オーダー転送コード */
  order_transfer_cd?: string = null;
  /** 見積進捗状況 */
  _estimate_statuses?: string[] = null;
  /** 見積提出日（開始） */
  estimate_submission_date_start?: string = null;
  /** 見積提出日（終了） */
  estimate_submission_date_end?: string = null;
  /** 次回連絡予定日（開始） */
  next_call_date_start?: string = null;
  /** 次回連絡予定日（終了） */
  next_call_date_end?: string = null;
  /** 名前 */
  end_name?: string = null;
  /** 電話番号 */
  end_tel?: string = null;
  /** メールアドレス */
  end_email?: string = null;
  /** 都道府県 */
  prefecture?: string = null;
  /** 市区町村 */
  city?: string = null;
  /** オーダー転送（開始日） */
  order_transfered_date_start?: string = null;
  /** オーダー転送（終了日） */
  order_transfered_date_end?: string = null;
}

@Injectable()
export class ClientPortalOrdersHttpService {
  constructor(
    private http: HttpClient,
    private ssUrlSearchParams: SsUrlSearchParamsService,
    @Inject(SS_CONSTANT) private SS_CONSTANT: ISsConstant
  ) {}

  fetchItems(
    search: ClientPortalOrdersSearchParams
  ): Observable<app.response<ClientPortalOrder[]>> {
    const params = this.ssUrlSearchParams.parseParams(search);
    return this.http
      .get(this.getUrl(), { params: params })
      .pipe(map(JsonConverter.toClientPortalOrders));
  }

  fetchItemPrevNext(
    orderId: string,
    search: ClientPortalOrdersSearchParams
  ): Observable<app.response<{ next_id: number; prev_id: number }>> {
    const url = this.getUrl(orderId) + '/prev_next';
    const params = this.ssUrlSearchParams.parseParams(search);
    return this.http.get<app.response<{ next_id: number; prev_id: number }>>(
      url,
      { params: params }
    );
  }

  private getUrl(orderId?: string): string {
    if (orderId) {
      return `/portal/orders/${orderId}`;
    }
    return `/portal/orders`;
  }

  exportCsvURL(paramsString: string): string {
    return this.SS_CONSTANT.API_BASE_URL + '/portal/orders/csv?' + paramsString;
  }
}
