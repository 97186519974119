import { DatepickerConfig } from 'ngx-bootstrap/datepicker';

// datepickerConfig
export function datepickerConfig(): DatepickerConfig {
  return Object.assign(new DatepickerConfig(), {
    locale: 'ja',
    showWeeks: false,
    formatDayTitle: 'YYYY年 M月',
    formatMonthTitle: 'YYYY年',
    formatDay: 'D',
  } as DatepickerConfig);
}
