import { EndInfoOriginInterface } from '@app-units/models/end-info/end-info-origin';
import { SsSelective } from '@blocks/selective/ss-selective-abstract';
import { OriginWrapper } from '@ss-core/models';

/**
 * @classdesc ユーザー情報
 * @export
 * @class EndInfo
 */
export class EndInfo extends SsSelective implements OriginWrapper {
  id: string;
  end_last_name: string;
  end_first_name: string;
  end_last_name_kana: string;
  end_first_name_kana: string;
  is_prime_contractor: boolean;
  end_tel: string;
  end_tel_second: string;
  end_email: string;
  label: string;
  pick_up_rank_type_id: string;
  order_transfered_date: string;
  /** お客様の印象 */
  impression?: string;
  /** お客様へのご連絡 */
  contactable_time?: string;

  private readonly origin: EndInfoOriginInterface;

  get posting_data(): EndInfoOriginInterface {
    return new EndInfoOrigin(
      parseInt(this.id),
      this.end_last_name,
      this.end_first_name,
      this.end_last_name_kana,
      this.end_first_name_kana,
      this.is_prime_contractor,
      this.end_tel,
      this.end_tel_second,
      this.end_email,
      this.label,
      this.pick_up_rank_type_id,
      this.order_transfered_date,
      this.impression,
      this.contactable_time
    );
  }

  get fullName() {
    const last_name = this.end_last_name ? this.end_last_name : '';
    const first_name = this.end_first_name ? this.end_first_name : '';
    let result = last_name;
    if (first_name.length > 0 && last_name.length > 0) {
      result = result + ' ';
    }
    result = result + first_name;
    return result;
  }

  get fullNameKana() {
    const last_name = this.end_last_name_kana ? this.end_last_name_kana : '';
    const first_name = this.end_first_name_kana ? this.end_first_name_kana : '';
    let result = last_name;
    if (first_name.length > 0 && last_name.length > 0) {
      result = result + ' ';
    }
    result = result + first_name;
    return result;
  }

  constructor(endInfo?: EndInfoOriginInterface) {
    super();
    this.origin = this.createEndInfoInstance(endInfo);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createEndInfoInstance(
    endInfo: EndInfoOriginInterface
  ): EndInfoOrigin {
    return endInfo
      ? new EndInfoOrigin(
          endInfo.id,
          endInfo.end_last_name,
          endInfo.end_first_name,
          endInfo.end_last_name_kana,
          endInfo.end_first_name_kana,
          endInfo.is_prime_contractor,
          endInfo.end_tel,
          endInfo.end_tel_second,
          endInfo.end_email,
          endInfo.label,
          endInfo.pick_up_rank_type_id,
          endInfo.order_transfered_date,
          endInfo.impression,
          endInfo.contactable_time
        )
      : new EndInfoOrigin();
  }

  private initializeProperties(endInfo: EndInfoOrigin) {
    this.id = endInfo.id ? String(endInfo.id) : null;
    this.end_last_name = endInfo.end_last_name ? endInfo.end_last_name : null;
    this.end_first_name = endInfo.end_first_name
      ? endInfo.end_first_name
      : null;
    this.end_last_name_kana = endInfo.end_last_name_kana
      ? endInfo.end_last_name_kana
      : null;
    this.end_first_name_kana = endInfo.end_first_name_kana
      ? endInfo.end_first_name_kana
      : null;
    this.is_prime_contractor = endInfo.is_prime_contractor
      ? endInfo.is_prime_contractor
      : false;
    this.end_tel = endInfo.end_tel ? endInfo.end_tel : null;
    this.end_tel_second = endInfo.end_tel_second
      ? endInfo.end_tel_second
      : null;
    this.end_email = endInfo.end_email ? endInfo.end_email : null;
    this.label = endInfo.label ? endInfo.label : null;
    this.pick_up_rank_type_id = endInfo.pick_up_rank_type_id
      ? endInfo.pick_up_rank_type_id
      : null;
    this.order_transfered_date = endInfo.order_transfered_date
      ? endInfo.order_transfered_date
      : null;
    this.impression = endInfo.impression ?? '';
    this.contactable_time = endInfo.contactable_time ?? '';
  }
}

class EndInfoOrigin implements EndInfoOriginInterface {
  id: number;
  end_last_name: string;
  end_first_name: string;
  end_last_name_kana: string;
  end_first_name_kana: string;
  is_prime_contractor: boolean;
  end_tel: string;
  end_tel_second: string;
  end_email: string;
  label: string;
  pick_up_rank_type_id: string;
  order_transfered_date: string;
  impression?: string;
  contactable_time?: string;

  constructor(
    id?: number,
    end_last_name?: string,
    end_first_name?: string,
    end_last_name_kana?: string,
    end_first_name_kana?: string,
    is_prime_contractor?: boolean,
    end_tel?: string,
    end_tel_second?: string,
    end_email?: string,
    label?: string,
    pick_up_rank_type_id?: string,
    order_transfered_date?: string,
    impression?: string,
    contactable_time?: string
  ) {
    this.id = id ? id : null;
    this.end_last_name = end_last_name ? end_last_name : null;
    this.end_first_name = end_first_name ? end_first_name : null;
    this.end_last_name_kana = end_last_name_kana ? end_last_name_kana : null;
    this.end_first_name_kana = end_first_name_kana ? end_first_name_kana : null;
    this.is_prime_contractor = is_prime_contractor ? is_prime_contractor : null;
    this.end_tel = end_tel ? end_tel : null;
    this.end_tel_second = end_tel_second ? end_tel_second : null;
    this.end_email = end_email ? end_email : null;
    this.label = label ? label : null;
    this.pick_up_rank_type_id = pick_up_rank_type_id
      ? pick_up_rank_type_id
      : null;
    this.order_transfered_date = order_transfered_date
      ? order_transfered_date
      : null;
    this.impression = impression ?? '';
    this.contactable_time = contactable_time ?? '';
  }
}
