import { map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { OfferedOrderHistory } from '@app-units/models/offered-order-history/offered-order-history';
import { SsUrlSearchParamsService } from '@blocks/utils/ss-url-search-params/ss-url-search-params.service';
import { Observable } from 'rxjs';
import { ISsConstant, SS_CONSTANT } from '@ss-core/constants/ss.constant';

export class OfferedOrderHistoriesSearchParams {
  /** ページ数 */
  page?: string = null;
  /** ソート */
  order?: string = null;
  /** 表示件数 */
  limit?: string = null;
  /** コード */
  order_transfer_cd?: string = null;
  /** 販売日付（開始） */
  order_transfer_date_start?: string = null;
  /** 販売日付（終了） */
  order_transfer_date_end?: string = null;
  /** エンド名 */
  end_name?: string = null;
  /** 課金状態 */
  charge_type_id?: number = null;
  /** ステータス */
  order_status_ids?: number[] = null;
  /** 見積進捗ステータス */
  estimate_statuses?: number[] = null;
}

@Injectable()
export class OfferedOrderHistoriesHttpService {
  constructor(
    private http: HttpClient,
    private ssUrlSearchParams: SsUrlSearchParamsService,
    @Inject(SS_CONSTANT) private SS_CONSTANT: ISsConstant
  ) {}

  fetchItems(
    id: string,
    search: OfferedOrderHistoriesSearchParams
  ): Observable<app.response<OfferedOrderHistory[]>> {
    const params = this.ssUrlSearchParams.parseParams(search);
    return this.http
      .get(this.getUrl(id), { params: params })
      .pipe(map(JsonConverter.toOfferedOrderHistory));
  }

  private getUrl(id: string): string {
    return `/clients/${id}/offered_orders`;
  }

  exportCsvURL(id: string, paramsString: string): string {
    return (
      this.SS_CONSTANT.API_BASE_URL +
      `/clients/${id}/offered_orders/csv?` +
      paramsString
    );
  }
}
