import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileSizePipe } from '@blocks/pipe/file-size/file-size.pipe';
import { SsValuesPipe } from '@blocks/pipe/ss-values/ss-values.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [SsValuesPipe, FileSizePipe],
  exports: [SsValuesPipe, FileSizePipe],
})
export class SsPipeModule {}
