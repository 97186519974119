import { Component } from '@angular/core';

/**
 * @classdesc エラー系ページ
 * @export
 * @class ErrorComponent
 */
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  constructor() {
    console.log('Error');
  }
}
