import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface CheckAuthInterface {
  token_available: boolean;
}

@Injectable()
export class MailHttpService {
  constructor(private http: HttpClient) {}

  orderTransferClient(): Observable<
    app.response<{ title: string; body: string }>
  > {
    const url = '/mail/orderTransferClient';
    return this.http.get<app.response<{ title: string; body: string }>>(url);
  }
}
