import { map, catchError, tap } from 'rxjs/operators';
import {
  EventEmitter,
  Inject,
  Injectable,
  Output,
  Directive,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { AuthUser } from '@app-units/models/auth-user/auth-user';
import { Auth } from '../../models';

/**
 * @classdesc ログイン認証系Service
 * @export
 * @class AuthService
 */
@Directive()
@Injectable()
export class AuthService {
  /** ログイン者情報 */
  response: app.response<AuthUser>;

  authUser() {
    return this.response.data;
  }

  /** URL */
  private resouse = '/auth';
  /** backendへ認証確認が失敗したフラグ（何度も認証確認しないため） */
  isAuthRequestFailed: boolean;

  /** ログイン者情報取得完了イベント */
  @Output() fetchedAuthUser = new EventEmitter<any>();

  constructor(private http: HttpClient) {}

  /**
   * ログイン認証済みかを取得
   */
  isAuthenticated(): boolean {
    return this.response ? true : false;
  }

  /**
   * ログイン認証済みユーザーを取得
   */
  fetchUser(): Observable<app.response<AuthUser>> {
    return this.http
      .get(this.resouse + '/login')
      .pipe(
        catchError(this.failedAuthUser.bind(this)),
        map(JsonConverter.toAuthUser),
        tap(this.setAuthUser.bind(this))
      );
  }

  /**
   * ログイン認証処理
   */
  login(auth: Auth): Observable<app.response<any>> {
    return this.http
      .post(this.resouse + '/login', auth)
      .pipe(map(JsonConverter.toAuthUser));
  }

  /**
   * ログアウト処理
   */
  logout(): Observable<app.response<any>> {
    return this.http
      .post(this.resouse + '/logout', {})
      .pipe(map(JsonConverter.toAuthUser));
  }

  /**
   * ログイン者情報をセット
   */
  private setAuthUser(response: app.response<AuthUser>) {
    // 認証成功したら
    if (response.result) {
      this.isAuthRequestFailed = false;
      // ログイン者情報をセット
      this.response = response;
      // イベント通知
      this.fetchedAuthUser.emit(this.response);
    } else {
      this.isAuthRequestFailed = true;
    }
  }

  /**
   * ログイン者情報を取得失敗した場合
   */
  private failedAuthUser(error: any): any {
    this.isAuthRequestFailed = true;
    return error;
  }
}
