import { AuthUserOriginInterface } from '@app-units/models/auth-user/auth-user-origin';
import { Profile } from '@app-units/models/profile/profile';
import { ProfileOriginInterface } from '@app-units/models/profile/profile-origin';
import { OriginWrapper } from '@ss-core/models';
import * as _ from 'lodash';

/**
 * @classdesc ログイン者情報
 * @export
 * @class AuthUser
 */
export class AuthUser implements OriginWrapper {
  id: string;
  username: string;
  profile: Profile;
  isDoors: boolean;

  private readonly origin: AuthUserOriginInterface;

  get posting_data(): AuthUserOriginInterface {
    return new AuthUserOrigin(
      parseInt(this.id),
      this.username,
      this.profile.posting_data,
      this.isDoors
    );
  }

  get fullName() {
    const last_name = this.profile.last_name ? this.profile.last_name : '';
    const first_name = this.profile.first_name ? this.profile.first_name : '';
    return last_name + first_name;
  }

  constructor(authUser?: AuthUserOriginInterface) {
    this.origin = this.createInstance(authUser);
    this.initializeProperties(this.origin);
  }

  resetToOrigin() {
    this.initializeProperties(this.origin);
  }

  private createInstance(authUser: AuthUserOriginInterface): AuthUserOrigin {
    return authUser
      ? new AuthUserOrigin(
          authUser.id,
          authUser.username,
          authUser.profile,
          authUser.isDoors
        )
      : new AuthUserOrigin();
  }

  private initializeProperties(authUser: AuthUserOrigin) {
    this.id = authUser.id ? String(authUser.id) : null;
    this.username = authUser.username;
    this.profile = new Profile(authUser.profile);
    this.isDoors = authUser.isDoors;
  }
}

/**
 * @classdesc APIに合わせたログイン者情報クラス
 * @description typescriptにprivate classの概念がないため、
 * 同一ファイル内に２つのクラスを実装。
 * @class AuthUserOrigin
 */
class AuthUserOrigin implements AuthUserOriginInterface {
  id: number;
  username: string;
  profile: ProfileOriginInterface;
  isDoors: boolean;

  constructor(
    id?: number,
    username?: string,
    profile?: ProfileOriginInterface,
    isDoors?: boolean
  ) {
    this.id = id ? id : null;
    this.username = username ? username : null;
    this.profile = profile ? _.cloneDeep(profile) : null;
    this.isDoors = _.isBoolean(isDoors) ? isDoors : null;
  }
}
