import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConverter } from '@app-core/services/utility/json-conveter';
import { Attachment } from '@app-units/models/attachment/attachment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class ClientManagementDocsHttpService {
  constructor(private http: HttpClient) {}

  fetchItems(clientId: string): Observable<app.response<Attachment[]>> {
    return this.http
      .get<app.response<Attachment[]>>(this.getUrl(clientId))
      .pipe(map(JsonConverter.toAttachment));
  }

  saveItems(
    clientId: string,
    postingData: any
  ): Observable<app.response<Attachment[]>> {
    return this.http
      .post<app.response<Attachment[]>>(this.getUrl(clientId), postingData)
      .pipe(map(JsonConverter.toAttachment));
  }

  deleteItem(
    clientId: string,
    id: string
  ): Observable<app.response<Attachment[]>> {
    return this.http
      .delete<app.response<Attachment[]>>(this.getUrl(clientId, id))
      .pipe(map(JsonConverter.toAttachment));
  }

  createUpLoadUrl(): string {
    return '/api/upload';
  }

  getUrl(clientId: string, id?: string): string {
    if (!_.isNil(id)) {
      return `/clients/${clientId}/client_management_docs/${id}`;
    }
    return `/clients/${clientId}/client_management_docs`;
  }
}
